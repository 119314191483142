import lokiDB, {technologyGroups} from "./lokiDB"
import {getModificationTime, insertInto, setModificationTime} from "../utils/LokiUtils";
import TechnologyGr from "../beans/TechnologyGroup"

class TechnologyGroup {

    /**********************************
     ***         siloses            ***
     **********************************/

    /**
     * Funkcja do pobierania ostatniej daty modyfikacji farmy na danej fermie
     * @return {{DtaModTime: string}} 0 jeśli nie było wcześniej informacji o ostatniej dacie modyfikacji dla danego farmdID
     * @param FarmID
     */
    getModificationTime(FarmID) {
        return getModificationTime("technologyGroup", "FarmID", FarmID);
    }

    async insertIntoTechnologyGroup(values) {
        insertInto(values, "technoGroups", "TGID");
        setModificationTime("technologyGroup", values[values.length - 1].DtaModTime, "FarmID", values[values.length - 1].FarmID);
        await lokiDB.asyncSaveDB();
    }

    getCreateDay() {
        try {
            return technologyGroups.find({createDay: {"$type": "number"}});
        } catch (e) {
            console.error(e);
            return 0;
        }
    }

    async updateCreateDay(createDay) {
        try {
            let res = this.getCreateDay();
            res[0].createDay = createDay;
            technologyGroups.update(res[0]);
            // await Loki.saveDB();
        } catch (e) {
            Promise.reject(e);
        }
    }

    getTechnologyGroups(id) {
        try {
            return technologyGroups.find({
                FarmID: id,
                DtaDelTime: {$type: 'undefined'}
            }).map(tg => new TechnologyGr(tg));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getTechnoGroupById(AnmID) {
        try {
            return new TechnologyGr(technologyGroups.findOne({
                AnmList: {
                    $contains: AnmID
                },
                DtaDelTime: {$type: 'undefined'}
            }));
        } catch (e) {
            console.error(e.message);
            return null;
        }
    }

    getTechnoGroupByTGID(TGID) {
        try {
            return new TechnologyGr(technologyGroups.findOne({
                TGID: TGID
            }));
        } catch (e) {
            console.error(e.message);
            return null;
        }
    }

    /**
     * Metoda zwraca liste grup technologicznych dla zwierzęcia
     * @param FarmID            id fermy
     * @param AnmID             id zwierzecia
     * @param notRemoved        wyszukiwanie tylko nie usunietych
     * @return {Array.<TechnologyGroup>}
     */
    getTechnologyGroupsWithAnimal(FarmID, AnmID, notRemoved = true) {
        //console.log(res);
        try {
            let params = {
                FarmID: FarmID,
                AnmList: {
                    $contains: AnmID
                },
                DtaDelTime: {$type: 'undefined'}
            };
            if (notRemoved) {
                params = {
                    ...params,
                    Rmvd: {
                        $containsNone: AnmID
                    },
                }
            }
            return technologyGroups.find(params).map(tg => new TechnologyGr(tg));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getTechnologyGroupWithAnimalAfterTime(FarmID, AnmID, startTime) {
        try {
            let res = technologyGroups.find({
                FarmID: FarmID,
                AnmList: {
                    $contains: AnmID
                },
                StartTime: {
                    $gt: startTime
                },
                DtaDelTime: {$type: 'undefined'}
            });
            return res.sort((t1, t2) => t1.StartTime - t2.StartTime).map(tg => new TechnologyGr(tg))
        } catch (e) {
            console.error(e);
            return [];
        }
    }
}

const technologyGroupsDB = new TechnologyGroup();
export default technologyGroupsDB;