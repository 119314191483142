import lokiDB, {groups} from "./lokiDB"
import {getModificationTime, insertInto, setModificationTime} from "../utils/LokiUtils";
import Group from "../beans/Group";

class Groups {

    async insertIntoGroups(values) {
        values.sort((e1, e2) => e1.DtaModTime - e2.DtaModTime);
        insertInto(values, "groups", "AnmGrp");
        setModificationTime("groups", values[values.length - 1].DtaModTime, "FarmID", values[values.length - 1].FarmID);
        await lokiDB.asyncSaveDB();
    }

    getModificationTime(id) {
        return getModificationTime("groups", "FarmID", id);
    }

    /**
     * Metoda prywatna do pobierania i castowania danych
     * @param object
     * @return {Array<Group>}
     * @private
     */
    _findGroups(object = {}) {
        return groups.find(object).map(group => new Group(group));
    }

    /**
     * Metoda prywatna do pobierania i castowania danych
     * @param object
     * @return {null|Group}
     * @private
     */
    _findOneGroup(object = {}) {
        let group = groups.findOne(object);
        if (group) return new Group(group);
        return null;
    }

    /**
     * Metoda zwraca grupę po ID
     * @param AnmGrp {string}    ID grupy
     * @return {Group|null}
     */
    findGroupByID(AnmGrp) {
        return this._findOneGroup({
            AnmGrp,
            DtaDltTime: {$type: 'undefined'}
        });
    }

    /**
     * Metoda zwraca listę grup
     * @param FarmID {string}   ID fermy
     * @return {Array<Group>}
     */
    getAllGroups(FarmID) {
        return this._findGroups({
            FarmID,
            DtaDltTime: {$type: 'undefined'}
        });
    }

    /**
     * Metoda zwracająca wszystkie grupy, do których należy zwierzę
     * @param AnmID {string}    ID zwierzęcia
     * @param FarmID {string}   ID fermy
     * @return {Array<Group>}
     */
    getGroupWithAnimal(AnmID, FarmID) {
        return this._findGroups({
            FarmID,
            AnmIDs: {
                $contains: AnmID
            },
            DtaDltTime: {$type: 'undefined'}
        });
    }

    /**
     * Metoda zwracające grupy grupy o danym numerze
     * @param GrNo1 {string}    Numer grupy
     * @param FarmID {string}   ID fermy
     * @return {Array<Group>}
     */
    getGroupsByGrNo1(GrNo1, FarmID) {
        if (!(GrNo1 && FarmID)) return [];
        let findObj = {
            GrNo1: GrNo1,
            FarmID: FarmID
        };
        return this._findGroups(findObj);
    }

    /**
     * Sprawdza czy grupa istnieje na fermie
     * @param GrNo1 {string}        Numer grupy
     * @param FarmID {string}       ID fermy
     * @return {boolean}
     */
    checkIfGroupExistsOnFarm(GrNo1, FarmID) {
        return this.getGroupsByGrNo1(GrNo1, FarmID).length > 0;
    }
}

const groupsDB = new Groups();
export default groupsDB;