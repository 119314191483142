import lokiDB, {dictionary} from "./lokiDB"
import {getModificationTime, insertInto, setModificationTime} from "../utils/LokiUtils";
import {DICTIONARY_TYPE} from "../constans/general";
import {controlLists} from "../constans/controlLists";
import RemanentControlList from "../beans/controlLists/RemanentControlList";
import ControlList from "../beans/ControlList";
import Medicine from "../beans/Medicine";
import GraftingProgram from "../beans/GraftingProgram";
import ForageIngredient from "../beans/ForageIngredient";

class Dictionaries {

    /**********************************
     ***         FARMS              ***
     **********************************/

    /**
     * Funkcja do pobierania ostatniej daty modyfikacji farmy na danej fermie
     * @return {{DtaModTime: string}} 0 jeśli nie było wcześniej informacji o ostatniej dacie modyfikacji dla danego farmdID
     */
    getModificationTime() {
        return getModificationTime("dictionary");
    }

    //insert into dictionary
    async insertIntoDictionaries(values) {
        insertInto(values, "dictionary", "WordID", "ClientID");
        setModificationTime("dictionary", values[values.length - 1].DtaModTime, "WordID", values[values.length - 1].WordID);
        await lokiDB.asyncSaveDB();
    }

    getAllDictionaries() {
        try {
            return dictionary.find({
                DtaDelTime: {$type: 'undefined'}
            }).map(d => this._getClassname(d));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    // updateDictionary(d) {
    //     return new Promise((resolve, reject) => {
    //         let res = dictionary.update(d);
    //         if (res !== d) reject("Błąd zamiany");
    //         db.saveDatabase(function (err) {
    //             if (err)
    //                 console.log("error obtain: ", err);
    //             //console.log("save database successfull")
    //         });
    //         resolve(res);
    //     })
    // }

    findDictionary(type) {
        return this._getClassname(dictionary.find({
            Type: type, ClientID: {$ne: "Default"},
            DtaDelTime: {$type: 'undefined'}
        }));
    }

    _getClassname(dictionary) {
        switch (dictionary.Type) {
            case DICTIONARY_TYPE.controlLists:
                switch (dictionary.WordID) {
                    case controlLists.REMANENT:
                        return new RemanentControlList(dictionary);
                    default:
                        return new ControlList(dictionary);
                }
            case DICTIONARY_TYPE.medicine:
                return new Medicine(dictionary);
            case DICTIONARY_TYPE.graftingProgram:
                return new GraftingProgram(dictionary);
            case DICTIONARY_TYPE.forageIngredient:
                return new ForageIngredient(dictionary);
            default:
                return dictionary;
        }
    }

}

const dictionariesDB = new Dictionaries();
export default dictionariesDB;