import lokiDB, {reports} from "./lokiDB"
import {reportStatus} from "../constans/reports";
import {getModificationTime, insertInto, setModificationTime} from "../utils/LokiUtils";
import Report from "../beans/Report";

class ReportsDB {

    getModificationTime() {
        return getModificationTime("reports");
    }

    async insertAthenaReports(values) {
        console.log(values);
        insertInto(values, "reports", "LocalQTime");
        setModificationTime("reports", values[0].DtaModTime);
        await lokiDB.asyncSaveDB();
    }

    insertNewReport(report) {
        reports.insert(report);
        lokiDB.asyncSaveDB();
    }

    /**
     * Zwraca listę raportów dla fermy
     * @param farmID
     * @return {[Report]}
     */
    getAllReports(farmID) {
        return reports.find({DtaDelTime: {$type: 'undefined'}})
            .map(report => new Report(report))
            .filter(item => item.isOnFarm(farmID))
            .sort((a, b) => b.FeturaQuery.FeturaQTime - a.FeturaQuery.FeturaQTime);
    }

    updateReportStatus(id, data, status = reportStatus.READY) {
        let reps = reports.find();
        let report = new Report(reps.find(item => item.FeturaQuery.FeturaQTime === id));
        report.updateStatus(data, status);
        reports.update(report);
        lokiDB.saveDB();
    }

    removeReport(report) {
        reports.chain().find({$loki: report.$loki}).remove();
    }

    /**
     * Tylko do testów
     */
    clear() {
        reports.chain().find().remove();
    }
}

const reportsDB = new ReportsDB();
export default reportsDB;