import {addAlarm} from "../../actions/iotAlarmsActions";
import store from "../../store/store";
import _ from "lodash";
import {DevType} from './../../constans/devices';
import moment from 'moment';

export function fakeGetData({gateways, dispatch}) {
    const devices = store.getState().farmDevices.devices
    if (gateways.length) {
        let climate;
        let silo;
        let dispenser;
        for (let device of devices) {
            //jesli nie ma plmnt to niepotrzebne nam
            if (!_.isArray(device.PlcmntID) || !device.PlcmntID.length) continue;
            //jesli juz mamy wszystko to wychodzimy
            if (climate && silo && dispenser) break;
            switch (device.DevType) {
                case DevType.CLIMATE:
                    climate = device;
                    break;
                case DevType.SCALE:
                    silo = device;
                    break;
                case DevType.DISPENSER_NRF:
                    dispenser = device;
                    break;
                default:
                    break;

            }
        }
        const alarms = [];

        function add(device, code, info = {}) {
            if (!device) return;
            const index = alarms.length;
            alarms.push({
                DevID: device.DevID,
                Index: undefined,
                alarms: [
                    {
                        "alarm": {
                            "DevID": device.DevID,
                            "alert": code,
                            "info": info
                        },
                        "time": +moment().startOf("day").add((index + 1) * 45, "minutes"),
                        "expired": null,
                        "_id": `l3g171dofN0m32_${index}`
                    }
                ]
            })


        }

        add(silo, 2001);
        add(climate, 3011);
        add(dispenser, 5205);
        dispatch(addAlarm(gateways[0].DevID, alarms));
    }
}
