import store from "../../store/store";
import {fakeAggData as dataTemplate} from "./fakeAggData"
import moment from "moment";
import {Milliseconds} from "../../constans/milliseconds";

const MaxWeight = 30000;
const MaxSize = 61;

export function fakeAggData(DevID) {
    const {dispatch} = store;
    const aggData = [];
    const now = moment.utc().startOf("day");
    dataTemplate.slice(0).reverse().forEach((row, index) => {
        const time = +now.clone().subtract(index, "days");
        const tmp = {
            DevID: DevID,
            AggTi: time,
            AggDt: getFakeData(row, MaxWeight, MaxSize, time).filter(o=> !!index || o.T < +new Date())
        }
        aggData.push(tmp);
    })
    dispatch({
        type: "FETCH_AGGREGATED_DATA_FULFILLED",
        payload: aggData,
        meta: {DevID: DevID}
    })
    console.log(aggData, "HEEEEEEEEEUAWHUSD", dispatch)
}


function getFakeData(template, maxWeight, maxSize, time) {
    const g1 = ["BPCHM", "BPCHU", "BPCMA", "BPCNA", "BPCPS", "GPCHM", "GPCHU", "GPCMA", "GPCNA", "GPCPS"]; //ilosci
    const g2 = ["BPHM", "BPHU", "BPMA", "BPNA", "BPPS", "GPHM", "GPHU", "GPMA", "GPNA", "GPPS"]; //nie wiem co to
    const g3 = ["HM", "HU", "MA", "NA", "PS"]; //wagi
    const result = [];
    template.forEach((data, index) => {
        const tmp = {};
        tmp.T = time+(index*(Milliseconds.DAY/template.length))
        g1.forEach(key => {
            if (data[key] !== undefined) {
                tmp[key] = Math.round(data[key] * maxSize);
            }
        })
        g2.forEach(key => {
            if (data[key] !== undefined) {
                tmp[key] = data[key];
                for (let i = 0; i < tmp[key].length; i++) {
                    tmp[key][i][1] = data[key][i][1] * maxWeight
                }
            }
        })
        g3.forEach(key => {
            if (data[key] !== undefined) {
                tmp[key] = data[key] * maxWeight;
            }
        })
        result.push(tmp);
    })
    return result;
}
