import lokiDB, {formData} from "./lokiDB"

class FormData {

    insertFormData(formName, data) {
        console.log(formName, data);
        formData.insert({formName, data, time: new Date().getTime()});
        lokiDB.saveDB();
    }

    getSavedData(formName) {
        let data = formData.findOne({formName});
        if (!data) return null;
        formData.chain().find({formName}).remove();
        lokiDB.saveDB();
        return data;
    }

    hasDraft(formNames) {
        for (let formName of formNames) {
            let data = formData.findOne({formName: {$contains: formName}});
            if (data) return true;
        }
        return false;
    }

    getDrafts(formNames) {
        let drafts = [];
        for (let formName of formNames) {
            let data = formData.findOne({formName: {$contains: formName}});
            if (data) drafts.push(data);
        }
        return drafts;
    }

}

const formDataDB = new FormData();
export default formDataDB;