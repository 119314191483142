import {myID} from "../../libs/generateID";
import {MessageTypes, SeparationCageCommandTypes} from "../../constans/mqttMessages";
import store from "../../store/store";
import {addNotification} from "reapop";
import {changeShadowState} from "../../actions/shadowsActions";
import _ from "lodash";
import {CageWorkType} from "../../constans/devices";
import {getMinutesOfDay} from "../../utils/DateTimeUtils";
import moment from "moment";


export function refresh(devices) {
    const map = new Map();
    devices.forEach(dev => {
        const {DevID} = dev;
        const shadow = store.getState().shadows.shadows.get(DevID);
        const step = ((shadow.step + 1) % 10) || 0;
        const workType = shadow.configuration.workType;
        const rand = (weightKg) => 1000 * ((2 * Math.random()) + weightKg);
        let sensors = [];
        let counters = shadow.counters;
        let weight = 0;
        let weightArr = [0, rand(5), rand(63), rand(120), rand(70), rand(1), 0, 0, 0, 0];
        weight = weightArr[step];
        const openedState =  [
            ...CageDoors.TOP_LEFT,
            ...CageDoors.MID_CLOSED,
            ...CageDoors.BOTTOM_OPENED
        ];
        switch (workType) {
            case CageWorkType.MANUAL: {
                sensors = shadow.sensors;
                break;
            }
            case CageWorkType.SEPARATION: {
                const side = weight > 120500 ? CageDoors.TOP_LEFT : CageDoors.TOP_RIGHT;
                sensors = ([
                    [
                        ...CageDoors.TOP_LEFT,
                        ...CageDoors.MID_CLOSED,
                        ...CageDoors.BOTTOM_OPENED
                    ],
                    [
                        ...CageDoors.TOP_LEFT,
                        ...CageDoors.MID_CLOSED,
                        ...CageDoors.BOTTOM_OPENED
                    ],
                    [
                        ...CageDoors.TOP_LEFT,
                        ...CageDoors.MID_CLOSED,
                        ...CageDoors.BOTTOM_CLOSED
                    ],
                    [
                        ...CageDoors.TOP_LEFT,
                        ...CageDoors.MID_CLOSED,
                        ...CageDoors.BOTTOM_CLOSED
                    ],
                    [
                        ...side,
                        ...CageDoors.MID_OPENED,
                        ...CageDoors.BOTTOM_CLOSED
                    ],
                    [
                        ...side,
                        ...CageDoors.MID_OPENED,
                        ...CageDoors.BOTTOM_CLOSED
                    ]
                ][step] || openedState).sort((o1, o2) => o1.number - o2.number);
                if (step === 4) {
                    shadow.averageWeights.closed = shadow.weight;
                }
                if (step === 5) {
                    if (shadow.separationData.maxWeight && (!shadow.separationData.animalsNumber || (shadow.separationData.counter < shadow.separationData.animalsNumber))) {
                        counters.middle++;
                        shadow.separationData.counter++;
                    } else if (weight > 120500) {
                        counters.left++;
                    } else {
                        counters.right++;
                    }
                    counters.all++;
                }
                break;
            }
            default:
                break;
        }
        const newShadow = getShadow({
            shadow,
            sensors,
            workType,
            weight,
            step,
            counters
        });
        map.set(DevID, newShadow)
    });
    store.dispatch(changeShadowState(map));
}

function getShadow({
                       shadow = {}, weight = 120 * 1000, step = Math.floor(Math.random()*5), workType = CageWorkType.SEPARATION, sensors = [
        ...CageDoors.TOP_LEFT,
        ...CageDoors.MID_CLOSED,
        ...CageDoors.BOTTOM_OPENED
    ], counters = {
        "left": 125,
        "middle": 0,
        "right": 170,
        "all": 295
    }
                   }) {
    return _.cloneDeep({
        "valves": [
            {
                "number": 1,
                "value": 0
            },
            {
                "number": 2,
                "value": 0
            },
            {
                "number": 3,
                "value": 0
            },
            {
                "number": 4,
                "value": 0
            },
            {
                "number": 5,
                "value": 0
            },
            {
                "number": 6,
                "value": 0
            },
            {
                "number": 7,
                "value": 0
            },
            {
                "number": 8,
                "value": 0
            }
        ],
        "ISMAD": {
            "inserted": 350,
            "sold": 0,
            "moved": 0,
            "added": 0,
            "dead": 0
        },
        "errors": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "averageWeights": {
            "left": 0,
            "middle": 0,
            "right": 0,
            "all": 120000,
            "closed": 0,
            "yesterday": 120000
        },
        "separationData": {
            "minWeight": 0,
            "maxWeight": 0,
            "animalsNumber": 0,
            "counter": 1186
        },
        "expeditionData": {
            "minWeight": 117000,
            "maxWeight": 180000,
            "animalsNumber": 82,
            "counter": 4,
            "workType": 1,
            "defaultExit": 2,
            "exit1": 4,
            "exit3": 2,
            "counterMonitor": 0
        },
        "RFIDPasses": [
            {
                "P": [
                    {
                        "T": 18360000,
                        "W": 110462
                    }
                ],
                "RFID": "0900081000423634"
            }
        ],
        "ExpeditionMiddlePasses": [],
        "metadata": {
            "weight": 1576157094730
        },
        ...shadow,
        "sensors": sensors,
        "weight": weight,
        "step": step,
        counters,
        "configuration": {
            "workType": workType,
            "workTypeDuration": {
                "training1": 9,
                "training2": 6,
                "training3": 6,
                "separation": 20,
                "expedition": 20
            },
            "defaultExit": 3,
            "RTC": 1496147040000,
            "scale": {
                "SD": 10000,
                "SW": 896,
                "scale": 173000
            },
            "returnToDefaultExit": true,
            "attemptsNumber": 20,
            "exitTime": 0,
            "messageState": false,
            "tareStatus": true
        }

    })
}

export function fakeStartSendingDeviceState(devices) {
    const map = new Map();
    const shadows = store.getState().shadows.shadows;
    const last90days = (baseWeight = 120000) => {
        const tmp = [];
        for (let i = 0; i < 90; i++) {
            const time = +moment.utc().startOf("day").subtract(i, "day");
            tmp.push({
                AggDt: [generateFakeAggDt(baseWeight - (i * 1000), i ? Math.round((2000 + Math.random() * 500)) : 300, time, i)],
                AggTi: time,
                DtaModTime: time
            })
        }
        tmp.sort((o1, o2) => o1.AggTi - o2.AggTi)
        return tmp;
    };
    console.log(devices, last90days, "fdfds");
    devices.forEach(dev => {
        if (!shadows.has(dev.DevID)) {
            const shadowTmp = getShadow({})
            const l90days = last90days();
            console.log(l90days)
            // shadowTmp._weight = Math.round(50000+Math.round((Math.random()*10))*1500);
            // const l90days = last90days();
            // // console.log(l90days);
            store.dispatch({
                type: "FETCH_AGGREGATED_DATA_FULFILLED",
                payload: l90days,
                meta: {DevID: dev.DevID}
            })
            map.set(dev.DevID, shadowTmp);
        }
    });
    console.log(devices, "fdfds");
    if (0) {


    }
    store.dispatch(changeShadowState(map));
}

export function fakeCreateAndSendMessageObject(ClientID, GatewayID, LocalUserID, DeviceID, Command, data = undefined, notification = undefined, onSuccess = undefined, onError = undefined, onSend = undefined) {
    let id = myID();
    const message = {
        MsgId: id,
        PktType: MessageTypes.REQUEST,
        DeviceId: DeviceID,
        RTime: new Date().getTime(),
        Command: Array.isArray(Command) ? Command : [Command],
        CData: {},
        Priority: 69
    };
    if (data) {
        message.CData = data;
    }
    if (notification && notification.success) {
        store.dispatch(addNotification({
            ...notification.loading,
            ...notification.success
        }));
    }
    const shadows = store.getState().shadows.shadows;
    const deviceIds = Array.isArray(message.DeviceId) ? message.DeviceId : [message.DeviceId]
    const cmd = message.Command[0];
    const map = new Map();
    switch (cmd) {
        case SeparationCageCommandTypes.SET_WORK_TYPE:
            console.log("fakeSeparationCageCommandTypes")
            for (let deviceId of deviceIds) {
                const shadow = shadows.get(deviceId);
                console.log("fakeSeparationCageCommandTypesDev", shadow)
                if (deviceId) {
                    map.set(deviceId, getShadow({
                        shadow: shadow,
                        sensors: shadow.sensors,
                        workType: message.CData.workType,
                        weight: shadow.weight,
                        step: 0,
                        counters: shadow.counters
                    }))
                }
            }
            break;
        case SeparationCageCommandTypes.SET_SEPARATION_ANIMALS_NUMBER:
            for (let deviceId of deviceIds) {
                const shadow = shadows.get(deviceId);
                shadow.separationData.animalsNumber = message.CData.animalsNumber;
                if (deviceId) {
                    map.set(deviceId, getShadow({
                        shadow: shadow,
                        sensors: shadow.sensors,
                        workType: shadow.configuration.workType,
                        weight: shadow.weight,
                        step: shadow.step,
                        counters: shadow.counters
                    }))
                }
            }
            break;
        case SeparationCageCommandTypes.SET_SEPARATION_WEIGHT_RANGE:
            for (let deviceId of deviceIds) {
                const shadow = shadows.get(deviceId);
                shadow.separationData.maxWeight = message.CData.maxWeight;
                shadow.separationData.minWeight = message.CData.minWeight;
                if (deviceId) {
                    map.set(deviceId, getShadow({
                        shadow: shadow,
                        sensors: shadow.sensors,
                        workType: shadow.configuration.workType,
                        weight: shadow.weight,
                        step: shadow.step,
                        counters: shadow.counters
                    }))
                }
            }
            break;
        case SeparationCageCommandTypes.SET_VALVE:
            for (let deviceId of deviceIds) {
                const shadow = shadows.get(deviceId);
                if (deviceId) {
                    const idx = shadow.valves.findIndex(o => o.number === message.CData.number);
                    if (~idx) {
                        shadow.valves[idx] = message.CData;
                        shadow.valves.sort((o1, o2) => o1.number - o2.number);
                        shadow.sensors[idx] = message.CData;
                        shadow.sensors.sort((o1, o2) => o1.number - o2.number);
                        map.set(deviceId, getShadow({
                            shadow: shadow,
                            sensors: shadow.sensors,
                            workType: shadow.configuration.workType,
                            weight: shadow.weight,
                            step: 0,
                            counters: shadow.counters
                        }))
                    }

                }
            }
            break;
        default:
            break
    }
    store.dispatch(changeShadowState(map));
    console.log(cmd, shadows, message);
    return id;
}


const CageDoors = {
    TOP_RIGHT: [
        {
            "number": 5,
            "value": 1
        },
        {
            "number": 6,
            "value": 0
        },
        {
            "number": 7,
            "value": 0
        },
        {
            "number": 8,
            "value": 1
        }
    ],
    TOP_LEFT: [
        {
            "number": 5,
            "value": 0
        },
        {
            "number": 6,
            "value": 1
        },
        {
            "number": 7,
            "value": 1
        },
        {
            "number": 8,
            "value": 0
        }
    ],
    TOP_MIDDLE: [{
        "number": 5,
        "value": 0
    },
        {
            "number": 6,
            "value": 1
        },
        {
            "number": 7,
            "value": 0
        },
        {
            "number": 8,
            "value": 1
        }],
    MID_CLOSED: [
        {
            "number": 3,
            "value": 1
        },
        {
            "number": 4,
            "value": 0
        }
    ],
    MID_OPENED: [
        {
            "number": 3,
            "value": 0
        },
        {
            "number": 4,
            "value": 1
        }
    ],
    BOTTOM_CLOSED: [
        {
            "number": 1,
            "value": 1
        },
        {
            "number": 2,
            "value": 0
        }
    ],
    BOTTOM_OPENED: [
        {
            "number": 1,
            "value": 0
        },
        {
            "number": 2,
            "value": 1
        }
    ]
};

const shuffle = (arr) => {
    let curIdx = arr.length
    let tmp;
    let rnd;
    let array = _.cloneDeep(arr);
    while (0 !== curIdx) {
        rnd = Math.floor(Math.random() * curIdx);
        curIdx -= 1;
        tmp = array[curIdx];
        array[curIdx] = array[rnd];
        array[rnd] = tmp;
    }
    return array;
}

const generateFakeAggDt = (avgWeight, passes = 3000, timeDay, index) => {
    const offset = -Math.floor((avgWeight - template.avgValue) / 500);
    const max = (timeDay !== +moment.utc().startOf("day") ? 24 : Math.floor(getMinutesOfDay(new Date()) / 60));
    const names = ["VisLeft", "VisRight", "VisMid"];
    const tmp = {};
    const hasMidPass = [1, 2].includes(index);
    names.forEach(name => {
        template[name] = shuffle(template[name]);
    })
    tmp.VisAll = [];
    names.forEach(name => {
        for (let i = 0; i < 24; i++) {
            if (!tmp[name]) tmp[name] = new Array(24).fill(0)
            tmp[name][i % max] += template[name][i] * passes;
            if (i === 23) {
                tmp[name][i % max] = Math.round(tmp[name][i % max]);
            }
        }
    })
    if (hasMidPass && !tmp.VisMid.reduce((a, b) => a + b, 0)) {
        for (let i = 0; i < 24; i++) {
            let steal = Math.min(Math.ceil((passes / 1000) + (Math.random() * 5)), tmp[names[i % 2]][i]);
            tmp[names[i % 2]][i] -= steal;
            tmp["VisMid"][i] = steal;
        }
    }
    names.forEach(name => {
        for (let i = 0; i < 24; i++) {
            tmp[name][i % max] = Math.round(tmp[name][i % max]);
        }
    })
    for (let i = 0; i < 24; i++) {
        tmp.VisAll[i] = names.reduce((a, val) => a + tmp[val][i], 0);
    }

    return {
        ExpMidPass: hasMidPass ? getFakeMidPasses(avgWeight, max) : [],
        RFIDPass: getFakeRfidPasses(avgWeight, max),
        "WeightExAll": avgWeight,
        "WeightExLeft": 1.06063336766 * avgWeight,
        "WeightExMid": hasMidPass ? 1.01 * avgWeight : 0,
        "WeightExRight": 0.90917152992 * avgWeight,
        ...tmp,
        VisWeight: new Array(340).fill(0).map((o, i) => Math.round(passes * (template.VisWeight[i + offset] || 0)))
    }
}

const getTime = (maxHour = 24) => {
    const time = +moment(0).hour(Math.round(Math.random() * Math.max(maxHour, 0))).minute(Math.round(Math.random() * 50));
    const timeNow = +moment(0).hour(moment.utc().hour()).minute(moment.utc().minute());
    return maxHour !== 24 && (time > timeNow) ? getTime() : time;
}

const getFakeRfidPasses = (avgWeight = 120000, maxHour = 24, pigsNumber = 50, perPig = 5) => {
    const tmp = [];
    for (let i = 0; i < pigsNumber; i++) {
        const numOfPass = perPig + (Math.round(Math.random() * 2) - 1);

        tmp.push({
            P: new Array(numOfPass).fill(0).map((o, i) => ({
                "T": getTime(),
                "W": avgWeight + (((Math.random() * 2.5) - 1) * 500)
            })),
            "RFID": `0900081000423${`${i}`.padStart(3, "0")}`
        })
    }
    return tmp;
}

export const getFakeExpeditions = () => {
    const date = moment.utc().startOf("day");
    return [{
        startDate: +date.clone().subtract(2, "day"),
        endDate: +date.clone().subtract(1, "day")
    }]
}
const getFakeMidPasses = (avgWeight = 120000, maxHour = 24, pigsNumber = 50) => {
    const tmp = [];
    for (let i = 0; i < pigsNumber; i++) {
        tmp.push({
            "T": getTime(),
            "W": avgWeight + (((Math.random() * 2.5) - 1) * 500)
        })
    }
    return tmp;
}

const template = {
    avgIndex: 120,
    avgValue: 70000,
    "VisAll": [
        0.04016765630457562,
        0.02479916171847712,
        0.01746419839329375,
        0.012923506811037374,
        0.020607754104086624,
        0.029689137268599373,
        0.04575619979042962,
        0.05658400279427174,
        0.054488298987076494,
        0.039119804400977995,
        0.029339853300733496,
        0.041914076143904994,
        0.04505763185469787,
        0.07439748515543136,
        0.08452672022354174,
        0.04924903946908837,
        0.069856793573175,
        0.064268250087321,
        0.06915822563744324,
        0.04575619979042962,
        0.011177086971707998,
        0.021306322039818373,
        0.03248340901152637,
        0.019909186168354874
    ],
    "VisLeft": [
        0.02479916171847712,
        0.015019210618232623,
        0.008732099196646874,
        0.008382815228781,
        0.015019210618232623,
        0.013971358714634998,
        0.029339853300733496,
        0.034229828850855744,
        0.035277680754453374,
        0.022004889975550123,
        0.015717778553964373,
        0.025148445686342998,
        0.02759343346140412,
        0.04016765630457562,
        0.050296891372685996,
        0.030387705204331122,
        0.040516940272441494,
        0.035277680754453374,
        0.04366049598323437,
        0.027244149493538247,
        0.007334963325183374,
        0.014320642682500873,
        0.020957038071952497,
        0.014320642682500873
    ],
    "VisRight": [
        0.015368494586098498,
        0.009779951100244499,
        0.008732099196646874,
        0.0045406915822563745,
        0.005588543485853999,
        0.015717778553964373,
        0.016416346489696122,
        0.022354173943415996,
        0.01921061823262312,
        0.017114914425427872,
        0.013622074746769124,
        0.016765630457562,
        0.01746419839329375,
        0.034229828850855744,
        0.034229828850855744,
        0.018861334264757248,
        0.029339853300733496,
        0.028990569332867623,
        0.02549772965420887,
        0.01851205029689137,
        0.0038421236465246244,
        0.006985679357317499,
        0.011526370939573873,
        0.005588543485853999
    ],
    "VisMid": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    VisWeight: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0.0013971358714634998,
        0.00034928396786587494,
        0.0013971358714634998,
        0,
        0.0013971358714634998,
        0.00034928396786587494,
        0.00209570380719525,
        0.0045406915822563745,
        0.00209570380719525,
        0.00209570380719525,
        0.0038421236465246244,
        0.0027942717429269995,
        0.0041914076143905,
        0.0017464198393293748,
        0.0034928396786587496,
        0.0031435557107928748,
        0.004889975550122249,
        0.0031435557107928748,
        0.0024449877750611247,
        0.005239259517988124,
        0.004889975550122249,
        0.006985679357317499,
        0.009430667132378624,
        0.011526370939573873,
        0.013272790778903249,
        0.01606706252183025,
        0.012574222843171499,
        0.015717778553964373,
        0.020607754104086624,
        0.022703457911281873,
        0.027244149493538247,
        0.025847013622074748,
        0.02829200139713587,
        0.018861334264757248,
        0.032832692979392245,
        0.03318197694725812,
        0.029689137268599373,
        0.032832692979392245,
        0.030736989172196996,
        0.03248340901152637,
        0.02479916171847712,
        0.03248340901152637,
        0.035277680754453374,
        0.023052741879147746,
        0.023402025847013622,
        0.025148445686342998,
        0.026545581557806498,
        0.023402025847013622,
        0.02549772965420887,
        0.02619629758994062,
        0.018861334264757248,
        0.027942717429269997,
        0.01851205029689137,
        0.019559902200488997,
        0.01851205029689137,
        0.01781348236115962,
        0.016765630457562,
        0.012923506811037374,
        0.013272790778903249,
        0.009779951100244499,
        0.008033531260915125,
        0.008382815228781,
        0.011875654907439748,
        0.006636395389451624,
        0.010129235068110374,
        0.006985679357317499,
        0.005588543485853999,
        0.0041914076143905,
        0.004889975550122249,
        0.0038421236465246244,
        0.0034928396786587496,
        0.0013971358714634998,
        0.0013971358714634998,
        0.0013971358714634998,
        0.00034928396786587494,
        0,
        0.0006985679357317499,
        0,
        0,
        0,
        0.00034928396786587494,
        0.0013971358714634998,
        0.00034928396786587494,
        0,
        0.0013971358714634998,
        0,
        0,
        0,
        0.00034928396786587494,
        0.00034928396786587494,
        0.00034928396786587494,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ]
}
