import lokiDB, {events} from "./lokiDB"
import {EventTypes} from "../constans/eventTypes";
import {getModificationTime, insertInto, setModificationTime} from "../utils/LokiUtils";
import {getClass, getPigBalance} from "../utils/EventUtils";
import animalsDB from "./animalsDB";
import _ from "lodash";
import moment from "moment";
import {convertRowsToCycles, preEvents} from "../utils/AnimalDocumentsUtils";


class Events {

    constructor() {
        this.getAllEvents4Animal = _.memoize(this.getAllEvents4Animal);
    }

    clearCache() {
        this.getAllEvents4Animal.cache.clear();
    }

    /**
     * Funkcja do pobierania ostatniej daty modyfikacji farmy na danej fermie
     * @param id FarmID
     * @return {{DtaModTime: string}} 0 jeśli nie było wcześniej informacji o ostatniej dacie modyfikacji dla danego farmdID
     */
    getModificationTime(id) {
        return getModificationTime("events", "FarmID", id);
    }

    //insert into animals
    async insertIntoEvents(values) {
        insertInto(values, "events", "EvID");
        setModificationTime("events", values[values.length - 1].DtaModTime, "FarmID", values[values.length - 1].FarmID);
        this.clearCache();
        await lokiDB.asyncSaveDB();
    }

    //get All animals
    getAllEvents4Animal(AnmID) {
        try {
            let evts = events.find({'AnmID': AnmID, DtaDelTime: {$type: 'undefined'}});
            return evts.map(event => getClass(event)).sort((e1, e2) => e1.EvTime - e2.EvTime)
        } catch (e) {
            return [];
        }
    }

    getLastEvent4AnimalWithType(AnmID, type) {
        return getClass(events.chain().find({
            AnmID,
            EvCode: type,
            DtaDelTime: {$type: 'undefined'}
        }).simplesort('EvTime', {desc: true}).data()[0]);
    }

    getAllEvents4AnimalWithType(AnmID, type) {
        try {
            return events.find({
                AnmID: AnmID,
                EvCode: type,
                DtaDelTime: {$type: 'undefined'}
            }).map(event => getClass(event));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllEvents() {
        try {
            return events.find({DtaDelTime: {$type: 'undefined'}}).map(event => getClass(event));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllEventsForFarm(FarmID) {
        try {
            return events.find({FarmID, DtaDelTime: {$type: 'undefined'}}).map(event => getClass(event));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllEventsInWeek(date, weeks, FarmID) {
        try {
            let days;
            if (weeks > 0) {
                days = weeks * 7;
            }
            return events.find({
                EvTime: {$between: [date, +moment(date).add(days, "days")]},
                DtaDelTime: {$type: 'undefined'},
                FarmID: FarmID
            }).map(event => getClass(event));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllEventsWithRange(startDate, endDate, FarmID) {
        try {
            return events.find({
                EvTime: {$between: [startDate, endDate]},
                DtaDelTime: {$type: 'undefined'},
                FarmID: FarmID
            }).map(event => getClass(event));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getEventsByAnimalIDWithRange(dateStart, dateEnd, AnmID) {
        try {
            return events.find({
                EvTime: {$between: [dateStart, dateEnd]},
                DtaDelTime: {$type: 'undefined'},
                AnmID: AnmID
            }).map(event => getClass(event));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllEventsWithEvTimeGreaterThanOrEqualsForAnimal(AnmID, EvTime) {
        try {
            return events.chain().find({
                AnmID,
                EvTime: {
                    $gte: EvTime
                },
                DtaDelTime: {$type: 'undefined'}
            }).simplesort('EvTime').data().map(event => getClass(event));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getCurrentCycle(animal) {
        let events = this.getAllEvents4Animal(animal.AnmID).sort((a, b) => a.EvTime - b.EvTime);
        let cycles = convertRowsToCycles(preEvents(events, animal).cycleTable);
        return cycles[cycles.length - 1];
    }

    getPigBalanceForASowInCurrentCycle(animal) {
        try {
            let currentCycle = this.getCurrentCycle(animal);
            let eventsInCycle = [];
            for (let key in currentCycle) {
                if (Array.isArray(currentCycle[key])) {
                    eventsInCycle.push(...currentCycle[key]);
                }
            }
            return getPigBalance(eventsInCycle);
        } catch (e) {
            console.error(e);
            return 0;
        }
    }

    getPigBalanceForASow(animal, selectedDate) {
        if (!animal) return 0;
        //szukamy ostatniego porodu
        try {
            let lastBirth = this.getLastEvent4AnimalWithType(animal.AnmID, EventTypes.PARTURITION);
            if (!lastBirth) return 0;
            let evtz = this.getAllEventsWithEvTimeGreaterThanOrEqualsForAnimal(animal.AnmID, lastBirth.EvTime);
            selectedDate && (evtz = evtz.filter(ev => moment(+ev.EvTime).startOf('day').isSameOrBefore(selectedDate)));
            return getPigBalance(evtz);
        } catch (e) {
            console.error(e);
            return 0;
        }
    }

    getPigBalanceAllTime(animal, selectedDate) {
        let events = this.getAllEvents4Animal(animal.AnmID);
        events = events.filter(item => moment(+item.EvTime).startOf('day').isSameOrBefore(selectedDate));
        return getPigBalance(events, false);
    }

    getNumberOfSeparatedPigsToMommy(selectedDate, daysBack) {
        const res = events.find({DtaDelTime: {$type: 'undefined'}});
        if (res.length === 0) {
            return 0;
        }
        let evts = [];
        let amount = 0;
        if (!daysBack) {
            evts = res.filter(ev => (ev.EvCode === EventTypes.SEPARATION_TO_MOMMY || ev.EvCode === EventTypes.MOMMY));
        } else {
            evts = res.filter(ev => (ev.EvCode === EventTypes.SEPARATION_TO_MOMMY || ev.EvCode === EventTypes.MOMMY) && (selectedDate.startOf('day').diff(moment(+ev.EvTime).startOf('day'), 'days') >= 0) && (selectedDate.startOf('day').diff(moment(+ev.EvTime).startOf('day'), 'days') < daysBack));
        }
        evts.map(ev => {
            let am = isNaN(+ev.EvData.PiCnt) ? 0 : +ev.EvData.PiCnt;
            if (ev.EvCode === EventTypes.SEPARATION_TO_MOMMY) {
                ev.EvData && ev.EvData.PiCnt && (amount += am)
            } else {
                ev.EvData && ev.EvData.PiCnt && (amount -= am)
            }
        });
        return amount;
    }

    getEventByID(id) {
        return events.find({EvID: id});
    }

    getFirstEventDate(FarmID) {
        let res = events.find({FarmID: FarmID});
        res.sort((a, b) => a.EvTime - b.EvTime);
        return res[0] ? res[0].EvTime : new Date().getTime();
    }

    getEventsForAnimalWithinDateRangeAndType(AnmID, minData, maxDate, type) {
        return events.find({
            AnmID,
            EvTime: {
                $between: [minData, maxDate]
            },
            EvCode: type
        })
    }

    /**
     * Metoda wyszukująca ilość sutków dla zwierzęcia
     * @param AnmID {string}    ID zwierzęcia
     * @param date {number}     timestamp, maksymalna czas zgloszenia ilosci sutkow
     * @return {number | null}  ilość aktywnych sutków w podanym czasie lub null kiedy nie odnaleziono danych
     */
    getActiveNipples(AnmID, date = new Date().getTime()) {
        let evs = events.find({
            AnmID,
            EvCode: EventTypes.ACTIVE_NIPPLES,
            EvTime: {$lte: date}
        }).sort((a, b) => b.EvTime - a.EvTime);
        let event = evs[0];
        console.log(event);
        if (event) {
            return event.EvData.Nipples;
        }
        let animal = animalsDB.getAnimalById(AnmID);
        if (_.isNil(animal.ActiveNipples)) return animal.ActiveNipples;
        return null;
    }

    getActiveNipplesInCurrentCycle(animal) {
        let currentCycle = this.getCurrentCycle(animal);
        if (currentCycle) {
            let activeNipplesArray = currentCycle[EventTypes.ACTIVE_NIPPLES];
            activeNipplesArray.sort((a, b) => b.EvTime - a.EvTime);
            if (activeNipplesArray[0]) {
                return activeNipplesArray[0].EvData.Nipples;
            }
        }
        if (_.isNil(animal.ActiveNipples)) return animal.ActiveNipples;
        return null;
    }
}

const eventsDB = new Events();
export default eventsDB;
