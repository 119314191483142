import store from "../../store/store";
import {SettingTypes} from "../../constans/settingTypes";
import settingsDB from "../../database/settingsDB";
import {getFeedState, getFeedStateRFID} from "../../actions/feedingActions";
import {cloneDeep, get, isEmpty, isNil, set} from "lodash"
import {myID} from "../../libs/generateID";
import {DispenserNRFCommandTypes, GatawayCommandTypes, MessageTypes} from "../../constans/mqttMessages";
import {addNotification} from "reapop";
import moment from "moment";
import {SectorType} from "../../constans/sectorTypes";
import buildingsDB from "../../database/buildingsDB";
import {getCurveDayShowingType} from "../../utils/FeedingUtils";
import {CurveDayShowingType, CurveType} from "../../constans/feedingTypes";

export function fakeStartSendingFeedingState(devIds) {
    setTimeout(() => {
        const state = store.getState();
        let sectorType;
        try {
            const chamberId = state.feeding.selectedChamber;
            if (chamberId) {
                sectorType = buildingsDB.getSectorTypeByChamberID(chamberId);
            }
        } catch (e) {
            console.error(e)
        }
        try {
            const data = new Map();
            devIds.forEach(devId => {
                data.set(devId, cloneDeep(fakeShadow(get(Object.values(state.feeding.chamber), "[0].individualFeeding"), sectorType)));
            });
            store.dispatch(getFeedState(data));
        } catch (e) {
            console.error(e);
        }
    }, 900);
}

export function startSendingFeedingStateRFID(gatewayId, CID) {
    setTimeout(() => {
        try {
            const data = new Map();
            const rfids = Object.values(get((store.getState().feeding.feeding), `[${CID}].data`, {})).map(o => ({
                ...o,
                ...fakeShadow(false)
            }))
            console.log(rfids, "RFF")
            // data.set(CID, ;
            const tmp = {};
            rfids.forEach(d => tmp[d.id] = d);
            data.set(CID, tmp)
            store.dispatch(getFeedStateRFID(data));
        } catch (e) {
            console.error(e);
        }
    }, 900);
}

export const fakeTransfer = (id) => {
    console.log("fakeTransfer called")
    try {
        const map = getMaps();
        const feeding = store.getState().feeding;
        for (let chamberId of Object.keys(feeding.feeding)) {
            const f = feeding.feeding[chamberId];
            if (f) {
                let delta = cloneDeep(f.data[id]);
                if (delta) {
                    const tmp = new Map();
                    const feedParams = get(delta, "animal.feedParam") || {}
                    delta = setFeedingConfig(map, delta, {
                        number: feedParams.curveNr || 0,
                        start: feedParams.startTime || 0,
                        eventStage: feedParams.stage || 0,
                        punishment: feedParams.punishment || 0,
                        correction: feedParams.percentCorrection || 0
                    });
                    delta.feeding = !!feedParams.curveNr;
                    if (delta.receiver) {
                        tmp.set(isNil(delta.receiver.index) ? `${delta.receiver.deviceId}` : `${delta.receiver.deviceId}_${delta.receiver.index}`, delta)
                        store.dispatch(getFeedState(tmp));
                    } else {
                        tmp.set(id, delta);
                        store.dispatch(getFeedStateRFID(tmp));
                    }
                }
            }
        }
    } catch (e) {
        console.error(e);
    }
}

export function refresh() {
    console.log("refresh called")
    try {
        const map = getMaps();
        const feeding = store.getState().feeding;
        let tmp = new Map();
        for (let chamberId of Object.keys(feeding.feeding)) {
            const f = feeding.feeding[chamberId];
            if (f) {
                Object.keys(f.data).forEach(k => {
                    let newData = cloneDeep(f.data[k]);
                    if (newData.receiver) {
                        let lastTime = newData.lastSeen.time;
                        newData = {
                            ...newData,
                            ...getExpectedFeed(map, newData.curve.number, newData.curve.day, newData.curve.correction, newData.feed.usage, newData)
                        };
                        newData.running = false;
                        if (newData._extraFeed) {
                            const givenExtra = newData.additionalFeeding.reduce((a, b) => a + b.dose, 0);
                            if (givenExtra < newData._extraFeed) {
                                newData.additionalFeeding.push({
                                    dose: newData._extraFeed - givenExtra >= 125 ? 125 : newData._extraFeed - givenExtra,
                                    success: 1,
                                    time: new Date()
                                });
                                lastTime = new Date();
                                newData.running = !!(newData._extraFeed - newData.additionalFeeding.reduce((a, b) => a + b.dose, 0));
                            }
                        }
                        if (newData.curve.number === 0) {
                            newData.feeding = false;
                        } else {
                            newData.feeding = true;
                        }
                        newData.lastSeen.time = Math.max(lastTime, newData.lastSeen.time);
                        tmp.set(isNil(newData.receiver.index) ? `${newData.receiver.deviceId}` : `${newData.receiver.deviceId}_${newData.receiver.index}`, newData)
                    }
                })
            }
        }
        if (!isEmpty(tmp)) {
            store.dispatch(getFeedState(tmp));
        }
    } catch (e) {
        //
    }

}

function getMaps() {
    const result = {
        [SettingTypes.FEEDING_CURVE]: new Map(),
        [SettingTypes.FEEDING_SCHEDULE]: new Map(),
        [SettingTypes.FEEDING_FORAGE]: new Map()
    };
    const settings = settingsDB.getAllSettings(store.getState().location.farm);
    settings.forEach(set => {
        const setIdx = get(set, "SetData.Index");
        if ([SettingTypes.FEEDING_FORAGE, SettingTypes.FEEDING_SCHEDULE, SettingTypes.FEEDING_CURVE].includes(set.SetType) && !isNil(setIdx)) {
            result[set.SetType].set(setIdx, set);
        }
    });
    return result;
}

const lastWeekHistory = (plannedUsage = 3000, usage = 0, shadow) => {
    const rand = (min, max) => min + ((max - min) * Math.random());
    return [
        {
            plannedUsage,
            usage,
            date: +moment().startOf("day")
        },
        {
            usage: get(shadow, "lastWeekHistory[1].usage", rand(2000, 3000)),
            plannedUsage: 3500,
            date: +moment().startOf("day").subtract(1, "day")
        },
        {
            usage: get(shadow, "lastWeekHistory[2].usage", rand(500, 1500)),
            plannedUsage: 3500,
            date: +moment().startOf("day").subtract(2, "day")
        },
        {
            usage: get(shadow, "lastWeekHistory[3].usage", rand(1500, 3500)),
            plannedUsage: 3500,
            date: +moment().startOf("day").subtract(3, "day")
        },
        {
            usage: get(shadow, "lastWeekHistory[4].usage", rand(2000, 3500)),
            plannedUsage: 3500,
            date: +moment().startOf("day").subtract(4, "day")
        },
        {
            usage: get(shadow, "lastWeekHistory[5].usage", rand(1000, 3500)),
            plannedUsage: 3500,
            date: +moment().startOf("day").subtract(5, "day")
        },
        {
            usage: get(shadow, "lastWeekHistory[6].usage", rand(0, 1000)),
            plannedUsage: 3500,
            date: +moment().startOf("day").subtract(6, "day")
        },
        ...new Array(8).fill(0).map((o, i) => ({
            usage: get(shadow, `lastWeekHistory[${6 + i}].usage`, rand(1000, 3500)),
            plannedUsage: 3500,
            date: +moment().startOf("day").subtract(6 + i, "day")
        }))


    ]
}

function fakeShadow(isIndividual = false, sectorType = SectorType.SOWS) {
    console.log("fakeShadow called")
    const map = getMaps();
    const curveArray = [...map[SettingTypes.FEEDING_CURVE].entries()];
    let index;
    const findCurve = (type, showingType = CurveDayShowingType.BOTH) => curveArray.find(([index, cur]) => cur.SetData.Type === type && getCurveDayShowingType(cur) === showingType)
    switch (sectorType) {
        case SectorType.DELIVERY:
            index = get(findCurve(CurveType.PARTURITION), "[0]", curveArray.length ? curveArray[0][0] : 0)
            break;
        case SectorType.MATING:
            index = get(findCurve(CurveType.MATING), "[0]", curveArray.length ? curveArray[0][0] : 0)
            break;
        default:
            index = get(findCurve(CurveType.INDIVIDUAL, CurveDayShowingType.NORMAL), "[0]", curveArray.length ? curveArray[0][0] : 0)
            break;
    }
    const curve = map[SettingTypes.FEEDING_CURVE].get(index);
    const schedule = map[SettingTypes.FEEDING_SCHEDULE].get(get(curve, "SetData.Days[0].DailyPlan", -1));
    const forage = map[SettingTypes.FEEDING_FORAGE].get(get(curve, "SetData.Days[0].ForageType", -1));
    return {
        curve: {
            number: get(curve, "SetData.Index", -1) + 1,
            id: get(curve, "SetID"),
            day: Math.min(Math.max(get(curve, getCurveDayShowingType(curve) === CurveDayShowingType.BOTH ? "SetData.InseminationJumpTo" : "SetData.Days.length", 0) - 3, 0), 14),
            punishment: 0,
            correction: 15,
            eventStage: 0
        },
        efficiency: 1050,
        schedule: {
            number: get(schedule, "SetData.Index", -1) + 1,
            id: get(schedule, "SetID")
        },
        forage: {
            number: get(forage, "SetData.Index", -1) + 1,
            id: get(forage, "SetID")
        },
        skipDoses: [0, 0, 0, 0, 0, 0],
        holdOut: 0,
        loop: {
            insemination: 0,
            parturition: 0,
            endDay: 0
        },
        locked: false,
        alert: false,
        feeding: true,
        errors: [],
        additionalFeeding: [],
        running: false,
        workType: isIndividual ? "H" : "R",
        oldest: +new Date() - 1000,
        lastSeen: {
            deviceId: "",
            time: 0
        },
        ...getExpectedFeed(map, get(curve, "SetData.Index", -1) + 1, get(curve, "SetData.Days.length", 1) - 1, 15),
        //extra only for fake
        _extraFeed: 0,
        _isIndividual: !!isIndividual,
        _rfid: !!isIndividual

    }
}

function getExpectedFeed(map, curveNr = 0, day = 1, correction = 15, lastUsage, shadow) {
    console.log("getExpectedFeed called")
    if (!curveNr) {
        return {
            feed: {usage: lastUsage || 0, plannedUsage: 0},
            lastWeekHistory: lastWeekHistory(0, lastUsage || 0, shadow)
        };
    }
    const curve = map[SettingTypes.FEEDING_CURVE].get(curveNr - 1);
    const forageAmount = get(curve, `SetData.Days[${day - 1}].ForageAmount`, 3000);
    const scheduleNr = get(curve, `SetData.Days[${day - 1}].DailyPlan`, -1) + 1;
    if (scheduleNr === 0) {
        return {
            feed: {usage: lastUsage || 1500, plannedUsage: forageAmount},
            lastWeekHistory: lastWeekHistory(forageAmount, lastUsage || 1500, shadow)
        };
    } else {
        const minutesOfDay = (m) => m.minutes() + m.hours() * 60;
        const schedule = map[SettingTypes.FEEDING_SCHEDULE].get(scheduleNr - 1);
        const days = get(schedule, "SetData.Doses", []);
        let usage = 0;
        let lastSeen = 0;
        days.forEach(day => {
            if (minutesOfDay(moment(day.Start)) < minutesOfDay(moment())) {
                console.log(day, "DODAJE")
                usage += ((day.Percent / 100) * forageAmount) || 0;
                lastSeen = +moment().startOf("day").add(minutesOfDay(moment(day.Start)), "minute")
            }
        });
        return {
            feed: {usage: lastUsage || usage, plannedUsage: ((100 + correction) / 100) * forageAmount},
            lastSeen: {time: lastSeen},
            lastWeekHistory: lastWeekHistory(((100 + correction) / 100) * forageAmount, lastUsage || usage, shadow)
        }
    }
}

export function fakeSubmitManageFeedingModal(values, dispatch, props) {

}

function getFeedShadow({chamberId, rfid, deviceId}) {
    console.log("getFeedShadow called with", arguments)
    let result = {};
    const feeding = store.getState().feeding;
    if (deviceId) {
        chamberId = feeding.chamberDeviceDict[deviceId]
    }
    if (chamberId) {
        if (feeding.chamber[chamberId]) {
            result = feeding.feeding[chamberId].data[rfid ? rfid : feeding.chamber[chamberId].deviceToStanding[deviceId]] || {}
        }
    }
    return cloneDeep(result);

}

export function fakeCreateAndSendMessageObject(ClientID, GatewayID, LocalUserID, DeviceID, Command, data = undefined, notification = undefined, onSuccess = undefined, onError = undefined, onSend = undefined) {
    console.log("fakeCreateAndSendMessageObject called with", arguments)
    let id = myID();
    const dicts = getMaps();
    const message = {
        MsgId: id,
        PktType: MessageTypes.REQUEST,
        DeviceId: DeviceID,
        RTime: new Date().getTime(),
        Command: Array.isArray(Command) ? Command : [Command],
        CData: {},
        Priority: 69
    };
    if (data) {
        // tmp.CData = this.compressData(data);
        message.CData = data;
    }
    if (notification && notification.success) {
        store.dispatch(addNotification({
            ...notification.loading,
            ...notification.success
        }));
        console.log(notification);
    }
    if (onSuccess) {
        setTimeout(() => {
            onSuccess();
        }, 1500);
    }
    const cmd = message.Command[0];
    console.log(cmd, message);
    switch (cmd) {
        case DispenserNRFCommandTypes.SET_CONFIG_STANDARD: {
            let map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                let delta = getFeedShadow({deviceId: devId});
                delta = setFeedingConfig(dicts, delta, {
                    number: message.CData.curveNr,
                    start: message.CData.startTime,
                    offset: message.CData.offset,
                    punishment: message.CData.punishment,
                    correction: message.CData.percentCorrection,
                    eventStage: message.CData.stage || 0,
                });
                delta.feeding = !!message.CData.curveNr;
                console.log(delta);
                delta.oldest = +new Date();
                map.set(devId, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case DispenserNRFCommandTypes.ADD_PIG: {
            let map = new Map();
            const tmp = {};
            const rfids = [];
            message.CData.animals.forEach(a => {
                let delta = getFeedShadow({chamberId: message.CData.PlcmntID, rfid: a.RFID});
                delta = setFeedingConfig(dicts, delta, {
                    number: a.curveCfg.curveNr || 0,
                    start: a.curveCfg.startTime || 0,
                    offset: a.curveCfg.offset || 0,
                    punishment: a.curveCfg.punishment || 0,
                    correction: a.curveCfg.percentCorrection || 0,
                });
                delta.oldest = +new Date();
                rfids.push(delta);
            });
            rfids.forEach(d => tmp[d.id] = d);
            map.set(message.CData.PlcmntID, tmp);
            store.dispatch(getFeedStateRFID(map));
            break;
        }
        case DispenserNRFCommandTypes.SET_LOCK: {
            let map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                let delta = getFeedShadow({deviceId: devId});
                delta.locked = !!message.CData.isLocked;
                delta.oldest = +new Date();
                map.set(devId, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case DispenserNRFCommandTypes.SET_SKIP_DOSES: {
            let map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                let delta = getFeedShadow({deviceId: devId});
                delta.skipDoses = message.CData.dosesToSkip || [];
                delta.oldest = +new Date();
                map.set(devId, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case DispenserNRFCommandTypes.SET_FORCE_FEEDING: {
            let map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                let delta = getFeedShadow({deviceId: devId});
                delta._extraFeed += message.CData.dose;
                delta.oldest = +new Date();
                map.set(devId, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case GatawayCommandTypes.SET_DISPENSERS_DOSE_CORRECTION: {
            let map = new Map();
            let sendRFID = false;
            let tmp = {};
            message.CData.devices.forEach(({DevID, PlcmntID, animals, correction}) => {
                if (DevID && !sendRFID) {
                    let delta = getFeedShadow({deviceId: DevID});
                    delta.curve.correction = correction;
                    map.set(DevID, delta);
                } else if (PlcmntID) {
                    sendRFID = true;
                    animals.forEach(({RFID, correction: correctionRfid}) => {
                        tmp[RFID] = getFeedShadow({chamberId: PlcmntID, rfid: RFID});
                        tmp[RFID].curve.correction = correctionRfid
                    })
                    map.set(PlcmntID, tmp);
                }
                tmp = {};
            })
            if (sendRFID) {
                store.dispatch(getFeedStateRFID(map))
            } else {
                store.dispatch(getFeedState(map));
            }
            break;
        }
        case DispenserNRFCommandTypes.SET_ALERT: {
            let map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                let delta = getFeedShadow({deviceId: devId});
                delta.alert = !!message.CData.alert;
                delta.oldest = +new Date();
                map.set(devId, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case DispenserNRFCommandTypes.SET_TYPE_EVENT: {
            let map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                let delta = getFeedShadow({deviceId: devId});
                delta.curve.eventStage = message.CData.type;
                delta.oldest = +new Date();
                map.set(devId, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case GatawayCommandTypes.SET_CLEAR_PROBLEM: {
            let map = new Map();
            message.CData.DeviceIDs.forEach(({DevID, index}) => {
                const devId = isNil(index) ? DevID : `${DevID}_${index}`;
                let delta = getFeedShadow({deviceId: devId});
                delta.alert = false;
                delta.oldest = +new Date();
                map.set(devId, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case GatawayCommandTypes.SET_CHANGE_DISPENSERS_DOSE_CORRECTION : {
            let map = new Map();
            message.CData.devices.forEach(({DevID, correctionChange, dispensers}) => {
                if (dispensers) {
                    dispensers.forEach(({number, correctionChange: corrChange}) => {
                        let delta = getFeedShadow({deviceId: `${DevID}_${number - 1}`});
                        delta.curve.correction = Math.min(25, Math.max(-25, delta.curve.correction + corrChange));
                        delta.oldest = +new Date();
                        map.set(`${DevID}_${number - 1}`, delta);
                    })
                } else {
                    let delta = getFeedShadow({deviceId: DevID});
                    delta.curve.correction = Math.min(25, Math.max(-25, delta.curve.correction + correctionChange));
                    delta.oldest = +new Date();
                    map.set(DevID, delta);
                }

            });
            store.dispatch(getFeedState(map));
            break;
        }
        default:
            break;

    }
    return id;
}

export function setFeedingConfig(dicts = new Map(), shadow = {}, {number, start, offset, punishment, correction, eventStage} = {}) {
    !isNil(number) && set(shadow, "curve.number", number);
    !isNil(start) && set(shadow, "curve.start", start);
    !isNil(offset) && set(shadow, "curve.offset", offset);
    !isNil(punishment) && set(shadow, "curve.punishment", punishment);
    !isNil(correction) && set(shadow, "curve.correction", correction);
    !isNil(eventStage) && set(shadow, "curve.eventStage", eventStage);
    console.log(dicts, number, eventStage, start)
    const day = get(dicts[SettingTypes.FEEDING_CURVE].get(number - 1), `SetData.${eventStage ? "InseminationJumpTo" : "nonexisting"}`, 1) + moment().startOf("day").diff(moment(get(shadow, "curve.start", +new Date())).startOf("day"), "day");
    set(shadow, "curve.day", day);
    console.log("setFeedingConfig ", shadow)
    if (shadow.curve.day) {
        shadow.loop = {
            insemination: 0,
            parturition: 0,
            endDay: 0
        };
        const curve = dicts[SettingTypes.FEEDING_CURVE].get(shadow.curve.number - 1);
        if (curve) {
            if (day > get(curve, "SetData.Days.length", 0)) {
                shadow.curve.day = get(curve, "SetData.Days.length", 0);
                shadow.loop.endDay = day - get(curve, "SetData.Days.length");
            }
            shadow.feed.plannedUsage = get(curve, `SetData.Days[${shadow.curve.day - 1}].ForageAmount`, 3000) * ((((shadow.curve.correction || 0) + 100) / 100))
        }
    }
    if (!shadow.curve.number) {
        delete shadow.schedule;
        delete shadow.forage;
        shadow.curve.id = undefined;
    } else {
        if (shadow.curve.day) {
            const curve = dicts[SettingTypes.FEEDING_CURVE].get(shadow.curve.number - 1);
            shadow.curve.id = get(curve, "SetID");
            const forageNr = get(curve, `SetData.Days[${shadow.curve.day - 1}].ForageType`, -1) + 1;
            const scheduleNr = get(curve, `SetData.Days[${shadow.curve.day - 1}].ForageType`, -1) + 1;
            shadow = {
                ...shadow,
                schedule: {
                    number: scheduleNr,
                    id: get(dicts[SettingTypes.FEEDING_SCHEDULE].get(scheduleNr - 1), "SetID")
                },
                forage: {
                    number: forageNr,
                    id: get(dicts[SettingTypes.FEEDING_FORAGE].get(forageNr - 1), "SetID")
                },
            }
        }

    }
    return shadow;
}


export const getFakeDataHistoryModal = () => {
    return [
        {
            "usage": 1.8,
            "plannedUsage": 3.6
        },
        {
            "usage": 3.6,
            "plannedUsage": 3.6
        },
        {
            "usage": 3.6,
            "plannedUsage": 3.6
        },
        {
            "usage": 3.6,
            "plannedUsage": 3.6
        },
        {
            "usage": 3.3,
            "plannedUsage": 3.3
        },
        {
            "usage": 2.8,
            "plannedUsage": 2.8
        },
        {
            "usage": 2.3,
            "plannedUsage": 2.3
        },
        {
            "usage": 2.4,
            "plannedUsage": 2.5
        },
        {
            "usage": 3,
            "plannedUsage": 3.1
        },
        {
            "usage": 3.8,
            "plannedUsage": 3.8
        },
        {
            "usage": 4.4,
            "plannedUsage": 4.4
        },
        {
            "usage": 4.8,
            "plannedUsage": 4.9
        },
        {
            "usage": 5.2,
            "plannedUsage": 5.3
        },
        {
            "usage": 5.6,
            "plannedUsage": 5.7
        },
        {
            "usage": 6.2,
            "plannedUsage": 6.2
        },
        {
            "usage": 6.6,
            "plannedUsage": 6.6
        },
        {
            "usage": 7,
            "plannedUsage": 7
        },
        {
            "usage": 7,
            "plannedUsage": 7
        },
        {
            "usage": 6.75,
            "plannedUsage": 7
        },
        {
            "usage": 7,
            "plannedUsage": 7
        },
        {
            "usage": 7,
            "plannedUsage": 7
        },
        {
            "usage": 7,
            "plannedUsage": 7
        },
        {
            "usage": 7,
            "plannedUsage": 7.1
        },
        {
            "usage": 7.5,
            "plannedUsage": 7.65
        },
        {
            "usage": 7.8,
            "plannedUsage": 7.85
        },
        {
            "usage": 8,
            "plannedUsage": 8.05
        },
        {
            "usage": 8.2,
            "plannedUsage": 8.25
        },
        {
            "usage": 8.4,
            "plannedUsage": 8.5
        },
        {
            "usage": 8.6,
            "plannedUsage": 8.7
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 2.2,
            "plannedUsage": 8.9
        }
    ].reverse().map((o, i) => ({
        usage: o.usage * 1000,
        plannedUsage: o.plannedUsage * 1000,
        time: moment.utc().subtract(i, "days").startOf("day"),
        name: moment.utc().subtract(i, "days").startOf("day").format("DD.MM.YY")

    }))
}
