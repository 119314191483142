import store from "../../store/store";
import * as moment from "moment";
import {changeShadowState} from "../../actions/shadowsActions";
import {getMinutesOfDay} from "../../utils/DateTimeUtils";
import {myID} from "../../libs/generateID";
import {MessageTypes} from "../../constans/mqttMessages";
import {addNotification} from "reapop";

export function fakeStartSendingDeviceState(devices) {
    const map = new Map();
    const shadows = store.getState().shadows.shadows;
    const last90days = (baseWeight = 5000000) => {
        const tmp = [];
        for (let i = 0; i < 90; i++) {
            const time = +moment.utc().startOf("day").subtract(i, "day");
            tmp.push({
                AggDt: generateFakeAggDt(baseWeight + (getFactor(90 - i) * (baseWeight * 3)), baseWeight + (getFactor(89 - i) * (baseWeight * 3)), time),
                AggTi: time,
                DtaModTime: time
            })
        }
        tmp.sort((o1, o2) => o1.AggTi - o2.AggTi)
        return tmp;
    };
    const getFactor = (index) => ((Math.pow(index, 2) / (-900)) + 9) / 9
  
    devices.forEach(dev => {
        if (!shadows.has(dev.DevID)) {
            const shadowTmp = getShadow({})
            for (let i = 0; i < 8; i++) {
                shadowTmp.weights[i].value = 2000000 * Math.random() + 1000000;
                const l90days = last90days(shadowTmp.weights[i].value);
                shadowTmp.addInfo[i].averageUsage = (l90days[0].AggDt[47].W - shadowTmp.weights[i].value) / 90
                shadowTmp.addInfo[i].daysToEmptySilo = Math.round(shadowTmp.weights[i].value / shadowTmp.addInfo[i].averageUsage);
                shadowTmp.addInfo[i].fillTime = l90days[0].AggTi
                store.dispatch({
                    type: "FETCH_AGGREGATED_DATA_FULFILLED",
                    payload: l90days,
                    meta: {DevID: `${dev.DevID}_${i}`}
                })
            }

            map.set(dev.DevID, shadowTmp);
        }
    });
    store.dispatch(changeShadowState(map));
}


function getShadow() {
    return {
        "tares": [
            {
                "number": 1,
                "value": 0
            },
            {
                "number": 2,
                "value": 0
            },
            {
                "number": 3,
                "value": 0
            },
            {
                "number": 4,
                "value": 0
            },
            {
                "number": 5,
                "value": 0
            },
            {
                "number": 6,
                "value": 0
            },
            {
                "number": 7,
                "value": -1000
            },
            {
                "number": 8,
                "value": -1000
            }
        ],
        "scales": [
            {
                "number": 1,
                "value": 0
            },
            {
                "number": 2,
                "value": 0
            },
            {
                "number": 3,
                "value": 0
            },
            {
                "number": 4,
                "value": 0
            },
            {
                "number": 5,
                "value": 0
            },
            {
                "number": 6,
                "value": 0
            },
            {
                "number": 7,
                "value": 65535
            },
            {
                "number": 8,
                "value": 65535
            }
        ],
        "weights": [
            {
                "number": 1,
                "value": 1910000
            },
            {
                "number": 2,
                "value": 1613000
            },
            {
                "number": 3,
                "value": 1636000
            },
            {
                "number": 4,
                "value": 1351000
            },
            {
                "number": 5,
                "value": 1198000
            },
            {
                "number": 6,
                "value": 1657000
            },
            {
                "number": 7,
                "value": -1000
            },
            {
                "number": 8,
                "value": -1000
            }
        ],
        "weightsRaw": [
            {
                "number": 1,
                "value": 0
            },
            {
                "number": 2,
                "value": 0
            },
            {
                "number": 3,
                "value": 0
            },
            {
                "number": 4,
                "value": 0
            },
            {
                "number": 5,
                "value": 0
            },
            {
                "number": 6,
                "value": 0
            },
            {
                "number": 7,
                "value": -1
            },
            {
                "number": 8,
                "value": -1
            }
        ],
        "statuses": [
            {
                "number": 1,
                "value": 1
            },
            {
                "number": 2,
                "value": 1
            },
            {
                "number": 3,
                "value": 1
            },
            {
                "number": 4,
                "value": 1
            },
            {
                "number": 5,
                "value": 1
            },
            {
                "number": 6,
                "value": 1
            },
            {
                "number": 7,
                "value": 2
            },
            {
                "number": 8,
                "value": 2
            }
        ],
        "configuration": {
            "version": 30,
            "pin": 0,
            "isPin": false,
            "sweepWeights": false,
            "flashDisplay": false,
            "slots": 8,
            "minWeights": [
                {
                    "number": 1,
                    "value": 0
                },
                {
                    "number": 2,
                    "value": 0
                },
                {
                    "number": 3,
                    "value": 0
                },
                {
                    "number": 4,
                    "value": 0
                },
                {
                    "number": 5,
                    "value": 0
                },
                {
                    "number": 6,
                    "value": 0
                },
                {
                    "number": 7,
                    "value": 0
                },
                {
                    "number": 8,
                    "value": 0
                }
            ]
        },
        "metadata": {
            "statuses": +new Date() - 1000,
            "weights": 1576146280494
        },
        "addInfo": [
            {
                "fillTime": 1575846000000,
                "minUsage": 1576018800000,
                "maxUsage": 1575932400000,
                "averageUsage": 36920,
                "daysToEmptySilo": 51
            },
            {
                "fillTime": 1576105200000,
                "minUsage": null,
                "maxUsage": null,
                "averageUsage": null,
                "daysToEmptySilo": null
            },
            {
                "fillTime": 1576105200000,
                "minUsage": null,
                "maxUsage": null,
                "averageUsage": null,
                "daysToEmptySilo": null
            },
            {
                "fillTime": 1575759600000,
                "minUsage": 1575846000000,
                "maxUsage": 1576018800000,
                "averageUsage": 368327,
                "daysToEmptySilo": 4
            },
            {
                "fillTime": 1576018800000,
                "minUsage": 1576105200000,
                "maxUsage": 1576105200000,
                "averageUsage": 601580,
                "daysToEmptySilo": 2
            },
            {
                "fillTime": 1576105200000,
                "minUsage": null,
                "maxUsage": null,
                "averageUsage": null,
                "daysToEmptySilo": null
            },
            {
                "fillTime": null,
                "minUsage": null,
                "maxUsage": null,
                "averageUsage": null,
                "daysToEmptySilo": null
            },
            {
                "fillTime": null,
                "minUsage": null,
                "maxUsage": null,
                "averageUsage": null,
                "daysToEmptySilo": null
            }
        ]
    }
}

const generateFakeAggDt = (endValue, startValue, timeDay) => {
    const result = [];
    let counter = 0;
    const max = (timeDay !== +moment.utc().startOf("day")
        ? 47
        : Math.floor(getMinutesOfDay(new Date()) / 30));
    const step = (startValue - endValue) / (max);
    for (let i = max; i >= 0; i--) {
        result.push({
            T: +moment(timeDay)
                .startOf("day")
                .add(i * 30, "minutes"),
            W: endValue + (step * counter),
            MW: 0,
        });
        counter++;
    }
    result.sort((o1, o2) => o1.T - o2.T)
    return result;
}

export function fakeCreateAndSendMessageObject(ClientID, GatewayID, LocalUserID, DeviceID, Command, data = undefined, notification = undefined, onSuccess = undefined, onError = undefined, onSend = undefined) {
    let id = myID();
    const message = {
        MsgId: id,
        PktType: MessageTypes.REQUEST,
        DeviceId: DeviceID,
        RTime: new Date().getTime(),
        Command: Array.isArray(Command) ? Command : [Command],
        CData: {},
        Priority: 69
    };
    if (data) {
        // tmp.CData = this.compressData(data);
        message.CData = data;
    }
    if (notification && notification.success) {
        store.dispatch(addNotification({
            ...notification.loading,
            ...notification.success
        }));
        console.log(notification);
    }
    const cmd = message.Command[0];
    const deviceIds = Array.isArray(message.DeviceId) ? message.DeviceId : [message.DeviceId]
    const map = new Map();
    const shadows = store.getState().shadows.shadows;
    switch (cmd) {
        case "SET_CONFIGURATION":
            for (let deviceId of deviceIds) {
                console.log("deviceId", deviceId)
                if (shadows.has(deviceId)) {
                    const shadow = shadows.get(deviceId);
                    console.log("shadow", shadow)
                    const {CData: {minWeights}} = message;
                    for (let {number, value} of minWeights) {
                        const idx = shadow.configuration.minWeights.findIndex(o => o.number === number)
                        if (~idx) {
                            shadow.configuration.minWeights[idx] = {number, value};
                        }
                    }
                    map.set(deviceId, shadow)
                }
            }

            break;
        default:
            break;
    }
    store.dispatch(changeShadowState(map));
    console.log(cmd, message);
    return id;
}