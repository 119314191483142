import store from "../../store/store";
import {changeShadowState} from "../../actions/shadowsActions";
import {myID} from "../../libs/generateID";
import {ChainFeedingCommandTypes, MessageTypes} from "../../constans/mqttMessages";
import {addNotification} from "reapop";
import {clone, get, set} from "lodash";
import {getMinutesOfDay} from "../../utils/DateTimeUtils";

export const INTERVAL_TIME = 10000;

export function fakeStartSendingDeviceState(devices) {
    const map = new Map();
    const shadows = store.getState().shadows.shadows;
    devices.forEach((dev) => {
        if (!shadows.has(dev.DevID)) {
            map.set(dev.DevID, getShadow({dev}));
        }
    });
    store.dispatch(changeShadowState(map));
}

export const refresh = (devices) => {
    const map = new Map();
    const shadows = store.getState().shadows.shadows;
    devices.forEach((dev) => {
        const shadow = clone(shadows.get(dev.DevID));
        if (shadow) {
            const workType = get(shadow, "workType.workType", 0);
            const excessWorkTime = get(shadow, "workType.excessWorkTime", 0);
            const result = {
                _done: get(shadow, "_done", false),
                _running: get(shadow, "_running", 0)
            }
            switch (workType) {
                case 1:
                    if (!result._done && result._running) {
                        result._done = result._running <= INTERVAL_TIME;
                        result._running = result._done ? 0 : result._running - INTERVAL_TIME;
                    }
                    break;
                case 3:
                    if (!result._done) {
                        if (!result._running) {
                            const schedule = get(shadow, "schedule", []);
                            const currentMinutes = getMinutesOfDay(+new Date())
                            const sched = schedule.find(o => getMinutesOfDay(o.start) <= currentMinutes && (currentMinutes <= getMinutesOfDay(o.stop)))
                            if (sched) {
                                result._running = sched.workTime + excessWorkTime;
                            }
                        }
                        result._done = result._running ? result._running <= INTERVAL_TIME : result._done;
                        if (result._running) {
                            result._running = result._done ? 0 : result._running - INTERVAL_TIME;
                        }


                    }

                    break;
                default:
                    result._running = 0;
                    break;

            }
            set(shadow, "_done", !!result._done);
            set(shadow, "isRunning", result._done ? false : !!result._running);
            set(shadow, "_running", result._running);
            set(shadow, "currentStatus.inputState[2]", !!result._done);
            map.set(dev.DevID, shadow);
        }
    });
    store.dispatch(changeShadowState(map));
}

export function fakeCreateAndSendMessageObject(
    ClientID,
    GatewayID,
    LocalUserID,
    DeviceID,
    Command,
    data = undefined,
    notification = undefined,
    onSuccess = undefined,
    onError = undefined,
    onSend = undefined
) {
    const id = myID();
    const message = {
        MsgId: id,
        PktType: MessageTypes.REQUEST,
        DeviceId: DeviceID,
        RTime: new Date().getTime(),
        Command: Array.isArray(Command) ? Command : [Command],
        CData: {},
        Priority: 69,
    };
    if (data) {
        message.CData = data;
    }
    if (notification && notification.success) {
        store.dispatch(
            addNotification({
                ...notification.loading,
                ...notification.success,
            })
        );
    }
    const cmd = message.Command[0];
    const map = new Map();
    const deviceIds = Array.isArray(DeviceID) ? DeviceID : [DeviceID];
    let counter = 0;
    deviceIds.forEach(devId => {
        const shadow = getShadow({dev: {DevID: devId}});
        let actionWasMade = true;
        switch (cmd) {
            case ChainFeedingCommandTypes.SET_WORK_TYPE: {
                set(shadow, "workType.workType", get(data, "workType", 0));
                set(shadow, "workType.sensorID", get(data, "sensorID", 1));
                set(shadow, "workType.maxWorkTime", get(data, "maxWorkTime", 60000));
                set(shadow, "workType.excessWorkTime", get(data, "excessWorkTime", 60000));
                set(shadow, "_done", [0].includes(get(data, "workType", 0)));
                set(shadow, "isRunning", false);
                break;
            }
            case ChainFeedingCommandTypes.SET_SCHEDULE: {
                set(shadow, "schedule", get(data, "schedule", []));
                set(shadow, "_done", false);
                set(shadow, "isRunning", false);


                break;
            }
            case ChainFeedingCommandTypes.SET_START_STOP_MOTOR: {
                const workType = get(shadow, "workType.workType", 0);
                if (workType === 1) {
                    const workTime = get(shadow, "workType.maxWorkTime", 60000);
                    const excessWorkTime = get(shadow, "workType.excessWorkTime", 60000);
                    set(shadow, "_running", !!get(data, "state", 0) ? workTime + excessWorkTime : 0);
                    set(shadow, "_done", !get(data, "state", 0));
                    set(shadow, "isRunning", !!get(data, "state", 0));
                }
                break;
            }
            default:
                actionWasMade = false;
                break;
        }
        if (actionWasMade) {
            counter++;
            map.set(devId, shadow);
        }
    })
    if (counter) {
        store.dispatch(changeShadowState(map, true));
    }
    return id;
}

const getShadow = ({dev, overrideShadowWith = {}}) => {
    const shadows = store.getState().shadows.shadows;
    const oldShadow = shadows.get(dev.DevID) || {
        "lastReset": 0,
        "isRunning": false,
        "currentStatus": {
            "rtc": 1593167608000,
            "psuVoltage": 24335680,
            "autoState": false,
            "manualState": false,
            "workTimeLeft": 0,
            "autoSet": true,
            "scheduleAnalogSet": true,
            "scheduleSet": true,
            "sensorSet": true,
            "inputState": [
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            "outputState": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            "sensor1": 0,
            "sensor2": 0,
            "sensor3": 1,
            "sensor4": 1,
            "sensorSilo": true,
            "sensorChain": true,
            "sensorFeeder": true,
            "outputMotor": true,
            "chainAlert": false,
            "siloAlert": false,
            "sensorChangeAlert": false
        },
        "workType": {
            "workType": 1,
            "inputNegation": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ],
            "outputNegation": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ],
            "excessWorkTime": 60000,
            "maxWorkTime": 60000,
            "sensorID": 2,
            "outputCurrentRangeReversal": 1,
            "outputMinCurrent": 5,
            "outputMaxCurrent": 20,
            "inputMinCurrent": 4,
            "inputMaxCurrent": 20,
            "actuatorPositioningTime": 10,
            "actuatorPositioningTimeMax": 10
        },
        "schedule": [
            {
                "start": 69300000,
                "stop": 69300000,
                "workTime": 60000
            }
        ],
        "analogSchedule": [
            {
                "start": 28740000,
                "stop": 32340000,
                "workTime": 240000
            },
            {
                "start": 36900000,
                "stop": 39600000,
                "workTime": 300000
            },
            {
                "start": 54000000,
                "stop": 61200000,
                "workTime": 450000
            }
        ],
        "metadata": {
            "workType": +new Date() - 1000,
            "currentStatus": 1593167607968,
            "analogSchedule": 1593167608193,
            "schedule": 1593167608394
        }
    }
    return {
        ...oldShadow,
        ...overrideShadowWith
    };
}
