import lokiDB, {devices} from "./lokiDB"
import buildingsDB from "./buildingsDB"
import {DevType} from "../constans/devices";
import _ from "lodash";
import {getModificationTime, insertInto, setModificationTime} from "../utils/LokiUtils";
import Gateway from "../beans/devices/Gateway"
import {getDeviceClass} from "../utils/DevicesUtils";

class Devices {

    constructor() {
        this.getGateway = _.memoize(this.getGateway, device => device ? `${device.DevID}_${device.DtaModTime}` : "NoDevice");
    }


    /**
     * Funkcja do pobierania ostatniej daty modyfikacji farmy na danej fermie
     * @param id FarmID
     * @return {{DtaModTime: string}} 0 jeśli nie było wcześniej informacji o ostatniej dacie modyfikacji dla danego farmdID
     */
    getModificationTime(id) {
        return getModificationTime("devices", "FarmID", id);
    }

    clearCache() {
        this.getGateway.cache.clear();
    }

    getGateway(device) {
        if (device) {
            if (device.DevType === DevType.GATEWAY) {
                return new Gateway(device);
            }
            let ParentID = device.ParentID;
            if (ParentID) {
                let parent = devices.findOne({DtaDltTime: {$type: 'undefined'}, DevID: ParentID});
                return this.getGateway(parent);
            }
        }
        return null;
    }

    /**
     * Metoda przyjmuje obiekt urzadzenia i znajduje GatewayID na podstaiwe parentow
     * @param device
     * @returns {*}
     */
    getDeviceWithGateway(device) {
        if (!device) return undefined;
        let dev = _.cloneDeep(device);
        let gateway = this.getGateway(dev);
        if (gateway && gateway.DevID) {
            dev.GatewayID = gateway.DevID;
        }
        return getDeviceClass(dev);
    }

    async insertIntoDevices(values) {
        insertInto(values, "devices", "DevID");
        setModificationTime("devices", values[values.length - 1].DtaModTime, "FarmID", values[values.length - 1].FarmID);
        this.clearCache();
        await lokiDB.asyncSaveDB();
    }

    getDeviceByID(id) {
        return this.getDeviceWithGateway(devices.findOne({'DevID': id}));
    }

    getDevices(FarmID) {
        try {
            return devices.find({FarmID, DtaDltTime: {$type: 'undefined'}}).map(dev => this.getDeviceWithGateway(dev));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * Gets devices in passed placement and its children
     * @param placement
     * @param initDevs
     * @param showDevicesInChildren
     * @param allDevs
     * @returns {(Bridge|Cage|Climate|DispenserWST|DispenserNRF|Gateway|*)[]}
     */
    getDevicesInPlcmnt(placement, {
        initDevs = [], showDevicesInChildren = true, allDevs = devices.find({
            DtaDltTime: {$type: 'undefined'},
            PlcmntID: {$type: 'array'}
        })
    } = {}) {
        //keys with children every location has different children
        let keys = ["Buildings", "Sectors", "Chambers", "Boxes"];
        let devs = initDevs;
        if (placement) {
            let id = placement.BgID || placement.SID || placement.CID || placement.BID;
            let devsInPlcmnts = allDevs.filter(item => item.PlcmntID.filter(plcmnt => plcmnt.PlcmntID === id).length > 0);
            devs = [...devs, ...devsInPlcmnts];
            if (showDevicesInChildren) {
                for (let key of keys) {
                    if (placement[key]) {
                        for (let anotherPlacement of placement[key]) {
                            devs = this.getDevicesInPlcmnt(anotherPlacement, {
                                initDevs: devs,
                                showDevicesInChildren,
                                allDevs
                            });
                        }
                    }
                }
            }

        }
        return _.uniqBy(devs, o => o.DevID).map(item => this.getDeviceWithGateway(item));
    }

    /**
     * Gets devices by passed placementID and its children
     * @param placementID
     * @param showDevicesInChildren
     * @returns {(Bridge|Cage|Climate|DispenserWST|DispenserNRF|Gateway|*)[]}
     */
    getDevicesInPlcmntID(placementID, {showDevicesInChildren = true} = {}) {
        return this.getDevicesInPlcmnt(buildingsDB.getLocationByID(placementID), {showDevicesInChildren});
    }


    getDevicesByType(devType) {
        return devices.find({
            DevType: devType,
            DtaDltTime: {$type: 'undefined'}
        }).map(dev => this.getDeviceWithGateway(dev));
    }

    /**
     * Gets device by passed attributes
     * @param attributes
     * @returns {*}
     */
    getDevicesByAttributes(attributes = {}) {
        try {
            return devices.find({DtaDltTime: {$type: 'undefined'}, ...attributes}).map(dev => this.getDeviceWithGateway(dev));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getDevicesWithParentID(parentID) {
        try {
            return devices.find({
                DtaDltTime: {$type: 'undefined'},
                ParentID: parentID
            }).map(dev => this.getDeviceWithGateway(dev));
        } catch (e) {
            return [];
        }
    }

    getDevicesWithAddress(farmID, address) {
        try {
            return devices.find({
                FarmID: farmID,
                DtaDltTime: {$type: 'undefined'},
                Address: _.isArray(address) ? {$in: address} : address
            }).map(dev => this.getDeviceWithGateway(dev));
        } catch (e) {
            return [];
        }
    }

    getAllDevices() {
        try {
            return devices.find({DtaDltTime: {$type: 'undefined'}}).map(dev => this.getDeviceWithGateway(dev));
        } catch (e) {
            return [];
        }
    }
}

const devicesDB = new Devices();
export default devicesDB;