import lokiDB, {test} from "./lokiDB"
import {getModificationTime, insertInto} from "../utils/LokiUtils";

class Test {

    /**********************************
     ***         DOCUMENTS              ***
     **********************************/

    /**
     * Funkcja do pobierania ostatniej daty modyfikacji farmy na danej fermie
     * @return {{DtaModTime: string}} 0 jeśli nie było wcześniej informacji o ostatniej dacie modyfikacji dla danego farmdID
     */
    getModificationTime() {
        return getModificationTime("test");
    }

    //insert into dictionary
    async insertIntoTest(values) {
        insertInto(values, "test", "AnmID");
        await lokiDB.asyncSaveDB();
    }

    onlyInsert(values) {
        test.insert(values);
    }

    getAll() {
        try {
            return test.find();
        } catch (e) {
            return [];
        }
    }

    clear() {
        test.chain().find().remove();
    }
}

const testLokiDB = new Test();
export default testLokiDB;