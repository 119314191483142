// import LokiIndexedCryptoAdapter from "./adapter/LokiIndexedCryptoAdapter";
import animalsDB from "./animalsDB";
import buildingsDB from "./buildingsDB";
import devicesDB from "./devicesDB";
import eventsDB from "./eventsDB";
import LokiIndexedAdapter from "lokijs/src/loki-indexed-adapter";

// let LokiIndexedAdapter;
// if (process.env.NODE_ENV === 'test') {
//     LokiIndexedAdapter = require('lokijs/src/loki-fs-structured-adapter');
// } else {
//     LokiIndexedAdapter = require('lokijs/src/loki-indexed-adapter');
// }

const Loki = require('lokijs');

export const DATABASE = {
    key: 'fetura_',//+getCurrentUser().pool.storage["aws.cognito.identity-id.eu-central-1:419e1198-ad2a-4ada-9921-895a83a624ca"],
    database: 'fetura.db'//+getCurrentUser().pool.storage["aws.cognito.identity-id.eu-central-1:419e1198-ad2a-4ada-9921-895a83a624ca"]+'.db'
};

export var db;

//table-grid animals
export var animals;
//table-grid buildings
export var buildings;
//table-grid cachedTablesParams
export var cachedTablesParams;
//table-grid events
export var events;
//table-grid devices
export var devices;
//table-grid dictionaries
export var dictionary;
//table-grid technologyGroups
export var technologyGroups;
//table-grid settings
export var settings;
//table-grid users
export var users;
//table-grid commandsHistory
export var commandsHistory;
//table-grid documents
export var documents;
//table-grid notifications
export var notifications;
//table-grid aggregated
export var aggregated;
//table-grid groups
export var groups;
//table test
export var test;
//table reports
export var reports;
//table api
export var api;
// table for forms
export var formData;

async function initDB(dbName, adapter) {
    return new Promise((resolve, reject) => {
        try {
            db = new Loki(dbName, {
                // autoload: true,
                // autoloadCallback: loadHandler,
                autosave: true,
                autosaveInterval: 10000, // 1 seconds
                adapter: adapter,
                verbose: true,
                serializationMethod: "pretty"
            });
            resolve(db);
        } catch (error) {
            console.error(error);
            reject(error)
        }
    })
}

export let getCollection = (collectionName, id = "") => {
    try {
        let coll = db.getCollection(collectionName);
        return coll ? coll : db.addCollection(collectionName, {
            unique: [id],
            // indices: [id], find nie dziala bo w pewnym momencie ilosc binaryIndicies nie zgadza sie z iloscia danych, tl;dr nie ma indicies nie ma problemu
            clone: true,
            cloneMethod: "parse-stringify",
            disableMeta: true
        });
    } catch (error) {
        console.error(error);
    }
};

function loadHandler() {
    // if database did not exist it will be empty so I will intitialize here
    cachedTablesParams = getCollection('cachedTablesParams');
    animals = getCollection('animals', "AnmID");
    buildings = getCollection('buildings', "BgID");
    events = getCollection('events', "EvID");
    devices = getCollection('devices', "DevID");
    dictionary = getCollection('dictionary');
    commandsHistory = getCollection('commandsHistory');
    users = getCollection('users');
    notifications = getCollection('notifications');
    groups = getCollection("groups");
    technologyGroups = getCollection('technoGroups');
    if (technologyGroups.data.length === 0) {
        let item2 = {
            createDay: 0
        };
        technologyGroups.insert(item2);
    }
    settings = getCollection('settings');
    if (settings.data.length === 0) {
        let item3 = {
            look: {
                insertionType: 0,
                showDead: false,
                autoMerge: false,
                borderTiles: false
            }
        };
        settings.insert(item3);
    }
    documents = getCollection('documents');
    aggregated = getCollection('aggregated');
    test = getCollection('test');
    reports = getCollection('reports');
    api = getCollection("api");
    formData = getCollection("formData");
    animalsDB.clearCache();
    buildingsDB.clearCache();
    devicesDB.clearCache();
    eventsDB.clearCache();
}

class Database {

    loadDB(_db) {
        return new Promise((resolve, reject) => {
            _db.loadDatabase({}, err => {
                if (err) {
                    console.error("LOAD DATABASE", err);
                    reject(err);
                }
                loadHandler();
                resolve();
            })
        })
    }

    /**********************************
     ***         GENERAL            ***
     **********************************/
    async loadDatabase(login) {
        DATABASE.key = 'fetura_' + login;
        DATABASE.database = 'fetura_' + login + '.db';
        if (process.env.NODE_ENV === 'test') {
            let adb = require("lokijs/src/loki-fs-structured-adapter");
            const adapter = new adb(DATABASE.key);
            await initDB(DATABASE.database, adapter);
        } else {
            const idbAdapter = new LokiIndexedAdapter(DATABASE.key);
            // let pa = new Loki.LokiPartitioningAdapter(idbAdapter, {paging: true});
            await initDB(DATABASE.database, idbAdapter);
        }
        await this.loadDB(db);
        // Promise.resolve();
    }

    asyncSaveDB() {
        return new Promise((resolve, reject) => {
            db.saveDatabase(err => {
                if (err) reject(err);
                resolve();
            })
        })
    }

    saveDB() {
        db.saveDatabase(function (err) {
            if (process.env.NODE_ENV !== 'test') {
                console.log("SAVE");
            }
            if (err) {
                console.error("SAVE ERROR DB", err);
            }
            if (process.env.NODE_ENV !== 'test') {
                console.log("save database successfull")
            }
        });
    }
}

const lokiDB = new Database();
export default lokiDB;