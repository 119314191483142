import {myID} from "../../libs/generateID";
import {MessageTypes} from "../../constans/mqttMessages";
import store from "../../store/store";
import {addNotification} from "reapop";
import {changeShadowState} from "../../actions/shadowsActions";
import {getMinutesOfDay} from "../../utils/DateTimeUtils";
import moment from "moment";
import _ from "lodash";

const AverageDeviceUsage = 40;//40W na urzadzenie plus minus

const getHourlyUsage = _.memoize(() => {
    return store.getState().farmDevices.devices.length * AverageDeviceUsage
})

export function refresh(devices) {
    const map = new Map();
    const perDeviceUsage = Math.ceil(getHourlyUsage() / devices.length);
    devices.forEach((dev) => {
        const {DevID} = dev;
        const shadow = store.getState().shadows.shadows.get(DevID) || {};
        const newShadow = getShadow(shadow, dev, perDeviceUsage);
        map.set(DevID, newShadow);
    });
    store.dispatch(changeShadowState(map));
}

function getShadow(newValues = {}, device = {}, perDeviceUsage = 0) {
    const newShadow = {
        _flowTime1: 0,
        _flowTime2: 0,
        _flowTime3: 0,
        totalActiveEnergyConsumption: 4240,
        activePowerConsumption: 0,
        activePowerConsumptionL1: 0,
        activePowerConsumptionL2: 0,
        activePowerConsumptionL3: 0,
        ...newValues,
        lastReceiveTime: +new Date() - 50000,
        deviceTime: +new Date() - 50000,
        metadata: {
            lastReceiveTime: +new Date() - 50000,
        },
    };
    newShadow.activePowerConsumptionL1 = (perDeviceUsage / 3) + Math.ceil(Math.random() * 200)
    newShadow.activePowerConsumptionL2 = (perDeviceUsage / 3) + Math.ceil(Math.random() * 200)
    newShadow.activePowerConsumptionL3 = (perDeviceUsage / 3) + Math.ceil(Math.random() * 200)
    newShadow.activePowerConsumption = newShadow.activePowerConsumptionL1 + newShadow.activePowerConsumptionL2 + newShadow.activePowerConsumptionL3
    newShadow.totalActiveEnergyConsumption = newShadow.totalActiveEnergyConsumption + newShadow.activePowerConsumption / 720;
    return newShadow;
}

export function fakeStartSendingDeviceState(devices) {
    const map = new Map();
    const shadows = store.getState().shadows.shadows;
    const date = moment.utc().startOf("day").subtract(89, "day");
    const last90Days = [];
    const vals = {};
    const perDeviceUsage = Math.ceil(getHourlyUsage() / devices.length);
    for (let i = 0; i < 90; i++) {
        const time = +date.clone().add(i, "day");
        last90Days.push({
            AggDt: generateFakeAggDt(
                time,
                i > 0 ? last90Days[i - 1].AggDt[47].MR : undefined,
                perDeviceUsage
            ),
            AggTi: time,
            DtaModTime: time,
        });
        if (i === 89) {
            vals.totalActiveEnergyConsumption =
                last90Days[89].AggDt[last90Days[89].AggDt.length - 1].MR + 100;
        }
    }
    devices.forEach((dev) => {
        if (!shadows.has(dev.DevID)) {
            map.set(dev.DevID, getShadow(vals, dev, perDeviceUsage));
            store.dispatch({
                type: "FETCH_AGGREGATED_DATA_FULFILLED",
                payload: last90Days,
                meta: {DevID: dev.DevID},
            });
        }
    });
    store.dispatch(changeShadowState(map));
}

export function fakeCreateAndSendMessageObject(
    ClientID,
    GatewayID,
    LocalUserID,
    DeviceID,
    Command,
    data = undefined,
    notification = undefined,
    onSuccess = undefined,
    onError = undefined,
    onSend = undefined
) {
    let id = myID();
    const message = {
        MsgId: id,
        PktType: MessageTypes.REQUEST,
        DeviceId: DeviceID,
        RTime: new Date().getTime(),
        Command: Array.isArray(Command) ? Command : [Command],
        CData: {},
        Priority: 69,
    };
    if (data) {
        message.CData = data;
    }
    if (notification && notification.success) {
        store.dispatch(
            addNotification({
                ...notification.loading,
                ...notification.success,
            })
        );
    }
    const shadows = store.getState().shadows.shadows;
    const cmd = message.Command[0];
    console.log(cmd, shadows, message);
    return id;
}

const generateFakeAggDt = (timeDay, dayBefore = 5000, perDeviceUsage = 0) => {
    const result = [];
    for (
        let i = 0;
        i <
        (timeDay !== +moment.utc().startOf("day")
            ? 48
            : Math.ceil(getMinutesOfDay(new Date()) / 30));
        i++
    ) {
        let factor = (Math.pow(i - 24, 2) / (-80) + 48) / 48;
        result.push({
            T: +moment(timeDay)
                .startOf("day")
                .add(i * 30, "minutes"),
            MR:
                ((i ? result[i - 1].MR : dayBefore) +
                    Math.pow(factor, 45) * Math.floor(perDeviceUsage + Math.random() * (40)) / 2),
            AF: 0,
        });
    }
    return result;
};
