import lokiDB, {notifications} from "./lokiDB"
import moment from "moment";
import {getModificationTime, insertInto, setModificationTime} from "../utils/LokiUtils";
import _ from "lodash";
import store from "../store/store";

class Notifications {

    _filterByUserRemoved(list, LocalUserID) {
        try {
            return list.filter(notification => !notification.DeleteIDs || !(_.isArray(notification.DeleteIDs) ? notification.DeleteIDs.includes(LocalUserID) : notification.DeleteIDs.values.includes(LocalUserID)));
        } catch (e) {
            return list;
        }
    }

    getModificationTime(id) {
        return getModificationTime("notifications", "FarmID", id);
    }

    async insertIntoNotifications(values) {
        if (values.length > 0) {
            insertInto(values, "notifications", "NID");
            // console.log(values);
            setModificationTime("notifications", values[values.length - 1].DtaModTime, "FarmID", values[values.length - 1].FarmID);
            await lokiDB.asyncSaveDB();
        }
    }

    getAllNotifications(farmID, localUserID = store.getState().user.user.LocalUserID) {
        try {
            return this._filterByUserRemoved(notifications.find({
                FarmID: farmID,
                DtaCrtTime: {$lte: moment().toDate().getTime()}
            }), localUserID);
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getLastNumberOfNotifications(farmID, number = 10, offset = 0, localUserID = store.getState().user.user.LocalUserID) {
        try {
            return this._filterByUserRemoved(notifications.chain().find({
                FarmID: farmID,
                DtaCrtTime: {$lte: moment().toDate().getTime()}
            }).simplesort("DtaCrtTime", {desc: true}).offset(offset).limit(number).data(), localUserID);
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getUnreadNotificationsCount(farmID, localUserID) {
        try {
            let res = notifications.find({FarmID: farmID, DtaCrtTime: {$lte: moment().toDate().getTime()}});
            return this._filterByUserRemoved(res.filter(notification => {
                try {
                    return _.isArray(notification.ReadIDs) ? !notification.ReadIDs.includes(localUserID) : !notification.ReadIDs.values.includes(localUserID);
                } catch (err) {
                    console.error(err)
                    return false;
                }
            }), localUserID).length;
        } catch (e) {
            console.error(e);
            return 0;
        }
    }

    getAllNotificationsCount(farmID, localUserID = store.getState().user.user.LocalUserID) {
        try {
            return this._filterByUserRemoved(notifications.find({
                FarmID: farmID,
                DtaCrtTime: {$lte: moment().toDate().getTime()}
            }), localUserID).length
        } catch (e) {
            console.error(e);
            return 0;
        }
    }

    removeOldRecords(farmID) {
        try {
            notifications.chain().find({
                FarmID: farmID,
                DtaCrtTime: {$lte: moment().subtract(2, "months").toDate().getTime()}
            }).remove();
        } catch (e) {
            console.error(e);
        }
    }
}

const notificationsDB = new Notifications();
export default notificationsDB;