import {api} from "./lokiDB";

class Api {

    insert(path, method, headers, query, body) {
        api.insert({
            time: new Date().getTime(),
            path,
            method,
            headers,
            query,
            body: JSON.parse(body)
        })
    }

    getData() {
        let minTime = new Date().getTime() - (1000 * 60 * 60 * 24);
        return api.find({time: {$gte: minTime}});
    }

}

const apiDB = new Api();
export default apiDB;