export const fakeAggData = [
    [
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.06153846153846154,
            "HM": 0.5395735294117647,
            "HU": 0.5337843137254902,
            "MA": 0.5523235294117647,
            "NA": 0.556578431372549,
            "PS": 0.5127867647058824,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.06153846153846154,
            "HM": 0.5395735294117647,
            "HU": 0.5337843137254902,
            "MA": 0.5523235294117647,
            "NA": 0.556578431372549,
            "PS": 0.5127867647058824,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.13846153846153847,
            "BPCHU": 0.15384615384615385,
            "BPCMA": 0.16923076923076924,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.13846153846153847,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.13846153846153847,
            "GPCPS": 0.12307692307692308,
            "HM": 0.5084235294117647,
            "HU": 0.5015882352941177,
            "MA": 0.4804191176470588,
            "NA": 0.48909803921568623,
            "PS": 0.5210919117647059,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.13846153846153847,
            "BPCHU": 0.15384615384615385,
            "BPCMA": 0.16923076923076924,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.13846153846153847,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.13846153846153847,
            "GPCPS": 0.12307692307692308,
            "HM": 0.5084235294117647,
            "HU": 0.5015882352941177,
            "MA": 0.4804191176470588,
            "NA": 0.48909803921568623,
            "PS": 0.5210919117647059,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.13846153846153847,
            "BPCHU": 0.15384615384615385,
            "BPCMA": 0.16923076923076924,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.16923076923076924,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.13846153846153847,
            "HM": 0.5102459893048128,
            "HU": 0.5122264705882353,
            "MA": 0.49335620915032674,
            "NA": 0.49377941176470586,
            "PS": 0.5214444444444444,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.15384615384615385,
            "BPCHU": 0.16923076923076924,
            "BPCMA": 0.18461538461538463,
            "BPCNA": 0.16923076923076924,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.2,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.16923076923076924,
            "HM": 0.5089660633484162,
            "HU": 0.5252328431372549,
            "MA": 0.49128342245989304,
            "NA": 0.4936225490196079,
            "PS": 0.5194171122994653,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2153846153846154,
            "BPCHU": 0.3076923076923077,
            "BPCMA": 0.27692307692307694,
            "BPCNA": 0.23076923076923078,
            "BPCPS": 0.24615384615384617,
            "GPCHM": 0.3076923076923077,
            "GPCHU": 0.2153846153846154,
            "GPCMA": 0.24615384615384617,
            "GPCNA": 0.2923076923076923,
            "GPCPS": 0.27692307692307694,
            "HM": 0.5230926470588235,
            "HU": 0.5324096638655462,
            "MA": 0.5086231617647059,
            "NA": 0.5076888544891641,
            "PS": 0.5206846405228758,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2153846153846154,
            "BPCHU": 0.3384615384615385,
            "BPCMA": 0.27692307692307694,
            "BPCNA": 0.23076923076923078,
            "BPCPS": 0.24615384615384617,
            "GPCHM": 0.3384615384615385,
            "GPCHU": 0.2153846153846154,
            "GPCMA": 0.27692307692307694,
            "GPCNA": 0.3230769230769231,
            "GPCPS": 0.3076923076923077,
            "HM": 0.5288542780748664,
            "HU": 0.5324096638655462,
            "MA": 0.5225620915032679,
            "NA": 0.5154929971988795,
            "PS": 0.5282205882352942,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.24615384615384617,
            "BPCHU": 0.4,
            "BPCMA": 0.3076923076923077,
            "BPCNA": 0.26153846153846155,
            "BPCPS": 0.27692307692307694,
            "GPCHM": 0.38461538461538464,
            "GPCHU": 0.23076923076923078,
            "GPCMA": 0.3230769230769231,
            "GPCNA": 0.36923076923076925,
            "GPCPS": 0.35384615384615387,
            "HM": 0.5318729411764705,
            "HU": 0.539156862745098,
            "MA": 0.5228711484593837,
            "NA": 0.5156825980392157,
            "PS": 0.5282583120204604,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3230769230769231,
            "BPCHU": 0.49230769230769234,
            "BPCMA": 0.38461538461538464,
            "BPCNA": 0.3076923076923077,
            "BPCPS": 0.35384615384615387,
            "GPCHM": 0.4307692307692308,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.36923076923076925,
            "GPCNA": 0.4461538461538462,
            "GPCPS": 0.4,
            "HM": 0.5264327731092437,
            "HU": 0.5317560553633218,
            "MA": 0.5184656862745097,
            "NA": 0.5173133874239351,
            "PS": 0.5237680995475112,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.36923076923076925,
            "BPCHU": 0.5538461538461539,
            "BPCMA": 0.4153846153846154,
            "BPCNA": 0.3230769230769231,
            "BPCPS": 0.36923076923076925,
            "GPCHM": 0.47692307692307695,
            "GPCHU": 0.2923076923076923,
            "GPCMA": 0.4307692307692308,
            "GPCNA": 0.5230769230769231,
            "GPCPS": 0.47692307692307695,
            "HM": 0.5205265654648956,
            "HU": 0.5333978328173374,
            "MA": 0.5121039915966387,
            "NA": 0.5150891003460207,
            "PS": 0.5280569259962049,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.36923076923076925,
            "BPCHU": 0.5538461538461539,
            "BPCMA": 0.4153846153846154,
            "BPCNA": 0.3230769230769231,
            "BPCPS": 0.36923076923076925,
            "GPCHM": 0.5076923076923077,
            "GPCHU": 0.3230769230769231,
            "GPCMA": 0.46153846153846156,
            "GPCNA": 0.5538461538461539,
            "GPCPS": 0.5076923076923077,
            "HM": 0.5172156862745098,
            "HU": 0.5389677871148459,
            "MA": 0.5095617647058823,
            "NA": 0.5136045751633986,
            "PS": 0.525729055258467,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.38461538461538464,
            "BPCHU": 0.5846153846153846,
            "BPCMA": 0.4307692307692308,
            "BPCNA": 0.3384615384615385,
            "BPCPS": 0.38461538461538464,
            "GPCHM": 0.5230769230769231,
            "GPCHU": 0.3230769230769231,
            "GPCMA": 0.47692307692307695,
            "GPCNA": 0.5692307692307692,
            "GPCPS": 0.5230769230769231,
            "HM": 0.5176799307958478,
            "HU": 0.5389677871148459,
            "MA": 0.5088330170777989,
            "NA": 0.5133092209856915,
            "PS": 0.5291738754325259,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.03076923076923077,
            "HM": 0.5742647058823529,
            "HU": 0.648,
            "MA": 0.55525,
            "NA": 0.5872352941176471,
            "PS": 0.6041470588235294,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.046153846153846156,
            "HM": 0.5991960784313726,
            "HU": 0.6563235294117648,
            "MA": 0.5896176470588236,
            "NA": 0.6079411764705882,
            "PS": 0.6192647058823529,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.046153846153846156,
            "HM": 0.5991960784313726,
            "HU": 0.6563235294117648,
            "MA": 0.5896176470588236,
            "NA": 0.6079411764705882,
            "PS": 0.6192647058823529,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.07692307692307693,
            "HM": 0.6012058823529411,
            "HU": 0.6578627450980392,
            "MA": 0.5957411764705882,
            "NA": 0.6123882352941177,
            "PS": 0.6159588235294117,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.1076923076923077,
            "HM": 0.6050462184873949,
            "HU": 0.6501397058823529,
            "MA": 0.6070210084033614,
            "NA": 0.6124705882352941,
            "PS": 0.6190420168067228,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.1076923076923077,
            "HM": 0.6127463235294117,
            "HU": 0.6501397058823529,
            "MA": 0.6070210084033614,
            "NA": 0.6124705882352941,
            "PS": 0.6190420168067228,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.12307692307692308,
            "HM": 0.6150653594771242,
            "HU": 0.6501397058823529,
            "MA": 0.6070210084033614,
            "NA": 0.6124705882352941,
            "PS": 0.6209080882352941,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.12307692307692308,
            "HM": 0.6150653594771242,
            "HU": 0.6501397058823529,
            "MA": 0.6070210084033614,
            "NA": 0.6124705882352941,
            "PS": 0.6209080882352941,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.13846153846153847,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.12307692307692308,
            "HM": 0.6150653594771242,
            "HU": 0.6501397058823529,
            "MA": 0.6070210084033614,
            "NA": 0.6124705882352941,
            "PS": 0.6209080882352941,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.16923076923076924,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.12307692307692308,
            "HM": 0.6150653594771242,
            "HU": 0.6501397058823529,
            "MA": 0.6070210084033614,
            "NA": 0.6124705882352941,
            "PS": 0.6209080882352941,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.2,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.12307692307692308,
            "BPCPS": 0.13846153846153847,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.12307692307692308,
            "HM": 0.6150653594771242,
            "HU": 0.6501397058823529,
            "MA": 0.6070210084033614,
            "NA": 0.6124705882352941,
            "PS": 0.6209080882352941,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.03076923076923077,
            "HM": 0.5657352941176471,
            "HU": 0.6433529411764706,
            "MA": 0.38885294117647057,
            "NA": 0.5558970588235295,
            "PS": 0.5755294117647058,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.07692307692307693,
            "HM": 0.5783470588235294,
            "HU": 0.6376911764705883,
            "MA": 0.5322867647058823,
            "NA": 0.5658411764705882,
            "PS": 0.5948941176470589,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.07692307692307693,
            "HM": 0.5783470588235294,
            "HU": 0.6376911764705883,
            "MA": 0.5322867647058823,
            "NA": 0.5658411764705882,
            "PS": 0.5948941176470589,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.07692307692307693,
            "HM": 0.5783470588235294,
            "HU": 0.6376911764705883,
            "MA": 0.5322867647058823,
            "NA": 0.5658411764705882,
            "PS": 0.5948941176470589,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.09230769230769231,
            "HM": 0.5785980392156862,
            "HU": 0.6477450980392156,
            "MA": 0.5582,
            "NA": 0.5635049019607844,
            "PS": 0.5879215686274509,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.1076923076923077,
            "HM": 0.5912941176470589,
            "HU": 0.6477450980392156,
            "MA": 0.5779901960784314,
            "NA": 0.5781806722689076,
            "PS": 0.5987394957983194,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.18461538461538463,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.15384615384615385,
            "HM": 0.6001558823529412,
            "HU": 0.6367117647058824,
            "MA": 0.5964967320261438,
            "NA": 0.5910294117647059,
            "PS": 0.6061911764705883,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.18461538461538463,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.16923076923076924,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.15384615384615385,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.16923076923076924,
            "HM": 0.60374064171123,
            "HU": 0.6407990196078431,
            "MA": 0.6011058823529412,
            "NA": 0.5954117647058823,
            "PS": 0.6092647058823529,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.23076923076923078,
            "BPCHU": 0.3384615384615385,
            "BPCMA": 0.24615384615384617,
            "BPCNA": 0.23076923076923078,
            "BPCPS": 0.2,
            "GPCHM": 0.3230769230769231,
            "GPCHU": 0.2153846153846154,
            "GPCMA": 0.2923076923076923,
            "GPCNA": 0.3230769230769231,
            "GPCPS": 0.3384615384615385,
            "HM": 0.5785980392156862,
            "HU": 0.6049957983193277,
            "MA": 0.5777058823529412,
            "NA": 0.5582759103641457,
            "PS": 0.5700374331550803,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.27692307692307694,
            "BPCHU": 0.49230769230769234,
            "BPCMA": 0.3384615384615385,
            "BPCNA": 0.27692307692307694,
            "BPCPS": 0.24615384615384617,
            "GPCHM": 0.49230769230769234,
            "GPCHU": 0.27692307692307694,
            "GPCMA": 0.4153846153846154,
            "GPCNA": 0.49230769230769234,
            "GPCPS": 0.5076923076923077,
            "HM": 0.5827159926470589,
            "HU": 0.610937908496732,
            "MA": 0.5716546840958605,
            "NA": 0.5642545955882353,
            "PS": 0.5766818181818182,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2923076923076923,
            "BPCHU": 0.5384615384615384,
            "BPCMA": 0.36923076923076925,
            "BPCNA": 0.2923076923076923,
            "BPCPS": 0.26153846153846155,
            "GPCHM": 0.5384615384615384,
            "GPCHU": 0.2923076923076923,
            "GPCMA": 0.4461538461538462,
            "GPCNA": 0.5384615384615384,
            "GPCPS": 0.5538461538461539,
            "HM": 0.5886361344537816,
            "HU": 0.612530959752322,
            "MA": 0.5769087221095335,
            "NA": 0.5717042016806723,
            "PS": 0.5829975490196079,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3230769230769231,
            "BPCHU": 0.5692307692307692,
            "BPCMA": 0.4,
            "BPCNA": 0.3230769230769231,
            "BPCPS": 0.2923076923076923,
            "GPCHM": 0.5538461538461539,
            "GPCHU": 0.3076923076923077,
            "GPCMA": 0.46153846153846156,
            "GPCNA": 0.5538461538461539,
            "GPCPS": 0.5692307692307692,
            "HM": 0.5892066993464052,
            "HU": 0.6144323529411765,
            "MA": 0.5772647058823529,
            "NA": 0.5727516339869281,
            "PS": 0.5837718600953895,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3230769230769231,
            "BPCHU": 0.5692307692307692,
            "BPCMA": 0.4,
            "BPCNA": 0.3230769230769231,
            "BPCPS": 0.2923076923076923,
            "GPCHM": 0.5846153846153846,
            "GPCHU": 0.3384615384615385,
            "GPCMA": 0.49230769230769234,
            "GPCNA": 0.5846153846153846,
            "GPCPS": 0.6,
            "HM": 0.5897422600619195,
            "HU": 0.6194224598930481,
            "MA": 0.581250919117647,
            "NA": 0.5732933436532508,
            "PS": 0.5849653092006033,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3384615384615385,
            "BPCHU": 0.5846153846153846,
            "BPCMA": 0.4153846153846154,
            "BPCNA": 0.3384615384615385,
            "BPCPS": 0.3076923076923077,
            "GPCHM": 0.6153846153846154,
            "GPCHU": 0.36923076923076925,
            "GPCMA": 0.5230769230769231,
            "GPCNA": 0.6153846153846154,
            "GPCPS": 0.6307692307692307,
            "HM": 0.5883235294117647,
            "HU": 0.6182794117647059,
            "MA": 0.5817335640138408,
            "NA": 0.5741154411764706,
            "PS": 0.5853895265423243,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.35384615384615387,
            "BPCHU": 0.6,
            "BPCMA": 0.4307692307692308,
            "BPCNA": 0.35384615384615387,
            "BPCPS": 0.3230769230769231,
            "GPCHM": 0.6307692307692307,
            "GPCHU": 0.38461538461538464,
            "GPCMA": 0.5384615384615384,
            "GPCNA": 0.6307692307692307,
            "GPCPS": 0.6461538461538462,
            "HM": 0.5851850789096126,
            "HU": 0.613764705882353,
            "MA": 0.5794932773109244,
            "NA": 0.5705918220946915,
            "PS": 0.581967787114846,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.35384615384615387,
            "BPCHU": 0.6,
            "BPCMA": 0.4307692307692308,
            "BPCNA": 0.35384615384615387,
            "BPCPS": 0.3230769230769231,
            "GPCHM": 0.6307692307692307,
            "GPCHU": 0.38461538461538464,
            "GPCMA": 0.5384615384615384,
            "GPCNA": 0.6307692307692307,
            "GPCPS": 0.6461538461538462,
            "HM": 0.5851850789096126,
            "HU": 0.613764705882353,
            "MA": 0.5794932773109244,
            "NA": 0.5705918220946915,
            "PS": 0.581967787114846,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.38461538461538464,
            "BPCHU": 0.6307692307692307,
            "BPCMA": 0.46153846153846156,
            "BPCNA": 0.38461538461538464,
            "BPCPS": 0.35384615384615387,
            "GPCHM": 0.6307692307692307,
            "GPCHU": 0.38461538461538464,
            "GPCMA": 0.5384615384615384,
            "GPCNA": 0.6307692307692307,
            "GPCPS": 0.6461538461538462,
            "HM": 0.5851850789096126,
            "HU": 0.613764705882353,
            "MA": 0.5794932773109244,
            "NA": 0.5705918220946915,
            "PS": 0.581967787114846,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.675264705882353,
            "HU": 0.7120588235294117,
            "MA": 0.7115294117647059,
            "NA": 0.7093823529411765,
            "PS": 0.7083823529411765,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.03076923076923077,
            "HM": 0.668235294117647,
            "HU": 0.6901470588235294,
            "MA": 0.6869117647058823,
            "NA": 0.6852794117647059,
            "PS": 0.6842058823529412,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.03076923076923077,
            "HM": 0.668235294117647,
            "HU": 0.6901470588235294,
            "MA": 0.6869117647058823,
            "NA": 0.6852794117647059,
            "PS": 0.6842058823529412,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.03076923076923077,
            "HM": 0.6760980392156862,
            "HU": 0.6916862745098039,
            "MA": 0.6712745098039216,
            "NA": 0.6323235294117647,
            "PS": 0.6842058823529412,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.03076923076923077,
            "HM": 0.6760980392156862,
            "HU": 0.6916862745098039,
            "MA": 0.6712745098039216,
            "NA": 0.6323235294117647,
            "PS": 0.6842058823529412,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.03076923076923077,
            "HM": 0.6760980392156862,
            "HU": 0.6916862745098039,
            "MA": 0.6712745098039216,
            "NA": 0.6323235294117647,
            "PS": 0.6842058823529412,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.046153846153846156,
            "HM": 0.6679117647058823,
            "HU": 0.6916862745098039,
            "MA": 0.6712745098039216,
            "NA": 0.6349117647058824,
            "PS": 0.6712156862745098,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.046153846153846156,
            "HM": 0.6679117647058823,
            "HU": 0.6916862745098039,
            "MA": 0.6712745098039216,
            "NA": 0.6349117647058824,
            "PS": 0.6712156862745098,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.046153846153846156,
            "HM": 0.6679117647058823,
            "HU": 0.6916862745098039,
            "MA": 0.6712745098039216,
            "NA": 0.6349117647058824,
            "PS": 0.6712156862745098,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.046153846153846156,
            "HM": 0.6679117647058823,
            "HU": 0.6916862745098039,
            "MA": 0.6712745098039216,
            "NA": 0.6349117647058824,
            "PS": 0.6712156862745098,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0.6951176470588235,
            "NA": 0.6841470588235294,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0.6951176470588235,
            "NA": 0.6841470588235294,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0.6951176470588235,
            "NA": 0.6841470588235294,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.015384615384615385,
            "HM": 0.6748235294117647,
            "HU": 0.7253823529411765,
            "MA": 0.6917941176470588,
            "NA": 0.6877941176470588,
            "PS": 0.6955294117647058,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.015384615384615385,
            "HM": 0.6748235294117647,
            "HU": 0.7253823529411765,
            "MA": 0.6917941176470588,
            "NA": 0.6877941176470588,
            "PS": 0.6955294117647058,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.03076923076923077,
            "HM": 0.6476617647058823,
            "HU": 0.6889411764705883,
            "MA": 0.667264705882353,
            "NA": 0.6635196078431372,
            "PS": 0.6587352941176471,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.03076923076923077,
            "HM": 0.6476617647058823,
            "HU": 0.6889411764705883,
            "MA": 0.667264705882353,
            "NA": 0.6635196078431372,
            "PS": 0.6587352941176471,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.06153846153846154,
            "HM": 0.6535367647058824,
            "HU": 0.6617529411764705,
            "MA": 0.6629294117647059,
            "NA": 0.6616764705882353,
            "PS": 0.6586838235294118,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.07692307692307693,
            "HM": 0.6551705882352941,
            "HU": 0.6677058823529411,
            "MA": 0.6645980392156863,
            "NA": 0.662607843137255,
            "PS": 0.6599882352941177,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.1076923076923077,
            "HM": 0.6595672268907563,
            "HU": 0.6732463235294117,
            "MA": 0.6690756302521008,
            "NA": 0.661,
            "PS": 0.6697983193277312,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.23076923076923078,
            "GPCHU": 0.24615384615384617,
            "GPCMA": 0.24615384615384617,
            "GPCNA": 0.26153846153846155,
            "GPCPS": 0.26153846153846155,
            "HM": 0.6184921568627451,
            "HU": 0.6582738970588236,
            "MA": 0.5983511029411764,
            "NA": 0.6215501730103806,
            "PS": 0.6328010380622837,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.13846153846153847,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.12307692307692308,
            "GPCHM": 0.24615384615384617,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.26153846153846155,
            "GPCNA": 0.27692307692307694,
            "GPCPS": 0.27692307692307694,
            "HM": 0.6195698529411765,
            "HU": 0.6569186851211073,
            "MA": 0.600159169550173,
            "NA": 0.618390522875817,
            "PS": 0.6330065359477124,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.15384615384615385,
            "BPCHU": 0.13846153846153847,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.12307692307692308,
            "BPCPS": 0.13846153846153847,
            "GPCHM": 0.24615384615384617,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.26153846153846155,
            "GPCNA": 0.27692307692307694,
            "GPCPS": 0.27692307692307694,
            "HM": 0.6195698529411765,
            "HU": 0.6569186851211073,
            "MA": 0.600159169550173,
            "NA": 0.618390522875817,
            "PS": 0.6330065359477124,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2,
            "BPCHU": 0.2,
            "BPCMA": 0.16923076923076924,
            "BPCNA": 0.16923076923076924,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.2923076923076923,
            "GPCHU": 0.2923076923076923,
            "GPCMA": 0.3076923076923077,
            "GPCNA": 0.3230769230769231,
            "GPCPS": 0.3230769230769231,
            "HM": 0.6087585139318885,
            "HU": 0.6515030959752321,
            "MA": 0.5962382352941176,
            "NA": 0.6058081232492998,
            "PS": 0.6196470588235294,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.27692307692307694,
            "BPCHU": 0.3384615384615385,
            "BPCMA": 0.24615384615384617,
            "BPCNA": 0.23076923076923078,
            "BPCPS": 0.26153846153846155,
            "GPCHM": 0.4307692307692308,
            "GPCHU": 0.38461538461538464,
            "GPCMA": 0.4461538461538462,
            "GPCNA": 0.47692307692307695,
            "GPCPS": 0.47692307692307695,
            "HM": 0.6181754201680673,
            "HU": 0.6569611764705883,
            "MA": 0.5964594320486816,
            "NA": 0.6039829222011385,
            "PS": 0.6226888045540797,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3076923076923077,
            "BPCHU": 0.4153846153846154,
            "BPCMA": 0.3076923076923077,
            "BPCNA": 0.2923076923076923,
            "BPCPS": 0.3076923076923077,
            "GPCHM": 0.47692307692307695,
            "GPCHU": 0.38461538461538464,
            "GPCMA": 0.46153846153846156,
            "GPCNA": 0.49230769230769234,
            "GPCPS": 0.5076923076923077,
            "HM": 0.6225161290322581,
            "HU": 0.6569611764705883,
            "MA": 0.5989303921568628,
            "NA": 0.6041534926470589,
            "PS": 0.6236666666666667,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.35384615384615387,
            "BPCHU": 0.4307692307692308,
            "BPCMA": 0.3384615384615385,
            "BPCNA": 0.3230769230769231,
            "BPCPS": 0.3230769230769231,
            "GPCHM": 0.47692307692307695,
            "GPCHU": 0.4153846153846154,
            "GPCMA": 0.47692307692307695,
            "GPCNA": 0.5076923076923077,
            "GPCPS": 0.5384615384615384,
            "HM": 0.6225161290322581,
            "HU": 0.6525032679738562,
            "MA": 0.5943605313092979,
            "NA": 0.5987798573975045,
            "PS": 0.6130134453781513,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.38461538461538464,
            "BPCHU": 0.49230769230769234,
            "BPCMA": 0.38461538461538464,
            "BPCNA": 0.35384615384615387,
            "BPCPS": 0.36923076923076925,
            "GPCHM": 0.5076923076923077,
            "GPCHU": 0.4153846153846154,
            "GPCMA": 0.49230769230769234,
            "GPCNA": 0.5384615384615384,
            "GPCPS": 0.5692307692307692,
            "HM": 0.6261764705882353,
            "HU": 0.6525032679738562,
            "MA": 0.5969669117647058,
            "NA": 0.6040084033613445,
            "PS": 0.617016693163752,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.38461538461538464,
            "BPCHU": 0.5076923076923077,
            "BPCMA": 0.38461538461538464,
            "BPCNA": 0.35384615384615387,
            "BPCPS": 0.36923076923076925,
            "GPCHM": 0.5692307692307692,
            "GPCHU": 0.46153846153846156,
            "GPCMA": 0.5538461538461539,
            "GPCNA": 0.6,
            "GPCPS": 0.6307692307692307,
            "HM": 0.6279976152623212,
            "HU": 0.6523088235294118,
            "MA": 0.6021772875816993,
            "NA": 0.6082194570135747,
            "PS": 0.6200157819225252,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.4,
            "BPCHU": 0.5230769230769231,
            "BPCMA": 0.4,
            "BPCNA": 0.36923076923076925,
            "BPCPS": 0.38461538461538464,
            "GPCHM": 0.5846153846153846,
            "GPCHU": 0.47692307692307695,
            "GPCMA": 0.5692307692307692,
            "GPCNA": 0.6153846153846154,
            "GPCPS": 0.6461538461538462,
            "HM": 0.6265023219814241,
            "HU": 0.650573055028463,
            "MA": 0.6020151033386327,
            "NA": 0.6077727941176471,
            "PS": 0.619343137254902,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.4,
            "BPCHU": 0.5230769230769231,
            "BPCMA": 0.4,
            "BPCNA": 0.36923076923076925,
            "BPCPS": 0.38461538461538464,
            "GPCHM": 0.5846153846153846,
            "GPCHU": 0.47692307692307695,
            "GPCMA": 0.5692307692307692,
            "GPCNA": 0.6153846153846154,
            "GPCPS": 0.6461538461538462,
            "HM": 0.6265023219814241,
            "HU": 0.650573055028463,
            "MA": 0.6020151033386327,
            "NA": 0.6077727941176471,
            "PS": 0.619343137254902,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.4,
            "BPCHU": 0.5384615384615384,
            "BPCMA": 0.4,
            "BPCNA": 0.36923076923076925,
            "BPCPS": 0.38461538461538464,
            "GPCHM": 0.6153846153846154,
            "GPCHU": 0.49230769230769234,
            "GPCMA": 0.6,
            "GPCNA": 0.6461538461538462,
            "GPCPS": 0.676923076923077,
            "HM": 0.6282977941176471,
            "HU": 0.6507417279411765,
            "MA": 0.6044781297134239,
            "NA": 0.61040756302521,
            "PS": 0.6214311497326204,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.4,
            "BPCHU": 0.5384615384615384,
            "BPCMA": 0.4,
            "BPCNA": 0.36923076923076925,
            "BPCPS": 0.38461538461538464,
            "GPCHM": 0.6153846153846154,
            "GPCHU": 0.49230769230769234,
            "GPCMA": 0.6,
            "GPCNA": 0.6461538461538462,
            "GPCPS": 0.676923076923077,
            "HM": 0.6282977941176471,
            "HU": 0.6507417279411765,
            "MA": 0.6044781297134239,
            "NA": 0.61040756302521,
            "PS": 0.6214311497326204,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0,
            "GPCNA": 0,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0,
            "NA": 0,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.7222941176470589,
            "HU": 0,
            "MA": 0.726,
            "NA": 0.7541764705882353,
            "PS": 0.7175882352941176,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.03076923076923077,
            "HM": 0.6574852941176471,
            "HU": 0.6119411764705882,
            "MA": 0.6663088235294118,
            "NA": 0.6734558823529412,
            "PS": 0.656,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.03076923076923077,
            "HM": 0.6574852941176471,
            "HU": 0.6119411764705882,
            "MA": 0.6663088235294118,
            "NA": 0.6734558823529412,
            "PS": 0.656,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.03076923076923077,
            "HM": 0.6574852941176471,
            "HU": 0.6119411764705882,
            "MA": 0.6663088235294118,
            "NA": 0.6734558823529412,
            "PS": 0.656,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.046153846153846156,
            "HM": 0.6800196078431373,
            "HU": 0.6119411764705882,
            "MA": 0.6819607843137255,
            "NA": 0.6906274509803921,
            "PS": 0.6777941176470588,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.046153846153846156,
            "HM": 0.6242794117647059,
            "HU": 0.6479264705882353,
            "MA": 0.6819607843137255,
            "NA": 0.6284852941176471,
            "PS": 0.6777941176470588,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.06153846153846154,
            "HM": 0.6445529411764706,
            "HU": 0.6745,
            "MA": 0.6926029411764706,
            "NA": 0.6479411764705882,
            "PS": 0.6898308823529412,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.06153846153846154,
            "HM": 0.6445529411764706,
            "HU": 0.6745,
            "MA": 0.6926029411764706,
            "NA": 0.6479411764705882,
            "PS": 0.6898308823529412,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.06153846153846154,
            "HM": 0.6445529411764706,
            "HU": 0.6745,
            "MA": 0.6926029411764706,
            "NA": 0.6479411764705882,
            "PS": 0.6898308823529412,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.09230769230769231,
            "HM": 0.6451974789915966,
            "HU": 0.6994470588235294,
            "MA": 0.6630049019607843,
            "NA": 0.6474201680672269,
            "PS": 0.6765686274509803,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.1076923076923077,
            "HM": 0.6420882352941176,
            "HU": 0.6919362745098039,
            "MA": 0.6560588235294118,
            "NA": 0.6441323529411764,
            "PS": 0.6686386554621849,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.13846153846153847,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.15384615384615385,
            "HM": 0.645264705882353,
            "HU": 0.6772536764705882,
            "MA": 0.6387058823529411,
            "NA": 0.6389588235294117,
            "PS": 0.6646735294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.18461538461538463,
            "BPCHU": 0.2,
            "BPCMA": 0.18461538461538463,
            "BPCNA": 0.16923076923076924,
            "BPCPS": 0.15384615384615385,
            "GPCHM": 0.2,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.2153846153846154,
            "GPCPS": 0.2153846153846154,
            "HM": 0.6458144796380091,
            "HU": 0.6680073529411765,
            "MA": 0.6471862745098039,
            "NA": 0.6444789915966387,
            "PS": 0.6618235294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.26153846153846155,
            "BPCHU": 0.3076923076923077,
            "BPCMA": 0.26153846153846155,
            "BPCNA": 0.24615384615384617,
            "BPCPS": 0.23076923076923078,
            "GPCHM": 0.35384615384615387,
            "GPCHU": 0.3076923076923077,
            "GPCMA": 0.3384615384615385,
            "GPCNA": 0.36923076923076925,
            "GPCPS": 0.36923076923076925,
            "HM": 0.647074168797954,
            "HU": 0.6631823529411764,
            "MA": 0.6304558823529411,
            "NA": 0.6366495098039215,
            "PS": 0.6471764705882352,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3230769230769231,
            "BPCHU": 0.38461538461538464,
            "BPCMA": 0.35384615384615387,
            "BPCNA": 0.3076923076923077,
            "BPCPS": 0.2923076923076923,
            "GPCHM": 0.47692307692307695,
            "GPCHU": 0.4153846153846154,
            "GPCMA": 0.4307692307692308,
            "GPCNA": 0.49230769230769234,
            "GPCPS": 0.49230769230769234,
            "HM": 0.627219165085389,
            "HU": 0.6496492374727668,
            "MA": 0.617577731092437,
            "NA": 0.6187766544117647,
            "PS": 0.6261323529411764,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.4153846153846154,
            "BPCHU": 0.4307692307692308,
            "BPCMA": 0.4461538461538462,
            "BPCNA": 0.4,
            "BPCPS": 0.35384615384615387,
            "GPCHM": 0.47692307692307695,
            "GPCHU": 0.46153846153846156,
            "GPCMA": 0.4307692307692308,
            "GPCNA": 0.49230769230769234,
            "GPCPS": 0.5230769230769231,
            "HM": 0.627219165085389,
            "HU": 0.638314705882353,
            "MA": 0.617577731092437,
            "NA": 0.6187766544117647,
            "PS": 0.620772491349481,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.4153846153846154,
            "BPCHU": 0.4307692307692308,
            "BPCMA": 0.4461538461538462,
            "BPCNA": 0.4,
            "BPCPS": 0.35384615384615387,
            "GPCHM": 0.47692307692307695,
            "GPCHU": 0.46153846153846156,
            "GPCMA": 0.4307692307692308,
            "GPCNA": 0.49230769230769234,
            "GPCPS": 0.5230769230769231,
            "HM": 0.627219165085389,
            "HU": 0.638314705882353,
            "MA": 0.617577731092437,
            "NA": 0.6187766544117647,
            "PS": 0.620772491349481,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.4153846153846154,
            "BPCHU": 0.4307692307692308,
            "BPCMA": 0.4461538461538462,
            "BPCNA": 0.4,
            "BPCPS": 0.35384615384615387,
            "GPCHM": 0.47692307692307695,
            "GPCHU": 0.46153846153846156,
            "GPCMA": 0.4307692307692308,
            "GPCNA": 0.49230769230769234,
            "GPCPS": 0.5230769230769231,
            "HM": 0.627219165085389,
            "HU": 0.638314705882353,
            "MA": 0.617577731092437,
            "NA": 0.6187766544117647,
            "PS": 0.620772491349481,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.4307692307692308,
            "BPCHU": 0.46153846153846156,
            "BPCMA": 0.46153846153846156,
            "BPCNA": 0.4153846153846154,
            "BPCPS": 0.36923076923076925,
            "GPCHM": 0.49230769230769234,
            "GPCHU": 0.46153846153846156,
            "GPCMA": 0.4461538461538462,
            "GPCNA": 0.5076923076923077,
            "GPCPS": 0.5384615384615384,
            "HM": 0.6303998161764706,
            "HU": 0.638314705882353,
            "MA": 0.622183569979716,
            "NA": 0.6219661319073083,
            "PS": 0.6235151260504201,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.4307692307692308,
            "BPCHU": 0.46153846153846156,
            "BPCMA": 0.46153846153846156,
            "BPCNA": 0.4153846153846154,
            "BPCPS": 0.36923076923076925,
            "GPCHM": 0.49230769230769234,
            "GPCHU": 0.46153846153846156,
            "GPCMA": 0.4461538461538462,
            "GPCNA": 0.5076923076923077,
            "GPCPS": 0.5384615384615384,
            "HM": 0.6303998161764706,
            "HU": 0.638314705882353,
            "MA": 0.622183569979716,
            "NA": 0.6219661319073083,
            "PS": 0.6235151260504201,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.4307692307692308,
            "BPCHU": 0.47692307692307695,
            "BPCMA": 0.46153846153846156,
            "BPCNA": 0.4153846153846154,
            "BPCPS": 0.36923076923076925,
            "GPCHM": 0.5076923076923077,
            "GPCHU": 0.46153846153846156,
            "GPCMA": 0.46153846153846156,
            "GPCNA": 0.5230769230769231,
            "GPCPS": 0.5538461538461539,
            "HM": 0.6303217468805704,
            "HU": 0.638314705882353,
            "MA": 0.6211705882352941,
            "NA": 0.6219368512110727,
            "PS": 0.6236879084967321,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.4307692307692308,
            "BPCHU": 0.47692307692307695,
            "BPCMA": 0.46153846153846156,
            "BPCNA": 0.4153846153846154,
            "BPCPS": 0.36923076923076925,
            "GPCHM": 0.5076923076923077,
            "GPCHU": 0.46153846153846156,
            "GPCMA": 0.46153846153846156,
            "GPCNA": 0.5230769230769231,
            "GPCPS": 0.5538461538461539,
            "HM": 0.6303217468805704,
            "HU": 0.638314705882353,
            "MA": 0.6211705882352941,
            "NA": 0.6219368512110727,
            "PS": 0.6236879084967321,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.07692307692307693,
            "HM": 0.6264803921568627,
            "HU": 0.6916588235294118,
            "MA": 0.6722823529411764,
            "NA": 0.632064705882353,
            "PS": 0.6888117647058823,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.07692307692307693,
            "HM": 0.6264803921568627,
            "HU": 0.6916588235294118,
            "MA": 0.6722823529411764,
            "NA": 0.632064705882353,
            "PS": 0.6888117647058823,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.12307692307692308,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.09230769230769231,
            "HM": 0.6325196078431373,
            "HU": 0.6882058823529412,
            "MA": 0.6463897058823529,
            "NA": 0.6299323529411764,
            "PS": 0.7017450980392157,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.13846153846153847,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.15384615384615385,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.2,
            "GPCPS": 0.1076923076923077,
            "HM": 0.6018210784313726,
            "HU": 0.6778676470588235,
            "MA": 0.6197272727272727,
            "NA": 0.6088461538461538,
            "PS": 0.6815588235294118,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.15384615384615385,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.2,
            "GPCHU": 0.13846153846153847,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.2153846153846154,
            "GPCPS": 0.13846153846153847,
            "HM": 0.5943981900452489,
            "HU": 0.6713398692810457,
            "MA": 0.6076862745098038,
            "NA": 0.6014390756302521,
            "PS": 0.6602614379084968,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.15384615384615385,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.2,
            "GPCHM": 0.2,
            "GPCHU": 0.13846153846153847,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.2153846153846154,
            "GPCPS": 0.13846153846153847,
            "HM": 0.5943981900452489,
            "HU": 0.6713398692810457,
            "MA": 0.6076862745098038,
            "NA": 0.6014390756302521,
            "PS": 0.6602614379084968,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.16923076923076924,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.2153846153846154,
            "GPCHM": 0.23076923076923078,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.2,
            "GPCNA": 0.24615384615384617,
            "GPCPS": 0.16923076923076924,
            "HM": 0.5974078431372548,
            "HU": 0.6798588235294117,
            "MA": 0.6108552036199094,
            "NA": 0.5973143382352941,
            "PS": 0.6584304812834225,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.16923076923076924,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.2153846153846154,
            "GPCHM": 0.23076923076923078,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.2,
            "GPCNA": 0.24615384615384617,
            "GPCPS": 0.16923076923076924,
            "HM": 0.5974078431372548,
            "HU": 0.6798588235294117,
            "MA": 0.6108552036199094,
            "NA": 0.5973143382352941,
            "PS": 0.6584304812834225,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.18461538461538463,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.23076923076923078,
            "GPCHM": 0.24615384615384617,
            "GPCHU": 0.16923076923076924,
            "GPCMA": 0.2153846153846154,
            "GPCNA": 0.26153846153846155,
            "GPCPS": 0.18461538461538463,
            "HM": 0.594764705882353,
            "HU": 0.6692299465240641,
            "MA": 0.60775,
            "NA": 0.5916332179930796,
            "PS": 0.6518088235294117,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.2,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.23076923076923078,
            "GPCHM": 0.26153846153846155,
            "GPCHU": 0.16923076923076924,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.27692307692307694,
            "GPCPS": 0.2,
            "HM": 0.5994031141868512,
            "HU": 0.6692299465240641,
            "MA": 0.6062666666666666,
            "NA": 0.596687908496732,
            "PS": 0.6544457013574662,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.2,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.23076923076923078,
            "GPCHM": 0.26153846153846155,
            "GPCHU": 0.16923076923076924,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.27692307692307694,
            "GPCPS": 0.2,
            "HM": 0.5994031141868512,
            "HU": 0.6692299465240641,
            "MA": 0.6062666666666666,
            "NA": 0.596687908496732,
            "PS": 0.6544457013574662,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.24615384615384617,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.27692307692307694,
            "GPCHM": 0.3384615384615385,
            "GPCHU": 0.23076923076923078,
            "GPCMA": 0.2923076923076923,
            "GPCNA": 0.35384615384615387,
            "GPCPS": 0.27692307692307694,
            "HM": 0.6107032085561498,
            "HU": 0.6626156862745098,
            "MA": 0.6171811145510836,
            "NA": 0.6064846547314577,
            "PS": 0.653142156862745,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.16923076923076924,
            "BPCHU": 0.2923076923076923,
            "BPCMA": 0.18461538461538463,
            "BPCNA": 0.13846153846153847,
            "BPCPS": 0.3076923076923077,
            "GPCHM": 0.4153846153846154,
            "GPCHU": 0.3076923076923077,
            "GPCMA": 0.36923076923076925,
            "GPCNA": 0.4307692307692308,
            "GPCPS": 0.36923076923076925,
            "HM": 0.611599128540305,
            "HU": 0.6492823529411764,
            "MA": 0.6189166666666667,
            "NA": 0.6066764705882353,
            "PS": 0.6419730392156863,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.18461538461538463,
            "BPCHU": 0.2923076923076923,
            "BPCMA": 0.2,
            "BPCNA": 0.16923076923076924,
            "BPCPS": 0.38461538461538464,
            "GPCHM": 0.4153846153846154,
            "GPCHU": 0.3230769230769231,
            "GPCMA": 0.36923076923076925,
            "GPCNA": 0.4153846153846154,
            "GPCPS": 0.3076923076923077,
            "HM": 0.6042570806100218,
            "HU": 0.6443949579831934,
            "MA": 0.612094362745098,
            "NA": 0.6059891067538127,
            "PS": 0.6033470588235293,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.18461538461538463,
            "BPCHU": 0.3076923076923077,
            "BPCMA": 0.2,
            "BPCNA": 0.16923076923076924,
            "BPCPS": 0.4,
            "GPCHM": 0.49230769230769234,
            "GPCHU": 0.38461538461538464,
            "GPCMA": 0.4461538461538462,
            "GPCNA": 0.49230769230769234,
            "GPCPS": 0.36923076923076925,
            "HM": 0.6054944852941176,
            "HU": 0.6450117647058824,
            "MA": 0.616683569979716,
            "NA": 0.6041957720588236,
            "PS": 0.6053860294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.26153846153846155,
            "BPCHU": 0.38461538461538464,
            "BPCMA": 0.27692307692307694,
            "BPCNA": 0.24615384615384617,
            "BPCPS": 0.46153846153846156,
            "GPCHM": 0.5846153846153846,
            "GPCHU": 0.49230769230769234,
            "GPCMA": 0.5384615384615384,
            "GPCNA": 0.5846153846153846,
            "GPCPS": 0.49230769230769234,
            "HM": 0.5972755417956656,
            "HU": 0.6396516544117647,
            "MA": 0.6078201680672269,
            "NA": 0.5961362229102167,
            "PS": 0.5986121323529412,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.26153846153846155,
            "BPCHU": 0.4,
            "BPCMA": 0.27692307692307694,
            "BPCNA": 0.24615384615384617,
            "BPCPS": 0.46153846153846156,
            "GPCHM": 0.6,
            "GPCHU": 0.49230769230769234,
            "GPCMA": 0.5538461538461539,
            "GPCNA": 0.6,
            "GPCPS": 0.5076923076923077,
            "HM": 0.6001696832579185,
            "HU": 0.6396516544117647,
            "MA": 0.6106707516339869,
            "NA": 0.5992503770739065,
            "PS": 0.6025668449197861,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.27692307692307694,
            "BPCHU": 0.4307692307692308,
            "BPCMA": 0.3076923076923077,
            "BPCNA": 0.27692307692307694,
            "BPCPS": 0.47692307692307695,
            "GPCHM": 0.6461538461538462,
            "GPCHU": 0.5230769230769231,
            "GPCMA": 0.5846153846153846,
            "GPCNA": 0.6307692307692307,
            "GPCPS": 0.5538461538461539,
            "HM": 0.6068354341736695,
            "HU": 0.6432794117647059,
            "MA": 0.6102136222910217,
            "NA": 0.6021714490674319,
            "PS": 0.6111184640522875,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2923076923076923,
            "BPCHU": 0.4461538461538462,
            "BPCMA": 0.3230769230769231,
            "BPCNA": 0.2923076923076923,
            "BPCPS": 0.49230769230769234,
            "GPCHM": 0.6461538461538462,
            "GPCHU": 0.5230769230769231,
            "GPCMA": 0.5846153846153846,
            "GPCNA": 0.6307692307692307,
            "GPCPS": 0.5538461538461539,
            "HM": 0.6068354341736695,
            "HU": 0.6432794117647059,
            "MA": 0.6102136222910217,
            "NA": 0.6021714490674319,
            "PS": 0.6111184640522875,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3076923076923077,
            "BPCHU": 0.46153846153846156,
            "BPCMA": 0.3230769230769231,
            "BPCNA": 0.3076923076923077,
            "BPCPS": 0.49230769230769234,
            "GPCHM": 0.6461538461538462,
            "GPCHU": 0.5230769230769231,
            "GPCMA": 0.6,
            "GPCNA": 0.6307692307692307,
            "GPCPS": 0.5692307692307692,
            "HM": 0.6068354341736695,
            "HU": 0.6432794117647059,
            "MA": 0.6144683257918553,
            "NA": 0.6021714490674319,
            "PS": 0.6152503974562799,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3076923076923077,
            "BPCHU": 0.46153846153846156,
            "BPCMA": 0.3230769230769231,
            "BPCNA": 0.3076923076923077,
            "BPCPS": 0.49230769230769234,
            "GPCHM": 0.6461538461538462,
            "GPCHU": 0.5230769230769231,
            "GPCMA": 0.6,
            "GPCNA": 0.6307692307692307,
            "GPCPS": 0.5692307692307692,
            "HM": 0.6068354341736695,
            "HU": 0.6432794117647059,
            "MA": 0.6144683257918553,
            "NA": 0.6021714490674319,
            "PS": 0.6152503974562799,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3076923076923077,
            "BPCHU": 0.46153846153846156,
            "BPCMA": 0.3230769230769231,
            "BPCNA": 0.3076923076923077,
            "BPCPS": 0.5076923076923077,
            "GPCHM": 0.6615384615384615,
            "GPCHU": 0.5538461538461539,
            "GPCMA": 0.6153846153846154,
            "GPCNA": 0.6461538461538462,
            "GPCPS": 0.5846153846153846,
            "HM": 0.6049090287277702,
            "HU": 0.6430514705882353,
            "MA": 0.6129580882352942,
            "NA": 0.6006792717086834,
            "PS": 0.6129698142414861,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3076923076923077,
            "BPCHU": 0.46153846153846156,
            "BPCMA": 0.3230769230769231,
            "BPCNA": 0.3076923076923077,
            "BPCPS": 0.5076923076923077,
            "GPCHM": 0.7076923076923077,
            "GPCHU": 0.6,
            "GPCMA": 0.6615384615384615,
            "GPCNA": 0.6923076923076923,
            "GPCPS": 0.6307692307692307,
            "HM": 0.608037084398977,
            "HU": 0.6449924585218703,
            "MA": 0.6158324213406293,
            "NA": 0.6034065359477124,
            "PS": 0.6145975609756098,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0,
            "GPCNA": 0,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0,
            "NA": 0,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0,
            "GPCNA": 0,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0,
            "NA": 0,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.6912647058823529,
            "HU": 0,
            "MA": 0.6486764705882353,
            "NA": 0.6938823529411765,
            "PS": 0.6983823529411765,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.6912647058823529,
            "HU": 0,
            "MA": 0.6486764705882353,
            "NA": 0.6938823529411765,
            "PS": 0.6983823529411765,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.6912647058823529,
            "HU": 0,
            "MA": 0.6486764705882353,
            "NA": 0.6938823529411765,
            "PS": 0.6983823529411765,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.03076923076923077,
            "HM": 0.5638382352941177,
            "HU": 0.5137647058823529,
            "MA": 0.6486764705882353,
            "NA": 0.6938823529411765,
            "PS": 0.5688382352941177,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.03076923076923077,
            "HM": 0.5638382352941177,
            "HU": 0.5137647058823529,
            "MA": 0.6486764705882353,
            "NA": 0.6938823529411765,
            "PS": 0.5688382352941177,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.03076923076923077,
            "HM": 0.5638382352941177,
            "HU": 0.5137647058823529,
            "MA": 0.6486764705882353,
            "NA": 0.6938823529411765,
            "PS": 0.5688382352941177,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.03076923076923077,
            "HM": 0.5638382352941177,
            "HU": 0.5137647058823529,
            "MA": 0.6486764705882353,
            "NA": 0.6938823529411765,
            "PS": 0.5688382352941177,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.03076923076923077,
            "HM": 0.5638382352941177,
            "HU": 0.5137647058823529,
            "MA": 0.6486764705882353,
            "NA": 0.6938823529411765,
            "PS": 0.5688382352941177,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.06153846153846154,
            "HM": 0.6208676470588236,
            "HU": 0.6663333333333333,
            "MA": 0.7020196078431373,
            "NA": 0.663,
            "PS": 0.6359485294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.09230769230769231,
            "HM": 0.5779509803921569,
            "HU": 0.5993352941176471,
            "MA": 0.6173705882352941,
            "NA": 0.6282573529411765,
            "PS": 0.5947450980392156,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.09230769230769231,
            "HM": 0.5779509803921569,
            "HU": 0.5993352941176471,
            "MA": 0.6173705882352941,
            "NA": 0.6282573529411765,
            "PS": 0.5947450980392156,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.09230769230769231,
            "HM": 0.5779509803921569,
            "HU": 0.5993352941176471,
            "MA": 0.6173705882352941,
            "NA": 0.6282573529411765,
            "PS": 0.5947450980392156,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.15384615384615385,
            "HM": 0.62659477124183,
            "HU": 0.6504411764705882,
            "MA": 0.6379044117647059,
            "NA": 0.6417352941176471,
            "PS": 0.6191264705882352,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.2,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.12307692307692308,
            "BPCPS": 0.12307692307692308,
            "GPCHM": 0.2153846153846154,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.2153846153846154,
            "GPCPS": 0.24615384615384617,
            "HM": 0.6242542016806724,
            "HU": 0.6539882352941176,
            "MA": 0.6369058823529412,
            "NA": 0.650327731092437,
            "PS": 0.6172849264705882,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.13846153846153847,
            "BPCHU": 0.26153846153846155,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.13846153846153847,
            "BPCPS": 0.12307692307692308,
            "GPCHM": 0.2923076923076923,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.3076923076923077,
            "GPCNA": 0.2923076923076923,
            "GPCPS": 0.3384615384615385,
            "HM": 0.6211764705882353,
            "HU": 0.651875,
            "MA": 0.6383838235294117,
            "NA": 0.6404086687306502,
            "PS": 0.6309197860962567,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.15384615384615385,
            "BPCHU": 0.27692307692307694,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.13846153846153847,
            "GPCHM": 0.3076923076923077,
            "GPCHU": 0.2,
            "GPCMA": 0.3230769230769231,
            "GPCNA": 0.3076923076923077,
            "GPCPS": 0.35384615384615387,
            "HM": 0.6243823529411765,
            "HU": 0.6610294117647059,
            "MA": 0.6399313725490197,
            "NA": 0.6426926470588236,
            "PS": 0.633653452685422,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.24615384615384617,
            "BPCHU": 0.4153846153846154,
            "BPCMA": 0.2153846153846154,
            "BPCNA": 0.2153846153846154,
            "BPCPS": 0.24615384615384617,
            "GPCHM": 0.35384615384615387,
            "GPCHU": 0.2153846153846154,
            "GPCMA": 0.38461538461538464,
            "GPCNA": 0.38461538461538464,
            "GPCPS": 0.4,
            "HM": 0.6331227621483376,
            "HU": 0.6663781512605041,
            "MA": 0.6578964705882353,
            "NA": 0.6522388235294118,
            "PS": 0.6438472850678733,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.26153846153846155,
            "BPCHU": 0.4307692307692308,
            "BPCMA": 0.24615384615384617,
            "BPCNA": 0.23076923076923078,
            "BPCPS": 0.24615384615384617,
            "GPCHM": 0.38461538461538464,
            "GPCHU": 0.24615384615384617,
            "GPCMA": 0.4,
            "GPCNA": 0.4153846153846154,
            "GPCPS": 0.4461538461538462,
            "HM": 0.6239729411764706,
            "HU": 0.6642941176470588,
            "MA": 0.655131221719457,
            "NA": 0.6424989106753812,
            "PS": 0.6386551724137931,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.26153846153846155,
            "BPCHU": 0.4461538461538462,
            "BPCMA": 0.26153846153846155,
            "BPCNA": 0.23076923076923078,
            "BPCPS": 0.26153846153846155,
            "GPCHM": 0.4153846153846154,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.4153846153846154,
            "GPCNA": 0.4461538461538462,
            "GPCPS": 0.46153846153846156,
            "HM": 0.631942265795207,
            "HU": 0.6653737024221453,
            "MA": 0.6555533769063181,
            "NA": 0.6486632860040568,
            "PS": 0.639521568627451,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2923076923076923,
            "BPCHU": 0.47692307692307695,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.26153846153846155,
            "BPCPS": 0.2923076923076923,
            "GPCHM": 0.4153846153846154,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.4153846153846154,
            "GPCNA": 0.4461538461538462,
            "GPCPS": 0.46153846153846156,
            "HM": 0.631942265795207,
            "HU": 0.6653737024221453,
            "MA": 0.6555533769063181,
            "NA": 0.6486632860040568,
            "PS": 0.639521568627451,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2923076923076923,
            "BPCHU": 0.47692307692307695,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.26153846153846155,
            "BPCPS": 0.3076923076923077,
            "GPCHM": 0.4153846153846154,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.4153846153846154,
            "GPCNA": 0.4461538461538462,
            "GPCPS": 0.46153846153846156,
            "HM": 0.631942265795207,
            "HU": 0.6653737024221453,
            "MA": 0.6555533769063181,
            "NA": 0.6486632860040568,
            "PS": 0.639521568627451,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.046153846153846156,
            "HM": 0.5982156862745097,
            "HU": 0.638,
            "MA": 0.5762745098039216,
            "NA": 0.5804607843137255,
            "PS": 0.594735294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.06153846153846154,
            "HM": 0.6485882352941177,
            "HU": 0.638,
            "MA": 0.6349191176470588,
            "NA": 0.6346691176470588,
            "PS": 0.6475294117647059,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.06153846153846154,
            "HM": 0.6485882352941177,
            "HU": 0.638,
            "MA": 0.6349191176470588,
            "NA": 0.6346691176470588,
            "PS": 0.6475294117647059,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.06153846153846154,
            "HM": 0.6485882352941177,
            "HU": 0.638,
            "MA": 0.6349191176470588,
            "NA": 0.6346691176470588,
            "PS": 0.6475294117647059,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.09230769230769231,
            "HM": 0.6838480392156863,
            "HU": 0.7419803921568627,
            "MA": 0.6824362745098039,
            "NA": 0.6766666666666667,
            "PS": 0.6809362745098039,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.1076923076923077,
            "HM": 0.6649915966386555,
            "HU": 0.7060367647058824,
            "MA": 0.6699621848739495,
            "NA": 0.6631806722689076,
            "PS": 0.6722689075630253,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.12307692307692308,
            "HM": 0.6585441176470588,
            "HU": 0.7060367647058824,
            "MA": 0.6547647058823529,
            "NA": 0.6563970588235294,
            "PS": 0.6719227941176471,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.16923076923076924,
            "HM": 0.6439019607843137,
            "HU": 0.6988319327731092,
            "MA": 0.673355614973262,
            "NA": 0.6353235294117647,
            "PS": 0.657692513368984,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.16923076923076924,
            "HM": 0.6439019607843137,
            "HU": 0.6988319327731092,
            "MA": 0.673355614973262,
            "NA": 0.6353235294117647,
            "PS": 0.657692513368984,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.2,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.2,
            "GPCPS": 0.2,
            "HM": 0.635210407239819,
            "HU": 0.6496264705882353,
            "MA": 0.6676372549019608,
            "NA": 0.6263190045248869,
            "PS": 0.6512692307692308,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.2,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.2,
            "GPCPS": 0.2,
            "HM": 0.635210407239819,
            "HU": 0.6496264705882353,
            "MA": 0.6676372549019608,
            "NA": 0.6263190045248869,
            "PS": 0.6512692307692308,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.24615384615384617,
            "GPCHU": 0.2,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.24615384615384617,
            "GPCPS": 0.24615384615384617,
            "HM": 0.6326783088235294,
            "HU": 0.6783914027149321,
            "MA": 0.6701411764705882,
            "NA": 0.6230202205882353,
            "PS": 0.6428069852941176,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.26153846153846155,
            "GPCHU": 0.2153846153846154,
            "GPCMA": 0.24615384615384617,
            "GPCNA": 0.26153846153846155,
            "GPCPS": 0.26153846153846155,
            "HM": 0.6423442906574395,
            "HU": 0.6870546218487394,
            "MA": 0.6777316176470588,
            "NA": 0.633136678200692,
            "PS": 0.6518512110726643,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.12307692307692308,
            "GPCHM": 0.2923076923076923,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.27692307692307694,
            "GPCNA": 0.2923076923076923,
            "GPCPS": 0.2923076923076923,
            "HM": 0.651250773993808,
            "HU": 0.6821245674740485,
            "MA": 0.6833986928104575,
            "NA": 0.6429396284829721,
            "PS": 0.6594613003095976,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.13846153846153847,
            "BPCHU": 0.18461538461538463,
            "BPCMA": 0.15384615384615385,
            "BPCNA": 0.16923076923076924,
            "BPCPS": 0.2153846153846154,
            "GPCHM": 0.4,
            "GPCHU": 0.36923076923076925,
            "GPCMA": 0.38461538461538464,
            "GPCNA": 0.38461538461538464,
            "GPCPS": 0.38461538461538464,
            "HM": 0.6631244343891403,
            "HU": 0.6951727941176471,
            "MA": 0.6840270588235293,
            "NA": 0.6612564705882353,
            "PS": 0.6530176470588235,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2,
            "BPCHU": 0.2153846153846154,
            "BPCMA": 0.2153846153846154,
            "BPCNA": 0.24615384615384617,
            "BPCPS": 0.27692307692307694,
            "GPCHM": 0.5076923076923077,
            "GPCHU": 0.5076923076923077,
            "GPCMA": 0.49230769230769234,
            "GPCNA": 0.47692307692307695,
            "GPCPS": 0.49230769230769234,
            "HM": 0.6572852049910873,
            "HU": 0.6878351158645276,
            "MA": 0.6692748161764706,
            "NA": 0.6487685009487666,
            "PS": 0.6459788602941177,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2,
            "BPCHU": 0.23076923076923078,
            "BPCMA": 0.2153846153846154,
            "BPCNA": 0.24615384615384617,
            "BPCPS": 0.27692307692307694,
            "GPCHM": 0.5384615384615384,
            "GPCHU": 0.5230769230769231,
            "GPCMA": 0.5230769230769231,
            "GPCNA": 0.5076923076923077,
            "GPCPS": 0.5230769230769231,
            "HM": 0.6551058823529411,
            "HU": 0.6849558823529411,
            "MA": 0.6670570934256055,
            "NA": 0.6452522281639929,
            "PS": 0.6460173010380623,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.27692307692307694,
            "BPCHU": 0.3076923076923077,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.3230769230769231,
            "BPCPS": 0.35384615384615387,
            "GPCHM": 0.5692307692307692,
            "GPCHU": 0.5538461538461539,
            "GPCMA": 0.5538461538461539,
            "GPCNA": 0.5384615384615384,
            "GPCPS": 0.5538461538461539,
            "HM": 0.6527138314785373,
            "HU": 0.6862295751633987,
            "MA": 0.6602916666666667,
            "NA": 0.6407983193277311,
            "PS": 0.6445498366013072,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3230769230769231,
            "BPCHU": 0.38461538461538464,
            "BPCMA": 0.4,
            "BPCNA": 0.4153846153846154,
            "BPCPS": 0.4153846153846154,
            "GPCHM": 0.7076923076923077,
            "GPCHU": 0.6615384615384615,
            "GPCMA": 0.6307692307692307,
            "GPCNA": 0.6307692307692307,
            "GPCPS": 0.676923076923077,
            "HM": 0.6403874680306906,
            "HU": 0.6788782489740082,
            "MA": 0.654422525107604,
            "NA": 0.6419835007173602,
            "PS": 0.6424024064171123,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3384615384615385,
            "BPCHU": 0.4,
            "BPCMA": 0.4,
            "BPCNA": 0.4153846153846154,
            "BPCPS": 0.4153846153846154,
            "GPCHM": 0.7384615384615385,
            "GPCHU": 0.6923076923076923,
            "GPCMA": 0.676923076923077,
            "GPCNA": 0.676923076923077,
            "GPCPS": 0.7230769230769231,
            "HM": 0.6409307598039216,
            "HU": 0.681602614379085,
            "MA": 0.6508208556149733,
            "NA": 0.6464832887700536,
            "PS": 0.6519261576971214,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.35384615384615387,
            "BPCHU": 0.4153846153846154,
            "BPCMA": 0.4153846153846154,
            "BPCNA": 0.4307692307692308,
            "BPCPS": 0.4307692307692308,
            "GPCHM": 0.7538461538461538,
            "GPCHU": 0.7076923076923077,
            "GPCMA": 0.6923076923076923,
            "GPCNA": 0.6923076923076923,
            "GPCPS": 0.7384615384615385,
            "HM": 0.6426620648259304,
            "HU": 0.6826272378516623,
            "MA": 0.6506379084967321,
            "NA": 0.6452,
            "PS": 0.6539926470588235,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.35384615384615387,
            "BPCHU": 0.4153846153846154,
            "BPCMA": 0.4153846153846154,
            "BPCNA": 0.4307692307692308,
            "BPCPS": 0.4307692307692308,
            "GPCHM": 0.7538461538461538,
            "GPCHU": 0.7076923076923077,
            "GPCMA": 0.6923076923076923,
            "GPCNA": 0.6923076923076923,
            "GPCPS": 0.7384615384615385,
            "HM": 0.6426620648259304,
            "HU": 0.6826272378516623,
            "MA": 0.6506379084967321,
            "NA": 0.6452,
            "PS": 0.6539926470588235,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.35384615384615387,
            "BPCHU": 0.4307692307692308,
            "BPCMA": 0.4461538461538462,
            "BPCNA": 0.4461538461538462,
            "BPCPS": 0.4307692307692308,
            "GPCHM": 0.7846153846153846,
            "GPCHU": 0.7230769230769231,
            "GPCMA": 0.6923076923076923,
            "GPCNA": 0.7076923076923077,
            "GPCPS": 0.7692307692307693,
            "HM": 0.6421978085351787,
            "HU": 0.6796877346683353,
            "MA": 0.6506379084967321,
            "NA": 0.6410485933503837,
            "PS": 0.6533447058823529,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0,
            "GPCNA": 0,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0,
            "NA": 0,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.015384615384615385,
            "HM": 0.48858823529411766,
            "HU": 0.7073676470588235,
            "MA": 0,
            "NA": 0.4822941176470588,
            "PS": 0.7658823529411765,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.015384615384615385,
            "HM": 0.48858823529411766,
            "HU": 0.7073676470588235,
            "MA": 0,
            "NA": 0.4822941176470588,
            "PS": 0.7658823529411765,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.03076923076923077,
            "HM": 0.5791029411764705,
            "HU": 0.7073676470588235,
            "MA": 0,
            "NA": 0.5420980392156862,
            "PS": 0.8034411764705882,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.046153846153846156,
            "HM": 0.6408529411764706,
            "HU": 0.7355294117647059,
            "MA": 0.7813529411764706,
            "NA": 0.6020588235294118,
            "PS": 0.7899411764705883,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.046153846153846156,
            "HM": 0.6408529411764706,
            "HU": 0.7355294117647059,
            "MA": 0.7813529411764706,
            "NA": 0.6020588235294118,
            "PS": 0.7899411764705883,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.046153846153846156,
            "HM": 0.6408529411764706,
            "HU": 0.7355294117647059,
            "MA": 0.7813529411764706,
            "NA": 0.6020588235294118,
            "PS": 0.7899411764705883,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.12307692307692308,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.046153846153846156,
            "HM": 0.6408529411764706,
            "HU": 0.7355294117647059,
            "MA": 0.7813529411764706,
            "NA": 0.6020588235294118,
            "PS": 0.7899411764705883,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.12307692307692308,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.046153846153846156,
            "HM": 0.6408529411764706,
            "HU": 0.7355294117647059,
            "MA": 0.7813529411764706,
            "NA": 0.6020588235294118,
            "PS": 0.7899411764705883,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.12307692307692308,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.046153846153846156,
            "HM": 0.6408529411764706,
            "HU": 0.7355294117647059,
            "MA": 0.7813529411764706,
            "NA": 0.6020588235294118,
            "PS": 0.7899411764705883,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.13846153846153847,
            "BPCMA": 0.15384615384615385,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.07692307692307693,
            "HM": 0.6370823529411764,
            "HU": 0.7124362745098038,
            "MA": 0.6626274509803921,
            "NA": 0.6171862745098039,
            "PS": 0.7261529411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.15384615384615385,
            "BPCHU": 0.16923076923076924,
            "BPCMA": 0.2,
            "BPCNA": 0.13846153846153847,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.13846153846153847,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.13846153846153847,
            "GPCPS": 0.13846153846153847,
            "HM": 0.6339448529411764,
            "HU": 0.7187810457516339,
            "MA": 0.6964823529411766,
            "NA": 0.6293398692810457,
            "PS": 0.7160392156862745,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2153846153846154,
            "BPCHU": 0.23076923076923078,
            "BPCMA": 0.26153846153846155,
            "BPCNA": 0.18461538461538463,
            "BPCPS": 0.2,
            "GPCHM": 0.2153846153846154,
            "GPCHU": 0.23076923076923078,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.24615384615384617,
            "GPCPS": 0.27692307692307694,
            "HM": 0.665405462184874,
            "HU": 0.7193823529411765,
            "MA": 0.7023208556149733,
            "NA": 0.6613676470588236,
            "PS": 0.7158137254901961,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.23076923076923078,
            "BPCHU": 0.27692307692307694,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.2,
            "BPCPS": 0.23076923076923078,
            "GPCHM": 0.24615384615384617,
            "GPCHU": 0.23076923076923078,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.27692307692307694,
            "GPCPS": 0.2923076923076923,
            "HM": 0.6576268382352941,
            "HU": 0.7088137254901962,
            "MA": 0.6747941176470589,
            "NA": 0.6593071895424837,
            "PS": 0.7049953560371517,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.26153846153846155,
            "BPCHU": 0.3076923076923077,
            "BPCMA": 0.3230769230769231,
            "BPCNA": 0.23076923076923078,
            "BPCPS": 0.26153846153846155,
            "GPCHM": 0.24615384615384617,
            "GPCHU": 0.23076923076923078,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.27692307692307694,
            "GPCPS": 0.2923076923076923,
            "HM": 0.6576268382352941,
            "HU": 0.7088137254901962,
            "MA": 0.6747941176470589,
            "NA": 0.6593071895424837,
            "PS": 0.7049953560371517,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3230769230769231,
            "BPCHU": 0.36923076923076925,
            "BPCMA": 0.4,
            "BPCNA": 0.2923076923076923,
            "BPCPS": 0.3076923076923077,
            "GPCHM": 0.27692307692307694,
            "GPCHU": 0.27692307692307694,
            "GPCMA": 0.2153846153846154,
            "GPCNA": 0.3076923076923077,
            "GPCPS": 0.35384615384615387,
            "HM": 0.6676715686274509,
            "HU": 0.7193235294117647,
            "MA": 0.6843655462184874,
            "NA": 0.6625823529411764,
            "PS": 0.694574168797954,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3230769230769231,
            "BPCHU": 0.38461538461538464,
            "BPCMA": 0.4,
            "BPCNA": 0.2923076923076923,
            "BPCPS": 0.3076923076923077,
            "GPCHM": 0.2923076923076923,
            "GPCHU": 0.27692307692307694,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.3230769230769231,
            "GPCPS": 0.36923076923076925,
            "HM": 0.6754241486068112,
            "HU": 0.7193235294117647,
            "MA": 0.6944529411764706,
            "NA": 0.6698319327731093,
            "PS": 0.6995747549019609,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3230769230769231,
            "BPCHU": 0.38461538461538464,
            "BPCMA": 0.4,
            "BPCNA": 0.2923076923076923,
            "BPCPS": 0.3076923076923077,
            "GPCHM": 0.2923076923076923,
            "GPCHU": 0.27692307692307694,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.3230769230769231,
            "GPCPS": 0.36923076923076925,
            "HM": 0.6754241486068112,
            "HU": 0.7193235294117647,
            "MA": 0.6944529411764706,
            "NA": 0.6698319327731093,
            "PS": 0.6995747549019609,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.35384615384615387,
            "BPCHU": 0.4307692307692308,
            "BPCMA": 0.4153846153846154,
            "BPCNA": 0.3076923076923077,
            "BPCPS": 0.3230769230769231,
            "GPCHM": 0.3076923076923077,
            "GPCHU": 0.27692307692307694,
            "GPCMA": 0.26153846153846155,
            "GPCNA": 0.35384615384615387,
            "GPCPS": 0.4,
            "HM": 0.6824485294117647,
            "HU": 0.7193235294117647,
            "MA": 0.7104100346020761,
            "NA": 0.6826624040920717,
            "PS": 0.7062432126696833,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.35384615384615387,
            "BPCHU": 0.4461538461538462,
            "BPCMA": 0.4307692307692308,
            "BPCNA": 0.3076923076923077,
            "BPCPS": 0.3384615384615385,
            "GPCHM": 0.3384615384615385,
            "GPCHU": 0.2923076923076923,
            "GPCMA": 0.27692307692307694,
            "GPCNA": 0.38461538461538464,
            "GPCPS": 0.4153846153846154,
            "HM": 0.6848636363636363,
            "HU": 0.7220526315789474,
            "MA": 0.705640522875817,
            "NA": 0.6833023529411765,
            "PS": 0.7036045751633987,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.7142647058823529,
            "HU": 0.7503823529411765,
            "MA": 0.7308529411764706,
            "NA": 0.7307941176470588,
            "PS": 0.7308823529411764,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.7142647058823529,
            "HU": 0.7503823529411765,
            "MA": 0.7308529411764706,
            "NA": 0.7307941176470588,
            "PS": 0.7308823529411764,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.7142647058823529,
            "HU": 0.7503823529411765,
            "MA": 0.7308529411764706,
            "NA": 0.7307941176470588,
            "PS": 0.7308823529411764,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.03076923076923077,
            "HM": 0.7291764705882353,
            "HU": 0.7543382352941177,
            "MA": 0.7378382352941176,
            "NA": 0.7376470588235294,
            "PS": 0.7369558823529412,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.03076923076923077,
            "HM": 0.7291764705882353,
            "HU": 0.7543382352941177,
            "MA": 0.7378382352941176,
            "NA": 0.7376470588235294,
            "PS": 0.7369558823529412,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.046153846153846156,
            "HM": 0.7334509803921568,
            "HU": 0.7616176470588235,
            "MA": 0.7371666666666667,
            "NA": 0.7392254901960784,
            "PS": 0.7394019607843137,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.06153846153846154,
            "HM": 0.7548382352941176,
            "HU": 0.7803161764705883,
            "MA": 0.757,
            "NA": 0.7590661764705883,
            "PS": 0.7592132352941177,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.07692307692307693,
            "HM": 0.7411941176470588,
            "HU": 0.7702,
            "MA": 0.7418294117647058,
            "NA": 0.7439294117647058,
            "PS": 0.7443235294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.07692307692307693,
            "HM": 0.7411941176470588,
            "HU": 0.7702,
            "MA": 0.7418294117647058,
            "NA": 0.7439294117647058,
            "PS": 0.7443235294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.07692307692307693,
            "HM": 0.7411941176470588,
            "HU": 0.7702,
            "MA": 0.7418294117647058,
            "NA": 0.7439294117647058,
            "PS": 0.7443235294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.07692307692307693,
            "HM": 0.7411941176470588,
            "HU": 0.7702,
            "MA": 0.7418294117647058,
            "NA": 0.7439294117647058,
            "PS": 0.7443235294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.12307692307692308,
            "HM": 0.7664926470588236,
            "HU": 0.7624117647058823,
            "MA": 0.7354957983193277,
            "NA": 0.7652169117647059,
            "PS": 0.7292977941176471,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.13846153846153847,
            "GPCPS": 0.15384615384615385,
            "HM": 0.7362222222222222,
            "HU": 0.7538860294117647,
            "MA": 0.7236580882352941,
            "NA": 0.733032679738562,
            "PS": 0.707670588235294,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.2,
            "HM": 0.7076642156862745,
            "HU": 0.7403147058823529,
            "MA": 0.7003716577540107,
            "NA": 0.7009166666666667,
            "PS": 0.7052850678733031,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.13846153846153847,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.26153846153846155,
            "GPCHU": 0.2153846153846154,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.27692307692307694,
            "GPCPS": 0.27692307692307694,
            "HM": 0.7164809688581315,
            "HU": 0.748735294117647,
            "MA": 0.7099509803921569,
            "NA": 0.7151519607843138,
            "PS": 0.7343251633986928,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.15384615384615385,
            "BPCHU": 0.2153846153846154,
            "BPCMA": 0.2,
            "BPCNA": 0.13846153846153847,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.38461538461538464,
            "GPCHU": 0.3230769230769231,
            "GPCMA": 0.3384615384615385,
            "GPCNA": 0.4,
            "GPCPS": 0.38461538461538464,
            "HM": 0.7171776470588236,
            "HU": 0.7373347338935575,
            "MA": 0.7135187165775401,
            "NA": 0.7065859728506788,
            "PS": 0.7248682352941177,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.24615384615384617,
            "BPCHU": 0.3076923076923077,
            "BPCMA": 0.27692307692307694,
            "BPCNA": 0.2,
            "BPCPS": 0.23076923076923078,
            "GPCHM": 0.4461538461538462,
            "GPCHU": 0.38461538461538464,
            "GPCMA": 0.4153846153846154,
            "GPCNA": 0.49230769230769234,
            "GPCPS": 0.47692307692307695,
            "HM": 0.7002981744421907,
            "HU": 0.7378094117647059,
            "MA": 0.6862483660130719,
            "NA": 0.6875873161764706,
            "PS": 0.7043007590132827,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.26153846153846155,
            "BPCHU": 0.35384615384615387,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.2153846153846154,
            "BPCPS": 0.24615384615384617,
            "GPCHM": 0.5076923076923077,
            "GPCHU": 0.4153846153846154,
            "GPCMA": 0.47692307692307695,
            "GPCNA": 0.5538461538461539,
            "GPCPS": 0.5538461538461539,
            "HM": 0.7085713012477719,
            "HU": 0.7343747276688454,
            "MA": 0.69676944971537,
            "NA": 0.6964950980392156,
            "PS": 0.7149730392156862,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.26153846153846155,
            "BPCHU": 0.35384615384615387,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.2153846153846154,
            "BPCPS": 0.27692307692307694,
            "GPCHM": 0.5538461538461539,
            "GPCHU": 0.46153846153846156,
            "GPCMA": 0.5230769230769231,
            "GPCNA": 0.6,
            "GPCPS": 0.5846153846153846,
            "HM": 0.7141184640522875,
            "HU": 0.7390813725490196,
            "MA": 0.7022846020761245,
            "NA": 0.6981734539969835,
            "PS": 0.7162012383900929,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.26153846153846155,
            "BPCHU": 0.35384615384615387,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.2153846153846154,
            "BPCPS": 0.27692307692307694,
            "GPCHM": 0.5538461538461539,
            "GPCHU": 0.46153846153846156,
            "GPCMA": 0.5230769230769231,
            "GPCNA": 0.6,
            "GPCPS": 0.5846153846153846,
            "HM": 0.7141184640522875,
            "HU": 0.7390813725490196,
            "MA": 0.7022846020761245,
            "NA": 0.6981734539969835,
            "PS": 0.7162012383900929,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.26153846153846155,
            "BPCHU": 0.35384615384615387,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.2153846153846154,
            "BPCPS": 0.27692307692307694,
            "GPCHM": 0.5538461538461539,
            "GPCHU": 0.46153846153846156,
            "GPCMA": 0.5230769230769231,
            "GPCNA": 0.6,
            "GPCPS": 0.5846153846153846,
            "HM": 0.7141184640522875,
            "HU": 0.7390813725490196,
            "MA": 0.7022846020761245,
            "NA": 0.6981734539969835,
            "PS": 0.7162012383900929,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.26153846153846155,
            "BPCHU": 0.35384615384615387,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.2153846153846154,
            "BPCPS": 0.27692307692307694,
            "GPCHM": 0.5538461538461539,
            "GPCHU": 0.46153846153846156,
            "GPCMA": 0.5230769230769231,
            "GPCNA": 0.6,
            "GPCPS": 0.5846153846153846,
            "HM": 0.7141184640522875,
            "HU": 0.7390813725490196,
            "MA": 0.7022846020761245,
            "NA": 0.6981734539969835,
            "PS": 0.7162012383900929,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.26153846153846155,
            "BPCHU": 0.35384615384615387,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.2153846153846154,
            "BPCPS": 0.27692307692307694,
            "GPCHM": 0.5692307692307692,
            "GPCHU": 0.47692307692307695,
            "GPCMA": 0.5384615384615384,
            "GPCNA": 0.6153846153846154,
            "GPCPS": 0.6,
            "HM": 0.7145850556438792,
            "HU": 0.7431612903225807,
            "MA": 0.7029193277310924,
            "NA": 0.6991580882352941,
            "PS": 0.7164645550527903,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.7986470588235294,
            "HU": 0,
            "MA": 0.7182941176470589,
            "NA": 0.740764705882353,
            "PS": 0.8289411764705882,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.7986470588235294,
            "HU": 0,
            "MA": 0.7182941176470589,
            "NA": 0.740764705882353,
            "PS": 0.8289411764705882,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.046153846153846156,
            "HM": 0.7763333333333333,
            "HU": 0.9264705882352942,
            "MA": 0.7380882352941176,
            "NA": 0.7583235294117647,
            "PS": 0.7969411764705883,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.06153846153846154,
            "HM": 0.8051823529411765,
            "HU": 0.866985294117647,
            "MA": 0.7871823529411764,
            "NA": 0.7825352941176471,
            "PS": 0.7808014705882353,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.16923076923076924,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.12307692307692308,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.1076923076923077,
            "HM": 0.7687610294117647,
            "HU": 0.7542573529411765,
            "MA": 0.740218487394958,
            "NA": 0.7546764705882353,
            "PS": 0.7614495798319327,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.2,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.12307692307692308,
            "HM": 0.7889735294117647,
            "HU": 0.7725176470588235,
            "MA": 0.7692777777777778,
            "NA": 0.7776852941176471,
            "PS": 0.7690367647058823,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.23076923076923078,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.15384615384615385,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.15384615384615385,
            "HM": 0.7854509803921568,
            "HU": 0.7725176470588235,
            "MA": 0.7576852941176471,
            "NA": 0.7787009803921568,
            "PS": 0.779035294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.23076923076923078,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.15384615384615385,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.15384615384615385,
            "HM": 0.7854509803921568,
            "HU": 0.7725176470588235,
            "MA": 0.7576852941176471,
            "NA": 0.7787009803921568,
            "PS": 0.779035294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.13846153846153847,
            "BPCHU": 0.27692307692307694,
            "BPCMA": 0.18461538461538463,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.24615384615384617,
            "GPCHM": 0.26153846153846155,
            "GPCHU": 0.13846153846153847,
            "GPCMA": 0.2,
            "GPCNA": 0.24615384615384617,
            "GPCPS": 0.18461538461538463,
            "HM": 0.7614100346020761,
            "HU": 0.7606928104575164,
            "MA": 0.746972850678733,
            "NA": 0.7385128676470588,
            "PS": 0.7760857843137255,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.16923076923076924,
            "BPCHU": 0.3076923076923077,
            "BPCMA": 0.2153846153846154,
            "BPCNA": 0.18461538461538463,
            "BPCPS": 0.27692307692307694,
            "GPCHM": 0.26153846153846155,
            "GPCHU": 0.13846153846153847,
            "GPCMA": 0.2,
            "GPCNA": 0.24615384615384617,
            "GPCPS": 0.18461538461538463,
            "HM": 0.7614100346020761,
            "HU": 0.7606928104575164,
            "MA": 0.746972850678733,
            "NA": 0.7385128676470588,
            "PS": 0.7760857843137255,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.6130882352941176,
            "HU": 0.8667941176470588,
            "MA": 0,
            "NA": 0.5871764705882353,
            "PS": 0.6451470588235294,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.6130882352941176,
            "HU": 0.8667941176470588,
            "MA": 0,
            "NA": 0.5871764705882353,
            "PS": 0.6451470588235294,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.03076923076923077,
            "HM": 0.6591911764705882,
            "HU": 0.7925882352941176,
            "MA": 0.7073235294117647,
            "NA": 0.646235294117647,
            "PS": 0.6756176470588235,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.03076923076923077,
            "HM": 0.6591911764705882,
            "HU": 0.7925882352941176,
            "MA": 0.7073235294117647,
            "NA": 0.646235294117647,
            "PS": 0.6756176470588235,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.06153846153846154,
            "HM": 0.7048161764705883,
            "HU": 0.8168088235294118,
            "MA": 0.7089509803921569,
            "NA": 0.6971544117647058,
            "PS": 0.7086985294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.07692307692307693,
            "HM": 0.7298823529411764,
            "HU": 0.8363352941176471,
            "MA": 0.690514705882353,
            "NA": 0.735014705882353,
            "PS": 0.7091117647058823,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.09230769230769231,
            "HM": 0.7382773109243698,
            "HU": 0.8363352941176471,
            "MA": 0.690514705882353,
            "NA": 0.7410378151260504,
            "PS": 0.7239803921568627,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.1076923076923077,
            "HM": 0.7282169117647059,
            "HU": 0.8393382352941177,
            "MA": 0.6901294117647059,
            "NA": 0.7307279411764706,
            "PS": 0.7194831932773109,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.1076923076923077,
            "HM": 0.7282169117647059,
            "HU": 0.8393382352941177,
            "MA": 0.6901294117647059,
            "NA": 0.7307279411764706,
            "PS": 0.7194831932773109,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.13846153846153847,
            "GPCPS": 0.12307692307692308,
            "HM": 0.7088104575163399,
            "HU": 0.8393382352941177,
            "MA": 0.6901294117647059,
            "NA": 0.7141895424836602,
            "PS": 0.7027242647058823,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.13846153846153847,
            "HM": 0.6910264705882353,
            "HU": 0.839109243697479,
            "MA": 0.7009754901960785,
            "NA": 0.6973323529411765,
            "PS": 0.687702614379085,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.13846153846153847,
            "HM": 0.6910264705882353,
            "HU": 0.839109243697479,
            "MA": 0.7009754901960785,
            "NA": 0.6973323529411765,
            "PS": 0.687702614379085,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.16923076923076924,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.15384615384615385,
            "HM": 0.6801604278074866,
            "HU": 0.8458823529411764,
            "MA": 0.7009754901960785,
            "NA": 0.6859064171122994,
            "PS": 0.676329411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.13846153846153847,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.16923076923076924,
            "HM": 0.6985343137254902,
            "HU": 0.8458823529411764,
            "MA": 0.7286596638655463,
            "NA": 0.7038872549019608,
            "PS": 0.6967941176470588,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.23076923076923078,
            "BPCMA": 0.2153846153846154,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.26153846153846155,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.26153846153846155,
            "GPCPS": 0.24615384615384617,
            "HM": 0.7183373702422146,
            "HU": 0.8102230392156863,
            "MA": 0.7295534759358289,
            "NA": 0.7185397923875433,
            "PS": 0.7227463235294118,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.24615384615384617,
            "BPCMA": 0.23076923076923078,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.2923076923076923,
            "GPCHU": 0.2,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.2923076923076923,
            "GPCPS": 0.27692307692307694,
            "HM": 0.7399891640866874,
            "HU": 0.8211809954751131,
            "MA": 0.7457745098039216,
            "NA": 0.7406253869969041,
            "PS": 0.7454166666666667,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.26153846153846155,
            "BPCMA": 0.24615384615384617,
            "BPCNA": 0.12307692307692308,
            "BPCPS": 0.2,
            "GPCHM": 0.3384615384615385,
            "GPCHU": 0.24615384615384617,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.3384615384615385,
            "GPCPS": 0.3230769230769231,
            "HM": 0.7446403743315508,
            "HU": 0.821702205882353,
            "MA": 0.7527627450980392,
            "NA": 0.7450681818181818,
            "PS": 0.7476358543417366,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.27692307692307694,
            "BPCMA": 0.24615384615384617,
            "BPCNA": 0.12307692307692308,
            "BPCPS": 0.2,
            "GPCHM": 0.36923076923076925,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.26153846153846155,
            "GPCNA": 0.36923076923076925,
            "GPCPS": 0.35384615384615387,
            "HM": 0.7459644607843138,
            "HU": 0.8160709342560554,
            "MA": 0.7544532871972318,
            "NA": 0.7466200980392157,
            "PS": 0.7474424552429667,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.16923076923076924,
            "BPCHU": 0.3384615384615385,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.13846153846153847,
            "BPCPS": 0.24615384615384617,
            "GPCHM": 0.38461538461538464,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.27692307692307694,
            "GPCNA": 0.4153846153846154,
            "GPCPS": 0.36923076923076925,
            "HM": 0.7497070588235294,
            "HU": 0.8160709342560554,
            "MA": 0.7608202614379086,
            "NA": 0.7577668845315904,
            "PS": 0.7515232843137255,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.16923076923076924,
            "BPCHU": 0.3384615384615385,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.13846153846153847,
            "BPCPS": 0.24615384615384617,
            "GPCHM": 0.38461538461538464,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.27692307692307694,
            "GPCNA": 0.4153846153846154,
            "GPCPS": 0.36923076923076925,
            "HM": 0.7497070588235294,
            "HU": 0.8160709342560554,
            "MA": 0.7608202614379086,
            "NA": 0.7577668845315904,
            "PS": 0.7515232843137255,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.18461538461538463,
            "BPCHU": 0.36923076923076925,
            "BPCMA": 0.3076923076923077,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.26153846153846155,
            "GPCHM": 0.4307692307692308,
            "GPCHU": 0.2923076923076923,
            "GPCMA": 0.3230769230769231,
            "GPCNA": 0.46153846153846156,
            "GPCPS": 0.4153846153846154,
            "HM": 0.751125,
            "HU": 0.8188869969040248,
            "MA": 0.7640532212885154,
            "NA": 0.7612725490196078,
            "PS": 0.7537647058823529,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.18461538461538463,
            "BPCHU": 0.36923076923076925,
            "BPCMA": 0.3076923076923077,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.26153846153846155,
            "GPCHM": 0.4307692307692308,
            "GPCHU": 0.2923076923076923,
            "GPCMA": 0.3230769230769231,
            "GPCNA": 0.46153846153846156,
            "GPCPS": 0.4153846153846154,
            "HM": 0.7491271008403361,
            "HU": 0.8220108359133126,
            "MA": 0.7648067226890757,
            "NA": 0.7581186274509804,
            "PS": 0.751355119825708,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.23076923076923078,
            "BPCHU": 0.4,
            "BPCMA": 0.35384615384615387,
            "BPCNA": 0.2,
            "BPCPS": 0.3076923076923077,
            "GPCHM": 0.4461538461538462,
            "GPCHU": 0.3230769230769231,
            "GPCMA": 0.3384615384615385,
            "GPCNA": 0.47692307692307695,
            "GPCPS": 0.4307692307692308,
            "HM": 0.7550892494929006,
            "HU": 0.8200210084033613,
            "MA": 0.77224064171123,
            "NA": 0.7635730550284631,
            "PS": 0.7573224789915967,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.7387941176470588,
            "HU": 0.9438529411764706,
            "MA": 0.6495294117647059,
            "NA": 0.7419705882352942,
            "PS": 0.7441470588235294,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.03076923076923077,
            "HM": 0.7621176470588236,
            "HU": 0.9438529411764706,
            "MA": 0.8096764705882353,
            "NA": 0.7712794117647059,
            "PS": 0.7722205882352942,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.046153846153846156,
            "HM": 0.7921666666666667,
            "HU": 0.9304558823529412,
            "MA": 0.8172745098039216,
            "NA": 0.7957549019607844,
            "PS": 0.7967941176470589,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.046153846153846156,
            "HM": 0.7921666666666667,
            "HU": 0.9304558823529412,
            "MA": 0.8172745098039216,
            "NA": 0.7957549019607844,
            "PS": 0.7967941176470589,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.046153846153846156,
            "HM": 0.7921666666666667,
            "HU": 0.9304558823529412,
            "MA": 0.8172745098039216,
            "NA": 0.7957549019607844,
            "PS": 0.7967941176470589,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.046153846153846156,
            "HM": 0.7921666666666667,
            "HU": 0.9304558823529412,
            "MA": 0.8172745098039216,
            "NA": 0.7957549019607844,
            "PS": 0.7967941176470589,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.07692307692307693,
            "HM": 0.8190941176470589,
            "HU": 0.8952058823529412,
            "MA": 0.8174117647058824,
            "NA": 0.8111294117647059,
            "PS": 0.8130941176470589,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.09230769230769231,
            "HM": 0.8099411764705883,
            "HU": 0.8798602941176471,
            "MA": 0.8053823529411764,
            "NA": 0.8036421568627451,
            "PS": 0.8046470588235294,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.12307692307692308,
            "HM": 0.8395735294117647,
            "HU": 0.8979941176470588,
            "MA": 0.8445840336134454,
            "NA": 0.8345514705882353,
            "PS": 0.83625,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.12307692307692308,
            "HM": 0.8395735294117647,
            "HU": 0.8979941176470588,
            "MA": 0.8445840336134454,
            "NA": 0.8345514705882353,
            "PS": 0.83625,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.13846153846153847,
            "GPCPS": 0.13846153846153847,
            "HM": 0.8226895424836601,
            "HU": 0.903264705882353,
            "MA": 0.8157426470588235,
            "NA": 0.8188366013071896,
            "PS": 0.8207287581699346,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.15384615384615385,
            "HM": 0.834379411764706,
            "HU": 0.9112142857142858,
            "MA": 0.8294444444444444,
            "NA": 0.8309264705882353,
            "PS": 0.8323029411764705,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.16923076923076924,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.15384615384615385,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.16923076923076924,
            "HM": 0.8099652406417112,
            "HU": 0.9121397058823529,
            "MA": 0.8250588235294117,
            "NA": 0.8114358288770053,
            "PS": 0.8130989304812835,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.18461538461538463,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.18461538461538463,
            "HM": 0.8078725490196079,
            "HU": 0.9121397058823529,
            "MA": 0.8188930481283422,
            "NA": 0.8101691176470588,
            "PS": 0.812607843137255,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.18461538461538463,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.2153846153846154,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.2,
            "GPCNA": 0.2153846153846154,
            "GPCPS": 0.2153846153846154,
            "HM": 0.8144474789915966,
            "HU": 0.9125323529411764,
            "MA": 0.8236945701357465,
            "NA": 0.8167899159663865,
            "PS": 0.8166764705882353,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.18461538461538463,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.24615384615384617,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.2153846153846154,
            "GPCNA": 0.23076923076923078,
            "GPCPS": 0.24615384615384617,
            "HM": 0.8016213235294117,
            "HU": 0.8838063725490196,
            "MA": 0.8321302521008404,
            "NA": 0.8211235294117647,
            "PS": 0.8053088235294118,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.2153846153846154,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.12307692307692308,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.26153846153846155,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.24615384615384617,
            "GPCPS": 0.27692307692307694,
            "HM": 0.8024117647058824,
            "HU": 0.8838063725490196,
            "MA": 0.8267745098039215,
            "NA": 0.8209411764705883,
            "PS": 0.8152418300653594,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2,
            "BPCHU": 0.3076923076923077,
            "BPCMA": 0.2153846153846154,
            "BPCNA": 0.2153846153846154,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.2923076923076923,
            "GPCHU": 0.2153846153846154,
            "GPCMA": 0.27692307692307694,
            "GPCNA": 0.27692307692307694,
            "GPCPS": 0.3076923076923077,
            "HM": 0.7979334365325077,
            "HU": 0.8705189075630253,
            "MA": 0.8253333333333333,
            "NA": 0.8184313725490197,
            "PS": 0.8098573529411766,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.27692307692307694,
            "BPCHU": 0.4153846153846154,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.3076923076923077,
            "BPCPS": 0.23076923076923078,
            "GPCHM": 0.36923076923076925,
            "GPCHU": 0.27692307692307694,
            "GPCMA": 0.3384615384615385,
            "GPCNA": 0.3384615384615385,
            "GPCPS": 0.4153846153846154,
            "HM": 0.8140649509803921,
            "HU": 0.8548235294117648,
            "MA": 0.8299772727272727,
            "NA": 0.8237754010695187,
            "PS": 0.8144411764705882,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3076923076923077,
            "BPCHU": 0.46153846153846156,
            "BPCMA": 0.3076923076923077,
            "BPCNA": 0.3230769230769231,
            "BPCPS": 0.27692307692307694,
            "GPCHM": 0.46153846153846156,
            "GPCHU": 0.35384615384615387,
            "GPCMA": 0.4307692307692308,
            "GPCNA": 0.4307692307692308,
            "GPCPS": 0.49230769230769234,
            "HM": 0.8156284313725489,
            "HU": 0.8644641943734015,
            "MA": 0.8299107142857143,
            "NA": 0.8249201680672269,
            "PS": 0.8184908088235294,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.35384615384615387,
            "BPCHU": 0.5076923076923077,
            "BPCMA": 0.35384615384615387,
            "BPCNA": 0.36923076923076925,
            "BPCPS": 0.3076923076923077,
            "GPCHM": 0.5230769230769231,
            "GPCHU": 0.4153846153846154,
            "GPCMA": 0.49230769230769234,
            "GPCNA": 0.49230769230769234,
            "GPCPS": 0.5692307692307692,
            "HM": 0.8029282006920415,
            "HU": 0.8500686274509803,
            "MA": 0.8208244485294117,
            "NA": 0.8104770220588235,
            "PS": 0.807185214626391,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.35384615384615387,
            "BPCHU": 0.5076923076923077,
            "BPCMA": 0.35384615384615387,
            "BPCNA": 0.36923076923076925,
            "BPCPS": 0.3076923076923077,
            "GPCHM": 0.5692307692307692,
            "GPCHU": 0.46153846153846156,
            "GPCMA": 0.5384615384615384,
            "GPCNA": 0.5384615384615384,
            "GPCPS": 0.6153846153846154,
            "HM": 0.8011359300476947,
            "HU": 0.8509686274509805,
            "MA": 0.8155050420168067,
            "NA": 0.8057596638655462,
            "PS": 0.8050955882352941,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.4,
            "BPCHU": 0.6,
            "BPCMA": 0.4307692307692308,
            "BPCNA": 0.4307692307692308,
            "BPCPS": 0.38461538461538464,
            "GPCHM": 0.6307692307692307,
            "GPCHU": 0.49230769230769234,
            "GPCMA": 0.5846153846153846,
            "GPCNA": 0.6,
            "GPCPS": 0.6615384615384615,
            "HM": 0.802213055954089,
            "HU": 0.8555220588235294,
            "MA": 0.8221300309597523,
            "NA": 0.8063770739064856,
            "PS": 0.8048242134062927,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.06153846153846154,
            "HM": 0.8860735294117648,
            "HU": 0.9208470588235294,
            "MA": 0.8854926470588236,
            "NA": 0.8840441176470588,
            "PS": 0.8781470588235294,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.09230769230769231,
            "HM": 0.9236596638655462,
            "HU": 0.9208470588235294,
            "MA": 0.9076764705882353,
            "NA": 0.9185245098039215,
            "PS": 0.912328431372549,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.12307692307692308,
            "HM": 0.879967320261438,
            "HU": 0.9244705882352942,
            "MA": 0.8892745098039215,
            "NA": 0.8526139705882353,
            "PS": 0.8823492647058824,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.13846153846153847,
            "GPCPS": 0.13846153846153847,
            "HM": 0.8508617647058824,
            "HU": 0.8941139705882353,
            "MA": 0.8432899159663865,
            "NA": 0.8239607843137255,
            "PS": 0.8488660130718955,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.16923076923076924,
            "HM": 0.8575294117647059,
            "HU": 0.8967882352941177,
            "MA": 0.8547777777777777,
            "NA": 0.8364812834224599,
            "PS": 0.8555454545454546,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.13846153846153847,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.15384615384615385,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.16923076923076924,
            "HM": 0.8575294117647059,
            "HU": 0.8967882352941177,
            "MA": 0.8547777777777777,
            "NA": 0.8364812834224599,
            "PS": 0.8555454545454546,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.16923076923076924,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.15384615384615385,
            "GPCHM": 0.2153846153846154,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.2,
            "GPCPS": 0.2,
            "HM": 0.8682584033613445,
            "HU": 0.8967882352941177,
            "MA": 0.8754278074866311,
            "NA": 0.8500226244343891,
            "PS": 0.8683031674208145,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.16923076923076924,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.15384615384615385,
            "GPCHM": 0.23076923076923078,
            "GPCHU": 0.16923076923076924,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.2153846153846154,
            "GPCPS": 0.2153846153846154,
            "HM": 0.8747843137254903,
            "HU": 0.9049438502673797,
            "MA": 0.8825637254901961,
            "NA": 0.8583298319327731,
            "PS": 0.874327731092437,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.2,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.23076923076923078,
            "GPCHU": 0.16923076923076924,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.2153846153846154,
            "GPCPS": 0.2153846153846154,
            "HM": 0.8747843137254903,
            "HU": 0.9049438502673797,
            "MA": 0.8825637254901961,
            "NA": 0.8583298319327731,
            "PS": 0.874327731092437,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.24615384615384617,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.12307692307692308,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.27692307692307694,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.26153846153846155,
            "GPCPS": 0.26153846153846155,
            "HM": 0.8722598039215685,
            "HU": 0.9107549019607843,
            "MA": 0.896878431372549,
            "NA": 0.8588910034602076,
            "PS": 0.8635865051903114,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.27692307692307694,
            "BPCMA": 0.15384615384615385,
            "BPCNA": 0.12307692307692308,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.3230769230769231,
            "GPCHU": 0.2153846153846154,
            "GPCMA": 0.26153846153846155,
            "GPCNA": 0.3230769230769231,
            "GPCPS": 0.3230769230769231,
            "HM": 0.8699593837535013,
            "HU": 0.8965609243697479,
            "MA": 0.8825916955017301,
            "NA": 0.8445238095238095,
            "PS": 0.8465840336134454,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.26153846153846155,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.12307692307692308,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.3384615384615385,
            "GPCHU": 0.24615384615384617,
            "GPCMA": 0.2923076923076923,
            "GPCNA": 0.3384615384615385,
            "GPCPS": 0.3384615384615385,
            "HM": 0.8606804812834224,
            "HU": 0.8945753676470588,
            "MA": 0.8784551083591331,
            "NA": 0.8364344919786096,
            "PS": 0.8383235294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.13846153846153847,
            "BPCHU": 0.3076923076923077,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.13846153846153847,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.38461538461538464,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.35384615384615387,
            "GPCNA": 0.38461538461538464,
            "GPCPS": 0.4,
            "HM": 0.8506988235294117,
            "HU": 0.881242214532872,
            "MA": 0.8708363171355499,
            "NA": 0.8287682352941176,
            "PS": 0.8371832579185521,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.13846153846153847,
            "BPCHU": 0.3076923076923077,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.13846153846153847,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.4,
            "GPCHU": 0.27692307692307694,
            "GPCMA": 0.36923076923076925,
            "GPCNA": 0.4,
            "GPCPS": 0.4153846153846154,
            "HM": 0.8504558823529412,
            "HU": 0.8857777777777778,
            "MA": 0.8707941176470588,
            "NA": 0.8322285067873303,
            "PS": 0.8375599128540305,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.15384615384615385,
            "BPCHU": 0.3230769230769231,
            "BPCMA": 0.15384615384615385,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.2,
            "GPCHM": 0.4153846153846154,
            "GPCHU": 0.2923076923076923,
            "GPCMA": 0.38461538461538464,
            "GPCNA": 0.4153846153846154,
            "GPCPS": 0.4307692307692308,
            "HM": 0.8552069716775599,
            "HU": 0.8710882352941176,
            "MA": 0.8748941176470588,
            "NA": 0.8374139433551198,
            "PS": 0.8427121848739496,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.16923076923076924,
            "BPCHU": 0.38461538461538464,
            "BPCMA": 0.16923076923076924,
            "BPCNA": 0.18461538461538463,
            "BPCPS": 0.2153846153846154,
            "GPCHM": 0.5230769230769231,
            "GPCHU": 0.35384615384615387,
            "GPCMA": 0.49230769230769234,
            "GPCNA": 0.5076923076923077,
            "GPCPS": 0.5384615384615384,
            "HM": 0.8427223183391004,
            "HU": 0.8632813299232737,
            "MA": 0.8607757352941177,
            "NA": 0.8274509803921568,
            "PS": 0.8441890756302521,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2,
            "BPCHU": 0.4461538461538462,
            "BPCMA": 0.23076923076923078,
            "BPCNA": 0.23076923076923078,
            "BPCPS": 0.26153846153846155,
            "GPCHM": 0.6,
            "GPCHU": 0.4,
            "GPCMA": 0.5384615384615384,
            "GPCNA": 0.5692307692307692,
            "GPCPS": 0.6,
            "HM": 0.8349426847662141,
            "HU": 0.8638721719457014,
            "MA": 0.8601268907563024,
            "NA": 0.8278728139904611,
            "PS": 0.8372843137254903,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2153846153846154,
            "BPCHU": 0.46153846153846156,
            "BPCMA": 0.23076923076923078,
            "BPCNA": 0.24615384615384617,
            "BPCPS": 0.27692307692307694,
            "GPCHM": 0.6461538461538462,
            "GPCHU": 0.4461538461538462,
            "GPCMA": 0.6,
            "GPCNA": 0.6153846153846154,
            "GPCPS": 0.6461538461538462,
            "HM": 0.8338662464985994,
            "HU": 0.8647261663286003,
            "MA": 0.86389592760181,
            "NA": 0.8279882352941176,
            "PS": 0.8375917366946778,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.23076923076923078,
            "BPCHU": 0.5076923076923077,
            "BPCMA": 0.24615384615384617,
            "BPCNA": 0.26153846153846155,
            "BPCPS": 0.2923076923076923,
            "GPCHM": 0.7076923076923077,
            "GPCHU": 0.47692307692307695,
            "GPCMA": 0.6615384615384615,
            "GPCNA": 0.676923076923077,
            "GPCPS": 0.7076923076923077,
            "HM": 0.8321726342710998,
            "HU": 0.8662931688804555,
            "MA": 0.8589705882352942,
            "NA": 0.8277393048128342,
            "PS": 0.8358529411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.26153846153846155,
            "BPCHU": 0.5692307692307692,
            "BPCMA": 0.2923076923076923,
            "BPCNA": 0.2923076923076923,
            "BPCPS": 0.3230769230769231,
            "GPCHM": 0.7846153846153846,
            "GPCHU": 0.5230769230769231,
            "GPCMA": 0.7230769230769231,
            "GPCNA": 0.7538461538461538,
            "GPCPS": 0.7846153846153846,
            "HM": 0.8354688581314879,
            "HU": 0.8746003460207612,
            "MA": 0.8579042553191489,
            "NA": 0.8301368547418968,
            "PS": 0.8395599769319493,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3076923076923077,
            "BPCHU": 0.6153846153846154,
            "BPCMA": 0.3230769230769231,
            "BPCNA": 0.3384615384615385,
            "BPCPS": 0.36923076923076925,
            "GPCHM": 0.8769230769230769,
            "GPCHU": 0.6153846153846154,
            "GPCMA": 0.8153846153846154,
            "GPCNA": 0.8307692307692308,
            "GPCPS": 0.8769230769230769,
            "HM": 0.8320067079463365,
            "HU": 0.8793794117647059,
            "MA": 0.8536698113207547,
            "NA": 0.8252254901960785,
            "PS": 0.8378802889576884,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3230769230769231,
            "BPCHU": 0.6615384615384615,
            "BPCMA": 0.35384615384615387,
            "BPCNA": 0.36923076923076925,
            "BPCPS": 0.4,
            "GPCHM": 0.9846153846153847,
            "GPCHU": 0.6923076923076923,
            "GPCMA": 0.8923076923076924,
            "GPCNA": 0.9230769230769231,
            "GPCPS": 0.9692307692307692,
            "HM": 0.8331695772058824,
            "HU": 0.8873921568627451,
            "MA": 0.8545720081135902,
            "NA": 0.824396568627451,
            "PS": 0.8359024276377218,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.3384615384615385,
            "BPCHU": 0.7076923076923077,
            "BPCMA": 0.38461538461538464,
            "BPCNA": 0.38461538461538464,
            "BPCPS": 0.4153846153846154,
            "GPCHM": 1.0153846153846153,
            "GPCHU": 0.6923076923076923,
            "GPCMA": 0.9076923076923077,
            "GPCNA": 0.9538461538461539,
            "GPCPS": 1,
            "HM": 0.83499376114082,
            "HU": 0.8873921568627451,
            "MA": 0.8540533399800597,
            "NA": 0.8263614800759013,
            "PS": 0.837668778280543,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.7159117647058824,
            "HU": 0.9843823529411765,
            "MA": 0.9687058823529412,
            "NA": 0.7257941176470588,
            "PS": 0.8402941176470589,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.046153846153846156,
            "HM": 0.7350490196078432,
            "HU": 0.912921568627451,
            "MA": 0.8837647058823529,
            "NA": 0.8032941176470588,
            "PS": 0.8207745098039215,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.046153846153846156,
            "HM": 0.7350490196078432,
            "HU": 0.912921568627451,
            "MA": 0.8837647058823529,
            "NA": 0.8032941176470588,
            "PS": 0.8207745098039215,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.06153846153846154,
            "HM": 0.7044926470588235,
            "HU": 0.9130955882352941,
            "MA": 0.888125,
            "NA": 0.7586176470588235,
            "PS": 0.8442352941176471,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.09230769230769231,
            "HM": 0.7674901960784314,
            "HU": 0.9270294117647059,
            "MA": 0.8978382352941177,
            "NA": 0.8038186274509803,
            "PS": 0.8616029411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.09230769230769231,
            "HM": 0.7674901960784314,
            "HU": 0.9270294117647059,
            "MA": 0.8978382352941177,
            "NA": 0.8038186274509803,
            "PS": 0.8616029411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.1076923076923077,
            "HM": 0.789563025210084,
            "HU": 0.9270294117647059,
            "MA": 0.9136680672268908,
            "NA": 0.8211680672268907,
            "PS": 0.8691638655462185,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.1076923076923077,
            "HM": 0.789563025210084,
            "HU": 0.9270294117647059,
            "MA": 0.9136680672268908,
            "NA": 0.8211680672268907,
            "PS": 0.8691638655462185,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.12307692307692308,
            "HM": 0.7849779411764706,
            "HU": 0.9216302521008404,
            "MA": 0.8935367647058824,
            "NA": 0.8130073529411764,
            "PS": 0.8557941176470588,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.12307692307692308,
            "HM": 0.7849779411764706,
            "HU": 0.9216302521008404,
            "MA": 0.8935367647058824,
            "NA": 0.8130073529411764,
            "PS": 0.8557941176470588,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.12307692307692308,
            "HM": 0.7849779411764706,
            "HU": 0.9216302521008404,
            "MA": 0.8935367647058824,
            "NA": 0.8130073529411764,
            "PS": 0.8557941176470588,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.15384615384615385,
            "HM": 0.8131970588235294,
            "HU": 0.9216302521008404,
            "MA": 0.8935367647058824,
            "NA": 0.8350411764705883,
            "PS": 0.8699941176470588,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.15384615384615385,
            "HM": 0.8131970588235294,
            "HU": 0.9216302521008404,
            "MA": 0.8935367647058824,
            "NA": 0.8350411764705883,
            "PS": 0.8699941176470588,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.16923076923076924,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.16923076923076924,
            "HM": 0.8137513368983957,
            "HU": 0.9325919117647059,
            "MA": 0.8821209150326796,
            "NA": 0.8376737967914438,
            "PS": 0.866024064171123,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.13846153846153847,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.16923076923076924,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.18461538461538463,
            "HM": 0.8137513368983957,
            "HU": 0.9325919117647059,
            "MA": 0.8821209150326796,
            "NA": 0.8376737967914438,
            "PS": 0.8780269607843137,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.15384615384615385,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.2,
            "GPCHU": 0.13846153846153847,
            "GPCMA": 0.15384615384615385,
            "GPCNA": 0.2,
            "GPCPS": 0.2153846153846154,
            "HM": 0.8318823529411765,
            "HU": 0.932421568627451,
            "MA": 0.8866676470588235,
            "NA": 0.8520950226244344,
            "PS": 0.8859789915966386,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.23076923076923078,
            "BPCMA": 0.16923076923076924,
            "BPCNA": 0.12307692307692308,
            "BPCPS": 0.12307692307692308,
            "GPCHM": 0.24615384615384617,
            "GPCHU": 0.13846153846153847,
            "GPCMA": 0.2,
            "GPCNA": 0.24615384615384617,
            "GPCPS": 0.26153846153846155,
            "HM": 0.8474503676470588,
            "HU": 0.932421568627451,
            "MA": 0.8807239819004524,
            "NA": 0.8599761029411764,
            "PS": 0.8879688581314878,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.13846153846153847,
            "BPCHU": 0.23076923076923078,
            "BPCMA": 0.2,
            "BPCNA": 0.13846153846153847,
            "BPCPS": 0.13846153846153847,
            "GPCHM": 0.2923076923076923,
            "GPCHU": 0.2,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.2923076923076923,
            "GPCPS": 0.3076923076923077,
            "HM": 0.8486842105263158,
            "HU": 0.9127466063348417,
            "MA": 0.8957705882352941,
            "NA": 0.8570201238390093,
            "PS": 0.8950029411764705,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2,
            "BPCHU": 0.35384615384615387,
            "BPCMA": 0.27692307692307694,
            "BPCNA": 0.23076923076923078,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.4,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.3230769230769231,
            "GPCNA": 0.36923076923076925,
            "GPCPS": 0.4461538461538462,
            "HM": 0.8374954751131222,
            "HU": 0.8620190311418685,
            "MA": 0.8707114845938375,
            "NA": 0.8559889705882353,
            "PS": 0.8717312373225152,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2,
            "BPCHU": 0.38461538461538464,
            "BPCMA": 0.3076923076923077,
            "BPCNA": 0.23076923076923078,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.4307692307692308,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.3230769230769231,
            "GPCNA": 0.4,
            "GPCPS": 0.47692307692307695,
            "HM": 0.8493319327731093,
            "HU": 0.8620190311418685,
            "MA": 0.8707114845938375,
            "NA": 0.8650214932126697,
            "PS": 0.8794592030360532,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2,
            "BPCHU": 0.4,
            "BPCMA": 0.3230769230769231,
            "BPCNA": 0.23076923076923078,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.4461538461538462,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.3230769230769231,
            "GPCNA": 0.4153846153846154,
            "GPCPS": 0.49230769230769234,
            "HM": 0.8517393509127789,
            "HU": 0.8620190311418685,
            "MA": 0.8707114845938375,
            "NA": 0.8707385620915032,
            "PS": 0.8814503676470589,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2153846153846154,
            "BPCHU": 0.4307692307692308,
            "BPCMA": 0.3384615384615385,
            "BPCNA": 0.24615384615384617,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.46153846153846156,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.3384615384615385,
            "GPCNA": 0.4307692307692308,
            "GPCPS": 0.5076923076923077,
            "HM": 0.8548784313725489,
            "HU": 0.8620190311418685,
            "MA": 0.877072192513369,
            "NA": 0.8731008403361344,
            "PS": 0.8835668449197861,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2153846153846154,
            "BPCHU": 0.4307692307692308,
            "BPCMA": 0.3384615384615385,
            "BPCNA": 0.24615384615384617,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.46153846153846156,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.3384615384615385,
            "GPCNA": 0.4307692307692308,
            "GPCPS": 0.5076923076923077,
            "HM": 0.8548784313725489,
            "HU": 0.8620190311418685,
            "MA": 0.877072192513369,
            "NA": 0.8731008403361344,
            "PS": 0.8835668449197861,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0,
            "GPCNA": 0,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0,
            "NA": 0,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0,
            "GPCNA": 0,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0,
            "NA": 0,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.03076923076923077,
            "HM": 0.8693676470588235,
            "HU": 0.9773382352941177,
            "MA": 0.8015882352941176,
            "NA": 0.8076029411764706,
            "PS": 0.9348529411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.03076923076923077,
            "HM": 0.8693676470588235,
            "HU": 0.9773382352941177,
            "MA": 0.8015882352941176,
            "NA": 0.8076029411764706,
            "PS": 0.9348529411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.03076923076923077,
            "HM": 0.8693676470588235,
            "HU": 0.9773382352941177,
            "MA": 0.8015882352941176,
            "NA": 0.8076029411764706,
            "PS": 0.9348529411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.046153846153846156,
            "HM": 0.8313764705882353,
            "HU": 0.9071029411764706,
            "MA": 0.7536470588235294,
            "NA": 0.7880352941176471,
            "PS": 0.8508725490196078,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.09230769230769231,
            "HM": 0.8133198529411765,
            "HU": 0.8778970588235294,
            "MA": 0.8108411764705882,
            "NA": 0.7801544117647059,
            "PS": 0.8119705882352941,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.12307692307692308,
            "HM": 0.8261764705882353,
            "HU": 0.8778970588235294,
            "MA": 0.8488872549019608,
            "NA": 0.8139893048128343,
            "PS": 0.8415661764705883,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.12307692307692308,
            "HM": 0.8261764705882353,
            "HU": 0.8778970588235294,
            "MA": 0.8488872549019608,
            "NA": 0.8139893048128343,
            "PS": 0.8415661764705883,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.12307692307692308,
            "HM": 0.8261764705882353,
            "HU": 0.8778970588235294,
            "MA": 0.8488872549019608,
            "NA": 0.8139893048128343,
            "PS": 0.8415661764705883,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.16923076923076924,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.13846153846153847,
            "HM": 0.8293877005347594,
            "HU": 0.882781512605042,
            "MA": 0.8492773109243698,
            "NA": 0.8179215686274509,
            "PS": 0.8440915032679738,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.16923076923076924,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.13846153846153847,
            "HM": 0.8293877005347594,
            "HU": 0.882781512605042,
            "MA": 0.8492773109243698,
            "NA": 0.8179215686274509,
            "PS": 0.8440915032679738,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.16923076923076924,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.13846153846153847,
            "HM": 0.8293877005347594,
            "HU": 0.882781512605042,
            "MA": 0.8492773109243698,
            "NA": 0.8179215686274509,
            "PS": 0.8440915032679738,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.2,
            "GPCPS": 0.15384615384615385,
            "HM": 0.8333774509803922,
            "HU": 0.8894816176470588,
            "MA": 0.852202205882353,
            "NA": 0.8221764705882353,
            "PS": 0.8475735294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.2,
            "GPCPS": 0.15384615384615385,
            "HM": 0.8333774509803922,
            "HU": 0.8894816176470588,
            "MA": 0.852202205882353,
            "NA": 0.8221764705882353,
            "PS": 0.8475735294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.2,
            "GPCHU": 0.13846153846153847,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.2153846153846154,
            "GPCPS": 0.16923076923076924,
            "HM": 0.8261289592760181,
            "HU": 0.8840588235294118,
            "MA": 0.8464934640522875,
            "NA": 0.8225630252100841,
            "PS": 0.8399411764705882,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.2153846153846154,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.15384615384615385,
            "GPCNA": 0.23076923076923078,
            "GPCPS": 0.18461538461538463,
            "HM": 0.8312899159663865,
            "HU": 0.8932970588235294,
            "MA": 0.8538411764705882,
            "NA": 0.8277490196078432,
            "PS": 0.8445122549019608,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.13846153846153847,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.12307692307692308,
            "GPCHM": 0.24615384615384617,
            "GPCHU": 0.16923076923076924,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.26153846153846155,
            "GPCPS": 0.2,
            "HM": 0.8333382352941177,
            "HU": 0.8935748663101605,
            "MA": 0.8556016042780749,
            "NA": 0.8329913494809689,
            "PS": 0.8399162895927602,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.2,
            "BPCMA": 0.18461538461538463,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.15384615384615385,
            "GPCHM": 0.2923076923076923,
            "GPCHU": 0.2,
            "GPCMA": 0.2153846153846154,
            "GPCNA": 0.3076923076923077,
            "GPCPS": 0.27692307692307694,
            "HM": 0.8595294117647059,
            "HU": 0.8795339366515837,
            "MA": 0.8865630252100841,
            "NA": 0.8576161764705883,
            "PS": 0.8481797385620915,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.24615384615384617,
            "BPCMA": 0.2153846153846154,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.3230769230769231,
            "GPCHU": 0.2,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.3384615384615385,
            "GPCPS": 0.3076923076923077,
            "HM": 0.8648557422969188,
            "HU": 0.8795339366515837,
            "MA": 0.8820137254901961,
            "NA": 0.8666229946524064,
            "PS": 0.8561235294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.24615384615384617,
            "BPCMA": 0.2153846153846154,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.3230769230769231,
            "GPCHU": 0.2,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.3384615384615385,
            "GPCPS": 0.3076923076923077,
            "HM": 0.8648557422969188,
            "HU": 0.8795339366515837,
            "MA": 0.8820137254901961,
            "NA": 0.8666229946524064,
            "PS": 0.8561235294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.24615384615384617,
            "BPCMA": 0.2153846153846154,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.3230769230769231,
            "GPCHU": 0.2,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.3384615384615385,
            "GPCPS": 0.3076923076923077,
            "HM": 0.8648557422969188,
            "HU": 0.8795339366515837,
            "MA": 0.8820137254901961,
            "NA": 0.8666229946524064,
            "PS": 0.8561235294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.26153846153846155,
            "BPCMA": 0.2153846153846154,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.35384615384615387,
            "GPCHU": 0.2153846153846154,
            "GPCMA": 0.26153846153846155,
            "GPCNA": 0.36923076923076925,
            "GPCPS": 0.3384615384615385,
            "HM": 0.8653925831202046,
            "HU": 0.8809789915966386,
            "MA": 0.8916280276816609,
            "NA": 0.8721384803921568,
            "PS": 0.8575641711229947,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.8955294117647059,
            "HU": 0.9763823529411765,
            "MA": 0.9283529411764706,
            "NA": 0.8942058823529412,
            "PS": 0.8946176470588235,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.8955294117647059,
            "HU": 0.9763823529411765,
            "MA": 0.9283529411764706,
            "NA": 0.8942058823529412,
            "PS": 0.8946176470588235,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.8955294117647059,
            "HU": 0.9763823529411765,
            "MA": 0.9283529411764706,
            "NA": 0.8942058823529412,
            "PS": 0.8946176470588235,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.03076923076923077,
            "HM": 0.89175,
            "HU": 1.0044117647058823,
            "MA": 0.9375882352941176,
            "NA": 0.9171029411764706,
            "PS": 0.8883235294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.07692307692307693,
            "HM": 0.8504656862745098,
            "HU": 1.007985294117647,
            "MA": 0.799129411764706,
            "NA": 0.8161911764705883,
            "PS": 0.8079647058823529,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.09230769230769231,
            "HM": 0.8177394957983194,
            "HU": 1.006494117647059,
            "MA": 0.7700539215686274,
            "NA": 0.7903361344537816,
            "PS": 0.7813774509803921,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.1076923076923077,
            "HM": 0.8099705882352941,
            "HU": 0.9345126050420168,
            "MA": 0.7661512605042017,
            "NA": 0.7833161764705883,
            "PS": 0.7756428571428571,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.13846153846153847,
            "HM": 0.8339823529411765,
            "HU": 0.9401580882352941,
            "MA": 0.7807463235294118,
            "NA": 0.8110911764705881,
            "PS": 0.8076535947712419,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.13846153846153847,
            "HM": 0.8339823529411765,
            "HU": 0.9401580882352941,
            "MA": 0.7807463235294118,
            "NA": 0.8110911764705881,
            "PS": 0.8076535947712419,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.16923076923076924,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.15384615384615385,
            "HM": 0.8538903743315508,
            "HU": 0.9401580882352941,
            "MA": 0.7807463235294118,
            "NA": 0.8341069518716577,
            "PS": 0.8313529411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.15384615384615385,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.16923076923076924,
            "HM": 0.861642156862745,
            "HU": 0.9401580882352941,
            "MA": 0.7966732026143791,
            "NA": 0.8438848039215686,
            "PS": 0.8418048128342246,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.15384615384615385,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.16923076923076924,
            "HM": 0.861642156862745,
            "HU": 0.9401580882352941,
            "MA": 0.7966732026143791,
            "NA": 0.8438848039215686,
            "PS": 0.8418048128342246,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.16923076923076924,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.2153846153846154,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.2153846153846154,
            "GPCPS": 0.2153846153846154,
            "HM": 0.8724579831932773,
            "HU": 0.9572029411764706,
            "MA": 0.8244197860962567,
            "NA": 0.8569432773109243,
            "PS": 0.8699432773109244,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.18461538461538463,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.23076923076923078,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.23076923076923078,
            "GPCPS": 0.23076923076923078,
            "HM": 0.8824470588235295,
            "HU": 0.9572029411764706,
            "MA": 0.8416348039215686,
            "NA": 0.8681823529411765,
            "PS": 0.8794,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.1076923076923077,
            "BPCHU": 0.18461538461538463,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.24615384615384617,
            "GPCHU": 0.16923076923076924,
            "GPCMA": 0.2,
            "GPCNA": 0.24615384615384617,
            "GPCPS": 0.24615384615384617,
            "HM": 0.8853014705882353,
            "HU": 0.965385026737968,
            "MA": 0.8463122171945701,
            "NA": 0.8724724264705882,
            "PS": 0.8823860294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.13846153846153847,
            "BPCHU": 0.27692307692307694,
            "BPCMA": 0.18461538461538463,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.15384615384615385,
            "GPCHM": 0.3230769230769231,
            "GPCHU": 0.2,
            "GPCMA": 0.26153846153846155,
            "GPCNA": 0.3230769230769231,
            "GPCPS": 0.3230769230769231,
            "HM": 0.8967549019607843,
            "HU": 0.968368778280543,
            "MA": 0.8605397923875433,
            "NA": 0.8874887955182073,
            "PS": 0.8938557422969188,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.13846153846153847,
            "BPCHU": 0.2923076923076923,
            "BPCMA": 0.18461538461538463,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.3384615384615385,
            "GPCHU": 0.2153846153846154,
            "GPCMA": 0.27692307692307694,
            "GPCNA": 0.3384615384615385,
            "GPCPS": 0.3384615384615385,
            "HM": 0.8857901069518717,
            "HU": 0.9448613445378151,
            "MA": 0.8478872549019608,
            "NA": 0.8735561497326203,
            "PS": 0.8852473262032086,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.15384615384615385,
            "BPCHU": 0.3230769230769231,
            "BPCMA": 0.2,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.36923076923076925,
            "GPCHU": 0.23076923076923078,
            "GPCMA": 0.3076923076923077,
            "GPCNA": 0.38461538461538464,
            "GPCPS": 0.38461538461538464,
            "HM": 0.8814399509803921,
            "HU": 0.9526647058823529,
            "MA": 0.8388794117647059,
            "NA": 0.8781,
            "PS": 0.8887352941176471,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.15384615384615385,
            "BPCHU": 0.3384615384615385,
            "BPCMA": 0.2,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.38461538461538464,
            "GPCHU": 0.23076923076923078,
            "GPCMA": 0.3230769230769231,
            "GPCNA": 0.4,
            "GPCPS": 0.4,
            "HM": 0.8844294117647058,
            "HU": 0.9526647058823529,
            "MA": 0.8404943977591036,
            "NA": 0.8809705882352942,
            "PS": 0.8908382352941177,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.15384615384615385,
            "BPCHU": 0.3384615384615385,
            "BPCMA": 0.2,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.16923076923076924,
            "GPCHM": 0.38461538461538464,
            "GPCHU": 0.23076923076923078,
            "GPCMA": 0.3230769230769231,
            "GPCNA": 0.4,
            "GPCPS": 0.4,
            "HM": 0.8844294117647058,
            "HU": 0.9526647058823529,
            "MA": 0.8404943977591036,
            "NA": 0.8809705882352942,
            "PS": 0.8908382352941177,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0,
            "GPCNA": 0,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0,
            "NA": 0,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.03076923076923077,
            "GPCHU": 0.03076923076923077,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.03076923076923077,
            "HM": 0.7470441176470588,
            "HU": 0.8876470588235295,
            "MA": 0.9165294117647059,
            "NA": 0.9276176470588235,
            "PS": 0.7792205882352942,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.03076923076923077,
            "GPCNA": 0.03076923076923077,
            "GPCPS": 0.046153846153846156,
            "HM": 0.7704509803921569,
            "HU": 0.9382450980392156,
            "MA": 0.9644264705882353,
            "NA": 0.8821176470588236,
            "PS": 0.7987745098039215,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.06153846153846154,
            "HM": 0.7901397058823529,
            "HU": 0.9544044117647059,
            "MA": 0.9689411764705882,
            "NA": 0.8823921568627451,
            "PS": 0.8092279411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.06153846153846154,
            "HM": 0.7901397058823529,
            "HU": 0.9544044117647059,
            "MA": 0.9689411764705882,
            "NA": 0.8823921568627451,
            "PS": 0.8092279411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.07692307692307693,
            "HM": 0.7655294117647059,
            "HU": 0.9503470588235294,
            "MA": 0.9346985294117647,
            "NA": 0.828,
            "PS": 0.7812705882352942,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.1076923076923077,
            "HM": 0.816873949579832,
            "HU": 0.9769117647058824,
            "MA": 0.9650882352941177,
            "NA": 0.8893823529411765,
            "PS": 0.8463445378151261,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.1076923076923077,
            "HM": 0.816873949579832,
            "HU": 0.9769117647058824,
            "MA": 0.9650882352941177,
            "NA": 0.8893823529411765,
            "PS": 0.8463445378151261,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.13846153846153847,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.13846153846153847,
            "HM": 0.8268725490196078,
            "HU": 0.9702712418300653,
            "MA": 0.9465845588235294,
            "NA": 0.8652169117647058,
            "PS": 0.8546928104575163,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.13846153846153847,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.13846153846153847,
            "HM": 0.8268725490196078,
            "HU": 0.9702712418300653,
            "MA": 0.9465845588235294,
            "NA": 0.8652169117647058,
            "PS": 0.8546928104575163,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.16923076923076924,
            "GPCHU": 0.16923076923076924,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.16923076923076924,
            "HM": 0.8037486631016042,
            "HU": 0.9718048128342247,
            "MA": 0.9271209150326797,
            "NA": 0.8266,
            "PS": 0.8279705882352941,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.15384615384615385,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.18461538461538463,
            "HM": 0.8025612745098039,
            "HU": 0.9589705882352941,
            "MA": 0.9147823529411764,
            "NA": 0.8251604278074866,
            "PS": 0.8268529411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.15384615384615385,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.18461538461538463,
            "HM": 0.8025612745098039,
            "HU": 0.9589705882352941,
            "MA": 0.9147823529411764,
            "NA": 0.8251604278074866,
            "PS": 0.8268529411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.15384615384615385,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.18461538461538463,
            "HM": 0.8025612745098039,
            "HU": 0.9589705882352941,
            "MA": 0.9147823529411764,
            "NA": 0.8251604278074866,
            "PS": 0.8268529411764706,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.12307692307692308,
            "GPCHM": 0.24615384615384617,
            "GPCHU": 0.24615384615384617,
            "GPCMA": 0.2153846153846154,
            "GPCNA": 0.23076923076923078,
            "GPCPS": 0.24615384615384617,
            "HM": 0.8183897058823529,
            "HU": 0.9505183823529412,
            "MA": 0.8989138655462184,
            "NA": 0.8365803921568628,
            "PS": 0.8354742647058824,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.13846153846153847,
            "GPCHM": 0.26153846153846155,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.24615384615384617,
            "GPCPS": 0.26153846153846155,
            "HM": 0.8276574394463668,
            "HU": 0.9538442906574395,
            "MA": 0.9055823529411764,
            "NA": 0.8452647058823529,
            "PS": 0.8437335640138408,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.1076923076923077,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.1076923076923077,
            "BPCPS": 0.13846153846153847,
            "GPCHM": 0.26153846153846155,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.24615384615384617,
            "GPCPS": 0.26153846153846155,
            "HM": 0.8276574394463668,
            "HU": 0.9538442906574395,
            "MA": 0.9055823529411764,
            "NA": 0.8452647058823529,
            "PS": 0.8437335640138408,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.12307692307692308,
            "BPCHU": 0.16923076923076924,
            "BPCMA": 0.13846153846153847,
            "BPCNA": 0.13846153846153847,
            "BPCPS": 0.18461538461538463,
            "GPCHM": 0.3076923076923077,
            "GPCHU": 0.2923076923076923,
            "GPCMA": 0.27692307692307694,
            "GPCNA": 0.3076923076923077,
            "GPCPS": 0.3076923076923077,
            "HM": 0.8470088235294118,
            "HU": 0.9607538699690403,
            "MA": 0.9135882352941177,
            "NA": 0.8690544117647059,
            "PS": 0.8547779411764707,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.13846153846153847,
            "BPCHU": 0.2,
            "BPCMA": 0.15384615384615385,
            "BPCNA": 0.15384615384615385,
            "BPCPS": 0.2,
            "GPCHM": 0.3230769230769231,
            "GPCHU": 0.2923076923076923,
            "GPCMA": 0.2923076923076923,
            "GPCNA": 0.3230769230769231,
            "GPCPS": 0.3230769230769231,
            "HM": 0.8381736694677872,
            "HU": 0.963108359133127,
            "MA": 0.9076393188854489,
            "NA": 0.8652675070028011,
            "PS": 0.8550182072829131,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.15384615384615385,
            "BPCHU": 0.23076923076923078,
            "BPCMA": 0.18461538461538463,
            "BPCNA": 0.18461538461538463,
            "BPCPS": 0.23076923076923078,
            "GPCHM": 0.36923076923076925,
            "GPCHU": 0.3230769230769231,
            "GPCMA": 0.3230769230769231,
            "GPCNA": 0.35384615384615387,
            "GPCPS": 0.35384615384615387,
            "HM": 0.8588700980392157,
            "HU": 0.9742591036414566,
            "MA": 0.9237773109243698,
            "NA": 0.8779143222506394,
            "PS": 0.8650984654731457,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.2153846153846154,
            "BPCHU": 0.3230769230769231,
            "BPCMA": 0.24615384615384617,
            "BPCNA": 0.26153846153846155,
            "BPCPS": 0.3230769230769231,
            "GPCHM": 0.4153846153846154,
            "GPCHU": 0.36923076923076925,
            "GPCMA": 0.35384615384615387,
            "GPCNA": 0.38461538461538464,
            "GPCPS": 0.38461538461538464,
            "HM": 0.8562004357298475,
            "HU": 0.9618149509803922,
            "MA": 0.9242787723785166,
            "NA": 0.8770294117647058,
            "PS": 0.8665729411764705,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.24615384615384617,
            "BPCHU": 0.35384615384615387,
            "BPCMA": 0.27692307692307694,
            "BPCNA": 0.2923076923076923,
            "BPCPS": 0.35384615384615387,
            "GPCHM": 0.4153846153846154,
            "GPCHU": 0.36923076923076925,
            "GPCMA": 0.35384615384615387,
            "GPCNA": 0.38461538461538464,
            "GPCPS": 0.38461538461538464,
            "HM": 0.8562004357298475,
            "HU": 0.9618149509803922,
            "MA": 0.9242787723785166,
            "NA": 0.8770294117647058,
            "PS": 0.8665729411764705,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.24615384615384617,
            "BPCHU": 0.35384615384615387,
            "BPCMA": 0.27692307692307694,
            "BPCNA": 0.2923076923076923,
            "BPCPS": 0.35384615384615387,
            "GPCHM": 0.4153846153846154,
            "GPCHU": 0.36923076923076925,
            "GPCMA": 0.35384615384615387,
            "GPCNA": 0.38461538461538464,
            "GPCPS": 0.38461538461538464,
            "HM": 0.8562004357298475,
            "HU": 0.9618149509803922,
            "MA": 0.9242787723785166,
            "NA": 0.8770294117647058,
            "PS": 0.8665729411764705,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.24615384615384617,
            "BPCHU": 0.35384615384615387,
            "BPCMA": 0.27692307692307694,
            "BPCNA": 0.2923076923076923,
            "BPCPS": 0.35384615384615387,
            "GPCHM": 0.4153846153846154,
            "GPCHU": 0.36923076923076925,
            "GPCMA": 0.35384615384615387,
            "GPCNA": 0.38461538461538464,
            "GPCPS": 0.38461538461538464,
            "HM": 0.8562004357298475,
            "HU": 0.9618149509803922,
            "MA": 0.9242787723785166,
            "NA": 0.8770294117647058,
            "PS": 0.8665729411764705,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0,
            "GPCNA": 0,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0,
            "NA": 0,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0,
            "GPCNA": 0,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0,
            "NA": 0,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0,
            "GPCNA": 0,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0,
            "NA": 0,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0,
            "GPCHU": 0,
            "GPCMA": 0,
            "GPCNA": 0,
            "GPCPS": 0,
            "HM": 0,
            "HU": 0,
            "MA": 0,
            "NA": 0,
            "PS": 0,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.8003823529411764,
            "HU": 0.9605,
            "MA": 0.7704705882352941,
            "NA": 0.7954705882352942,
            "PS": 0.8046764705882353,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0,
            "BPCHU": 0,
            "BPCMA": 0,
            "BPCNA": 0,
            "BPCPS": 0,
            "GPCHM": 0.015384615384615385,
            "GPCHU": 0.015384615384615385,
            "GPCMA": 0.015384615384615385,
            "GPCNA": 0.015384615384615385,
            "GPCPS": 0.015384615384615385,
            "HM": 0.8003823529411764,
            "HU": 0.9605,
            "MA": 0.7704705882352941,
            "NA": 0.7954705882352942,
            "PS": 0.8046764705882353,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.046153846153846156,
            "GPCHU": 0.046153846153846156,
            "GPCMA": 0.046153846153846156,
            "GPCNA": 0.046153846153846156,
            "GPCPS": 0.046153846153846156,
            "HM": 0.9391470588235294,
            "HU": 0.8871274509803921,
            "MA": 0.8959705882352941,
            "NA": 0.9041862745098039,
            "PS": 0.9000098039215686,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.06153846153846154,
            "HM": 0.9130661764705882,
            "HU": 0.9184779411764706,
            "MA": 0.9134338235294117,
            "NA": 0.8896691176470588,
            "PS": 0.8847867647058824,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.06153846153846154,
            "GPCHU": 0.06153846153846154,
            "GPCMA": 0.06153846153846154,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.06153846153846154,
            "HM": 0.9130661764705882,
            "HU": 0.9184779411764706,
            "MA": 0.9134338235294117,
            "NA": 0.8896691176470588,
            "PS": 0.8847867647058824,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.09230769230769231,
            "HM": 0.9758284313725489,
            "HU": 0.9725147058823529,
            "MA": 0.9736421568627451,
            "NA": 0.9540539215686274,
            "PS": 0.9681666666666666,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.09230769230769231,
            "HM": 0.9758284313725489,
            "HU": 0.9725147058823529,
            "MA": 0.9736421568627451,
            "NA": 0.9540539215686274,
            "PS": 0.9681666666666666,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.09230769230769231,
            "HM": 0.9951302521008404,
            "HU": 0.9725147058823529,
            "MA": 0.9736421568627451,
            "NA": 0.9540539215686274,
            "PS": 0.9681666666666666,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.09230769230769231,
            "GPCPS": 0.09230769230769231,
            "HM": 0.9951302521008404,
            "HU": 0.9725147058823529,
            "MA": 0.9736421568627451,
            "NA": 0.9540539215686274,
            "PS": 0.9681666666666666,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.1076923076923077,
            "HM": 0.9872352941176471,
            "HU": 0.9921218487394957,
            "MA": 0.9794579831932773,
            "NA": 0.9607857142857144,
            "PS": 0.9644621848739495,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.12307692307692308,
            "HM": 0.9545751633986927,
            "HU": 0.9921218487394957,
            "MA": 0.9973051470588236,
            "NA": 0.9292022058823529,
            "PS": 0.9608235294117647,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.16923076923076924,
            "GPCHU": 0.13846153846153847,
            "GPCMA": 0.15384615384615385,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.15384615384615385,
            "HM": 0.9563422459893048,
            "HU": 1.0164803921568628,
            "MA": 0.9972088235294118,
            "NA": 0.936,
            "PS": 0.9594323529411765,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.16923076923076924,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.16923076923076924,
            "GPCPS": 0.18461538461538463,
            "HM": 0.9341691176470588,
            "HU": 1.0059572192513369,
            "MA": 0.9699919786096256,
            "NA": 0.9145561497326203,
            "PS": 0.9380269607843138,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.2,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.2,
            "HM": 0.9364638009049774,
            "HU": 1.004485294117647,
            "MA": 0.96975,
            "NA": 0.9185098039215686,
            "PS": 0.9395701357466064,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.2,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.2,
            "HM": 0.9364638009049774,
            "HU": 1.004485294117647,
            "MA": 0.96975,
            "NA": 0.9185098039215686,
            "PS": 0.9395701357466064,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.046153846153846156,
            "BPCNA": 0.07692307692307693,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.2,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.18461538461538463,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.2,
            "HM": 0.9364638009049774,
            "HU": 1.004485294117647,
            "MA": 0.96975,
            "NA": 0.9185098039215686,
            "PS": 0.9395701357466064,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.09230769230769231,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.27692307692307694,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.26153846153846155,
            "GPCNA": 0.26153846153846155,
            "GPCPS": 0.27692307692307694,
            "HM": 0.9179395424836602,
            "HU": 0.9996072664359862,
            "MA": 0.94019723183391,
            "NA": 0.8991176470588236,
            "PS": 0.9199493464052287,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.12307692307692308,
            "BPCPS": 0.1076923076923077,
            "GPCHM": 0.3230769230769231,
            "GPCHU": 0.2923076923076923,
            "GPCMA": 0.27692307692307694,
            "GPCNA": 0.2923076923076923,
            "GPCPS": 0.3076923076923077,
            "HM": 0.9366946778711485,
            "HU": 0.9882275541795664,
            "MA": 0.9404411764705882,
            "NA": 0.9119860681114551,
            "PS": 0.9295058823529412,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.09230769230769231,
            "BPCHU": 0.12307692307692308,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.13846153846153847,
            "BPCPS": 0.13846153846153847,
            "GPCHM": 0.36923076923076925,
            "GPCHU": 0.35384615384615387,
            "GPCMA": 0.3230769230769231,
            "GPCNA": 0.3384615384615385,
            "GPCPS": 0.36923076923076925,
            "HM": 0.935204656862745,
            "HU": 0.9801854219948849,
            "MA": 0.9439551820728291,
            "NA": 0.9169572192513369,
            "PS": 0.9225061274509804,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.015384615384615385,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.07692307692307693,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.07692307692307693,
            "HM": 0.9819117647058824,
            "HU": 1.095894117647059,
            "MA": 0.9954411764705883,
            "NA": 1.0043588235294116,
            "PS": 0.9595470588235294,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.046153846153846156,
            "BPCMA": 0.06153846153846154,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.1076923076923077,
            "GPCHU": 0.12307692307692308,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.1076923076923077,
            "HM": 0.9518613445378151,
            "HU": 1.0222757352941176,
            "MA": 0.9346470588235294,
            "NA": 0.9263713235294118,
            "PS": 0.9423865546218488,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.13846153846153847,
            "HM": 0.9671699346405229,
            "HU": 1.0340558823529413,
            "MA": 0.951672268907563,
            "NA": 0.9436970588235294,
            "PS": 0.9561535947712418,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.1076923076923077,
            "GPCNA": 0.15384615384615385,
            "GPCPS": 0.13846153846153847,
            "HM": 0.9671699346405229,
            "HU": 1.0340558823529413,
            "MA": 0.951672268907563,
            "NA": 0.9436970588235294,
            "PS": 0.9561535947712418,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.16923076923076924,
            "GPCHU": 0.16923076923076924,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.18461538461538463,
            "GPCPS": 0.16923076923076924,
            "HM": 0.9584171122994652,
            "HU": 1.0298716577540106,
            "MA": 0.9458464052287582,
            "NA": 0.9310049019607843,
            "PS": 0.9485294117647058,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.18461538461538463,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.15384615384615385,
            "GPCNA": 0.2,
            "GPCPS": 0.18461538461538463,
            "HM": 0.9531911764705883,
            "HU": 1.0316446078431372,
            "MA": 0.9529705882352941,
            "NA": 0.929027149321267,
            "PS": 0.9444240196078432,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.2,
            "GPCHU": 0.2,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.2153846153846154,
            "GPCPS": 0.2,
            "HM": 0.9522873303167421,
            "HU": 1.0273778280542987,
            "MA": 0.953590909090909,
            "NA": 0.9298802521008404,
            "PS": 0.9429276018099547,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.06153846153846154,
            "BPCMA": 0.07692307692307693,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.06153846153846154,
            "GPCHM": 0.2,
            "GPCHU": 0.2,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.2153846153846154,
            "GPCPS": 0.2,
            "HM": 0.9522873303167421,
            "HU": 1.0273778280542987,
            "MA": 0.953590909090909,
            "NA": 0.9298802521008404,
            "PS": 0.9429276018099547,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.23076923076923078,
            "GPCHU": 0.23076923076923078,
            "GPCMA": 0.2,
            "GPCNA": 0.24615384615384617,
            "GPCPS": 0.23076923076923078,
            "HM": 0.9301745098039216,
            "HU": 1.0109549019607844,
            "MA": 0.9446334841628959,
            "NA": 0.9132224264705883,
            "PS": 0.9261823529411765,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.24615384615384617,
            "GPCHU": 0.24615384615384617,
            "GPCMA": 0.2153846153846154,
            "GPCNA": 0.26153846153846155,
            "GPCPS": 0.24615384615384617,
            "HM": 0.9302205882352941,
            "HU": 1.0082922794117648,
            "MA": 0.9459348739495799,
            "NA": 0.9161695501730104,
            "PS": 0.9284724264705883,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.26153846153846155,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.27692307692307694,
            "GPCPS": 0.26153846153846155,
            "HM": 0.9166505190311419,
            "HU": 1.0131643598615918,
            "MA": 0.9507058823529412,
            "NA": 0.9042924836601307,
            "PS": 0.9380294117647059,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.26153846153846155,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.27692307692307694,
            "GPCPS": 0.26153846153846155,
            "HM": 0.9264377162629758,
            "HU": 1.0063304498269898,
            "MA": 0.9455686274509804,
            "NA": 0.9187777777777778,
            "PS": 0.9248806228373703,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.26153846153846155,
            "GPCHU": 0.26153846153846155,
            "GPCMA": 0.23076923076923078,
            "GPCNA": 0.27692307692307694,
            "GPCPS": 0.26153846153846155,
            "HM": 0.9264377162629758,
            "HU": 1.0063304498269898,
            "MA": 0.9455686274509804,
            "NA": 0.9187777777777778,
            "PS": 0.9248806228373703,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.27692307692307694,
            "GPCHU": 0.27692307692307694,
            "GPCMA": 0.24615384615384617,
            "GPCNA": 0.2923076923076923,
            "GPCPS": 0.27692307692307694,
            "HM": 0.9296013071895425,
            "HU": 1.0086290849673203,
            "MA": 0.947875,
            "NA": 0.9222739938080495,
            "PS": 0.9283169934640523,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.27692307692307694,
            "GPCHU": 0.27692307692307694,
            "GPCMA": 0.24615384615384617,
            "GPCNA": 0.2923076923076923,
            "GPCPS": 0.27692307692307694,
            "HM": 0.9296013071895425,
            "HU": 1.0086290849673203,
            "MA": 0.947875,
            "NA": 0.9222739938080495,
            "PS": 0.9283169934640523,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.27692307692307694,
            "GPCHU": 0.27692307692307694,
            "GPCMA": 0.24615384615384617,
            "GPCNA": 0.2923076923076923,
            "GPCPS": 0.27692307692307694,
            "HM": 0.9296013071895425,
            "HU": 1.0086290849673203,
            "MA": 0.947875,
            "NA": 0.9222739938080495,
            "PS": 0.9283169934640523,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.27692307692307694,
            "GPCHU": 0.27692307692307694,
            "GPCMA": 0.24615384615384617,
            "GPCNA": 0.2923076923076923,
            "GPCPS": 0.27692307692307694,
            "HM": 0.9254297385620914,
            "HU": 1.0078349673202616,
            "MA": 0.9474375,
            "NA": 0.9179736842105263,
            "PS": 0.9237598039215686,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.06153846153846154,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.09230769230769231,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.2923076923076923,
            "GPCHU": 0.2923076923076923,
            "GPCMA": 0.26153846153846155,
            "GPCNA": 0.3076923076923077,
            "GPCPS": 0.2923076923076923,
            "HM": 0.9232151702786378,
            "HU": 1.0098777089783282,
            "MA": 0.9493166089965398,
            "NA": 0.9161529411764706,
            "PS": 0.9217306501547987,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.07692307692307693,
            "BPCMA": 0.1076923076923077,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.07692307692307693,
            "GPCHM": 0.3384615384615385,
            "GPCHU": 0.36923076923076925,
            "GPCMA": 0.3076923076923077,
            "GPCNA": 0.36923076923076925,
            "GPCPS": 0.36923076923076925,
            "HM": 0.9229852941176471,
            "HU": 0.9999276960784313,
            "MA": 0.9567294117647058,
            "NA": 0.9124730392156862,
            "PS": 0.9273725490196079,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.07692307692307693,
            "BPCHU": 0.09230769230769231,
            "BPCMA": 0.12307692307692308,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.09230769230769231,
            "GPCHM": 0.35384615384615387,
            "GPCHU": 0.36923076923076925,
            "GPCMA": 0.3076923076923077,
            "GPCNA": 0.36923076923076925,
            "GPCPS": 0.36923076923076925,
            "HM": 0.9262826086956522,
            "HU": 0.9999276960784313,
            "MA": 0.9567294117647058,
            "NA": 0.9124730392156862,
            "PS": 0.9273725490196079,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ],
    [
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.07692307692307693,
            "GPCHU": 0.09230769230769231,
            "GPCMA": 0.07692307692307693,
            "GPCNA": 0.06153846153846154,
            "GPCPS": 0.09230769230769231,
            "HM": 0.8173529411764706,
            "HU": 0.9199558823529411,
            "MA": 0.8752,
            "NA": 0.8680661764705883,
            "PS": 0.9485735294117648,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.1076923076923077,
            "HM": 0.838156862745098,
            "HU": 0.9272478991596639,
            "MA": 0.8872107843137256,
            "NA": 0.8863058823529412,
            "PS": 0.9457100840336135,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.015384615384615385,
            "BPCHU": 0.015384615384615385,
            "BPCMA": 0.015384615384615385,
            "BPCNA": 0.03076923076923077,
            "BPCPS": 0.015384615384615385,
            "GPCHM": 0.09230769230769231,
            "GPCHU": 0.1076923076923077,
            "GPCMA": 0.09230769230769231,
            "GPCNA": 0.07692307692307693,
            "GPCPS": 0.1076923076923077,
            "HM": 0.838156862745098,
            "HU": 0.9272478991596639,
            "MA": 0.8872107843137256,
            "NA": 0.8863058823529412,
            "PS": 0.9457100840336135,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.03076923076923077,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.046153846153846156,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.13846153846153847,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.15384615384615385,
            "HM": 0.8807614379084968,
            "HU": 0.9741205882352941,
            "MA": 0.941156862745098,
            "NA": 0.9229154411764706,
            "PS": 0.9532441176470589,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.15384615384615385,
            "HM": 0.8945735294117647,
            "HU": 0.9754411764705883,
            "MA": 0.938514705882353,
            "NA": 0.9413277310924371,
            "PS": 0.9389029411764707,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.03076923076923077,
            "GPCHM": 0.12307692307692308,
            "GPCHU": 0.15384615384615385,
            "GPCMA": 0.12307692307692308,
            "GPCNA": 0.1076923076923077,
            "GPCPS": 0.15384615384615385,
            "HM": 0.8945735294117647,
            "HU": 0.9754411764705883,
            "MA": 0.938514705882353,
            "NA": 0.9413277310924371,
            "PS": 0.9389029411764707,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.13846153846153847,
            "GPCHU": 0.16923076923076924,
            "GPCMA": 0.15384615384615385,
            "GPCNA": 0.12307692307692308,
            "GPCPS": 0.15384615384615385,
            "HM": 0.8877483660130719,
            "HU": 0.9570026737967914,
            "MA": 0.901114705882353,
            "NA": 0.9278051470588236,
            "PS": 0.9561558823529411,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        },
        {
            "BPCHM": 0.046153846153846156,
            "BPCHU": 0.03076923076923077,
            "BPCMA": 0.03076923076923077,
            "BPCNA": 0.06153846153846154,
            "BPCPS": 0.046153846153846156,
            "GPCHM": 0.15384615384615385,
            "GPCHU": 0.18461538461538463,
            "GPCMA": 0.16923076923076924,
            "GPCNA": 0.13846153846153847,
            "GPCPS": 0.16923076923076924,
            "HM": 0.8710176470588235,
            "HU": 0.9482230392156863,
            "MA": 0.8884812834224599,
            "NA": 0.9047614379084968,
            "PS": 0.9352058823529412,
            "BPHM": [],
            "BPHU": [],
            "BPMA": [],
            "BPNA": [],
            "BPPS": [],
            "GPHM": [],
            "GPHU": [],
            "GPMA": [],
            "GPNA": [],
            "GPPS": []
        }
    ]
]
