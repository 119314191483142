import {myID} from "../../libs/generateID";
import {ClimateDriverCommandTypes, MessageTypes} from "../../constans/mqttMessages";
import store from "../../store/store";
import {addNotification} from "reapop";
import {changeShadowState} from "../../actions/shadowsActions";
import _ from "lodash";
import * as moment from "moment";
import {getMinutesOfDay} from "../../utils/DateTimeUtils";

export function refresh(devices) {
    const map = new Map();
    devices.forEach(dev => {
        const {DevID} = dev;
        const shadow = store.getState().shadows.shadows.get(DevID);
        const newShadow = getShadow({
            temp: _.get(shadow, "ambientConditions.averageCurrentAirTemperature"),
            requestedAirTemperature: _.get(shadow, "ambientConditions.requestedAirTemperature"),
            minimumVentilation: _.get(shadow, "configuration.ventilationConfiguration.minimumVentilation"),
            maximumVentilation: _.get(shadow, "configuration.ventilationConfiguration.maximumVentilation"),
            minimumTemperatureAlarmDeviation: _.get(shadow, "configuration.alarms.minimumTemperatureAlarmDeviation"),
            maximumTemperatureAlarmDeviation: _.get(shadow, "configuration.alarms.maximumTemperatureAlarmDeviation"),
            currentCurveDay: _.get(shadow, "climateCurve.currentCurveDay"),
            stages: _.get(shadow, "climateCurve.stages")
        });
        map.set(DevID, newShadow)
    });
    store.dispatch(changeShadowState(map));
}

function getShadow({
                       temp = 20, requestedAirTemperature = 20, minimumVentilation = 5, maximumVentilation = 100, minimumTemperatureAlarmDeviation = 2, maximumTemperatureAlarmDeviation = 2, currentCurveDay = 0, stages = [
        {
            "days": 1,
            "requestedTemperature": 20.6,
            "minimumVentilation": 0,
            "maximumVentilation": 190
        },
        {
            "days": 20,
            "requestedTemperature": 19.6,
            "minimumVentilation": 0,
            "maximumVentilation": 190
        },
        {
            "days": 37,
            "requestedTemperature": 24.4,
            "minimumVentilation": 0,
            "maximumVentilation": 190
        },
        {
            "days": 38,
            "requestedTemperature": 21.8,
            "minimumVentilation": 0,
            "maximumVentilation": 190
        },
        {
            "days": 23,
            "requestedTemperature": 21.6,
            "minimumVentilation": 0,
            "maximumVentilation": 190
        },
        {
            "days": 27,
            "requestedTemperature": 25.4,
            "minimumVentilation": 0,
            "maximumVentilation": 190
        },
        {
            "days": 22,
            "requestedTemperature": 19,
            "minimumVentilation": 0,
            "maximumVentilation": 190
        },
        {
            "days": 29,
            "requestedTemperature": 22.2,
            "minimumVentilation": 0,
            "maximumVentilation": 190
        }
    ]
                   }) {
    const currentTemp = temp > requestedAirTemperature ? temp - (Math.random() / 4) : temp + (Math.random() / 4);
    let vent = minimumVentilation;
    if (currentTemp >= (requestedAirTemperature + maximumTemperatureAlarmDeviation)) {
        vent = maximumVentilation;
    }
    return _.cloneDeep({
        "ambientConditions": {
            "averageCurrentAirTemperature": currentTemp,
            "allSensorsCurrentIndications": [
                6453.5,
                20.6,
                6453.5,
                6453.5,
                6453.5,
                6453.5,
                6453.5
            ],
            "requestedAirTemperature": requestedAirTemperature,
            "generalVentilationEfficiency": vent,
            "mastersOn": 0,
            "heatersOn": [
                0,
                0,
                0
            ],
            "floorHeatingOn": [
                0,
                0,
                0
            ],
            "waterHeatingConfiguration": 65,
            "liquidGroupEfficiency": 26,
            "chimneyGroupOn": [],
            "workType": 0,
            "controllerStatus": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            "channelsExtensionInstalled": true,
            "expansionCardSensorsIndications": [
                null,
                null,
                31,
                null,
                null,
                null,
                null,
                null
            ],
            "alerts": [
                +(currentTemp <= (requestedAirTemperature - minimumTemperatureAlarmDeviation)),
                +(currentTemp >= (requestedAirTemperature + maximumTemperatureAlarmDeviation)),
                0,
                0,
                0,
                0,
                0,
                0
            ]
        },
        "configuration": {
            "softwareVersion": "0.0",
            "requestedAirTemperature": requestedAirTemperature,
            "heatingDeviation": 1.8,
            "bandWidth": 3.2,
            "isActive": true,
            "manual": 39.6,
            "requestedTemperatureDeviation": 12.4,
            "floors": [
                25,
                28.6,
                29.2
            ],
            "maximumHoldFor20Percent": 400,
            "additionalHeatingVentilation": 10,
            "heatingWorkTime": 90,
            "heatingBreakTime": 140,
            "ventilationConfiguration": {
                "maximumVentilation": maximumVentilation,
                "minimumVentilation": minimumVentilation,
                "ventilationSuspension": 300,
                "maximumVentilationDeviation": 50,
                "minimumVentilationDeviation": 50
            },
            "coolingConfiguration": {
                "coolingEnableTemperature": 24,
                "coolingWorkTime": 30,
                "coolingBreakTime": 10,
                "coolingMaximumHumidity": 100,
                "coolingMinimumPressure": 50,
                "coolingMaximumPressure": 78
            },
            "alarms": {
                "minimumTemperatureAlarmDeviation": minimumTemperatureAlarmDeviation,
                "maximumTemperatureAlarmDeviation": maximumTemperatureAlarmDeviation,
                "absoluteAlarm": 33,
                "outsideAlarmDeviation": 0.4
            }
        },
        "climateCurve": {
            "currentCurveDay": currentCurveDay,
            "isNewTimeSetRequired": false,
            "lastDayMinimumTemperature": 19.5,
            "lastDayMinimumTemperatureTime": 13920000,
            "lastDayMaximumTemperature": 20.3,
            "lastDayMaximumTemperatureTime": 0,
            "recorderEraseTime": 0,
            "lastPowerOutageTime": 61560000,
            "currentTime": 16800000,
            "stages": stages
        },
        "service": {
            "chimneyVentilatorsCount": 1,
            "heatersCount": 1,
            "mastersCount": 0,
            "cooling": false,
            "floorHeating": 0,
            "language": 0,
            "ventilationIntensity": 25,
            "ventilationCycleTime": 120,
            "temperatureSensorsCalibration": [
                0,
                -13,
                -13,
                -13,
                -13
            ],
            "rotation55PercentCalibration": 30,
            "minimumRotation": 20,
            "objectDynamics": 10,
            "analogOutputs": {
                "firstAnalogOutputCurve": [
                    2,
                    0.8,
                    0.8,
                    0.8,
                    0.8,
                    3,
                    3.4,
                    3.7,
                    4.4,
                    5,
                    6.8,
                    10,
                    0.8,
                    0.8,
                    0.8
                ],
                "additionalAnalogOutputs": [
                    {
                        "analogOutputNumber": 2,
                        "minimumVoltage": 0.8,
                        "maximumVoltage": 0
                    },
                    {
                        "analogOutputNumber": 3,
                        "minimumVoltage": 9.9,
                        "maximumVoltage": 1
                    },
                    {
                        "analogOutputNumber": 4,
                        "minimumVoltage": 10,
                        "maximumVoltage": 0.6
                    },
                    {
                        "analogOutputNumber": 5,
                        "minimumVoltage": 10,
                        "maximumVoltage": 0.5
                    },
                    {
                        "analogOutputNumber": 6,
                        "minimumVoltage": 10,
                        "maximumVoltage": 0.5
                    }
                ]
            },
            "memoryLock": 100
        },
        "metadata": {
            "ambientConditions": 1576104623340,
            "configuration": 1576048198628,
            "climateCurve": 1576125664085,
            "service": +new Date() - 1000
        }
    })
}

export function fakeStartSendingDeviceState(devices) {
    const map = new Map();
    const shadows = store.getState().shadows.shadows;
    const last90days = (maxTemp = 25) => {
        const tmp = [];
        for (let i = 0; i < 90; i++) {
            const time = +moment.utc().startOf("day").subtract(i, "day");
            tmp.push({
                AggDt: generateFakeAggDt(maxTemp, time),
                AggTi: time,
                DtaModTime: time
            })
        }
        tmp.sort((o1, o2) => o1.AggTi - o2.AggTi)
        return tmp;
    };
    devices.forEach(dev => {
        if (!shadows.has(dev.DevID)) {
            map.set(dev.DevID, getShadow({}));
        }
        store.dispatch({
            type: "FETCH_AGGREGATED_DATA_FULFILLED",
            payload: last90days(),
            meta: {DevID: dev.DevID}
        })

    });
    store.dispatch(changeShadowState(map));
}

export function fakeCreateAndSendMessageObject(ClientID, GatewayID, LocalUserID, DeviceID, Command, data = undefined, notification = undefined, onSuccess = undefined, onError = undefined, onSend = undefined) {
    let id = myID();
    const message = {
        MsgId: id,
        PktType: MessageTypes.REQUEST,
        DeviceId: DeviceID,
        RTime: new Date().getTime(),
        Command: Array.isArray(Command) ? Command : [Command],
        CData: {},
        Priority: 69
    };
    if (data) {
        message.CData = data;
    }
    if (notification && notification.success) {
        store.dispatch(addNotification({
            ...notification.loading,
            ...notification.success
        }));
    }
    const shadows = store.getState().shadows.shadows;
    const cmd = message.Command[0];
    console.log(cmd, message)
    switch (cmd) {
        case ClimateDriverCommandTypes.SET_CONFIGURATION: {
            const map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                const shadow = shadows.get(devId);
                const newShadow = getShadow({
                    temp: _.get(shadow, "ambientConditions.averageCurrentAirTemperature"),
                    requestedAirTemperature: _.get(message.CData, "requestedAirTemperature"),
                    minimumVentilation: _.get(message.CData, "ventilationConfiguration.minimumVentilation"),
                    maximumVentilation: _.get(message.CData, "ventilationConfiguration.maximumVentilation"),
                    minimumTemperatureAlarmDeviation: _.get(message.CData, "alarms.minimumTemperatureAlarmDeviation"),
                    maximumTemperatureAlarmDeviation: _.get(message.CData, "alarms.maximumTemperatureAlarmDeviation"),
                    currentCurveDay: 0,
                    stages: _.get(shadow, "climateCurve.stages")
                });
                map.set(devId, newShadow)
            });
            store.dispatch(changeShadowState(map));
            break;
        }
        case ClimateDriverCommandTypes.SET_CLOCK_MENU: {
            const currentDay = _.get(message.CData, "currentCurveDay");
            if (currentDay) {
                const map = new Map();
                (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                    const shadow = shadows.get(devId);

                    const stages = _.get(message.CData, "stages");
                    const values = {
                        requestedAirTemperature: _.get(shadow, "ambientConditions.requestedAirTemperature"),
                        minimumVentilation: _.get(shadow, "configuration.ventilationConfiguration.minimumVentilation"),
                        maximumVentilation: _.get(shadow, "configuration.ventilationConfiguration.maximumVentilation"),
                    };
                    if (stages) {
                        let days = 0;
                        for (let s of stages) {
                            days += s.days;
                            if (currentDay <= days) {
                                values.requestedAirTemperature = s.requestedTemperature;
                                values.minimumVentilation = s.minimumVentilation;
                                values.maximumVentilation = s.maximumVentilation;
                                break;
                            }
                        }
                    }

                    const newShadow = getShadow({
                        temp: _.get(shadow, "ambientConditions.averageCurrentAirTemperature"),
                        ...values,
                        minimumTemperatureAlarmDeviation: _.get(shadow, "configuration.alarms.minimumTemperatureAlarmDeviation"),
                        maximumTemperatureAlarmDeviation: _.get(shadow, "configuration.alarms.maximumTemperatureAlarmDeviation"),
                        currentCurveDay: currentDay,
                        stages: stages
                    });
                    map.set(devId, newShadow)
                });
                store.dispatch(changeShadowState(map));
            }

            break;
        }
        default:
            break;

    }
    return id;
}

// 1 2 3       2  3-2
const generateFakeAggDt = (maxTemp, timeDay) => {
    const result = [];
    const max = (timeDay !== +moment.utc().startOf("day")
        ? 48
        : Math.ceil(getMinutesOfDay(new Date()) / 30));
    for (let i = 0; i < max; i++) {
        const fac = factor[Math.floor(48 * i / factor.length)] + (Math.random() / 30);
        result.push({
            T: +moment(timeDay)
                .startOf("day")
                .add(i * 30, "minutes"),
            "MinV": 0,
            "CAct": false,
            "TempR": 20,
            "Mast": 0,
            "IsAct": 1,
            "Temp": Math.round(fac * maxTemp * 10) / 10,
            "WType": 0,
            "MaxT": maxTemp + 2,
            "V": (i % 5) ? result[i - 1].V : Math.round(fac * 100),
            "MaxV": 190,
            "MinT": maxTemp - 10
        });
    }
    result.sort((o1, o2) => o1.T - o2.T)
    return result;
}

const factor = [
    0.8646288209606988,
    0.8646288209606988,
    0.8646288209606988,
    0.8602620087336245,
    0.8602620087336245,
    0.8602620087336245,
    0.8602620087336245,
    0.8558951965065503,
    0.8558951965065503,
    0.8602620087336245,
    0.8558951965065503,
    0.8558951965065503,
    0.8515283842794761,
    0.8515283842794761,
    0.8515283842794761,
    0.8471615720524017,
    0.8471615720524017,
    0.8471615720524017,
    0.8471615720524017,
    0.8515283842794761,
    0.8515283842794761,
    0.8515283842794761,
    0.8602620087336245,
    0.8602620087336245,
    0.8733624454148472,
    0.8471615720524017,
    0.8515283842794761,
    0.8515283842794761,
    0.8515283842794761,
    0.8515283842794761,
    0.8602620087336245,
    0.8646288209606988,
    0.8515283842794761,
    0.8646288209606988,
    0.8646288209606988,
    0.868995633187773,
    0.8733624454148472,
    0.8733624454148472,
    0.8777292576419216,
    0.8820960698689957,
    0.8820960698689957,
    0.8864628820960699,
    0.8951965065502184,
    0.8951965065502184,
    0.8995633187772927,
    0.8995633187772927,
    0.9039301310043668,
    0.9126637554585153,
    0.9126637554585153,
    0.9213973799126639,
    0.9344978165938864,
    0.9344978165938864,
    0.9475982532751092,
    0.9606986899563319,
    0.9650655021834063,
    0.9737991266375546,
    0.9737991266375546,
    0.9781659388646288,
    0.9868995633187774,
    1,
    0.9956331877729259,
    0.9912663755458515,
    0.9912663755458515,
    0.9868995633187774,
    0.9781659388646288,
    0.9694323144104804,
    0.9519650655021835,
    0.9388646288209608,
    0.9388646288209608,
    0.9344978165938864,
    0.9301310043668123,
    0.925764192139738,
    0.925764192139738,
    0.9213973799126639,
    0.9213973799126639,
    0.9170305676855895,
    0.9126637554585153,
    0.9126637554585153,
    0.9082969432314412,
    0.9082969432314412,
    0.9039301310043668,
    0.8995633187772927,
    0.8995633187772927,
    0.8951965065502184,
    0.8995633187772927,
    0.8951965065502184,
    0.8951965065502184,
    0.8908296943231441,
    0.8908296943231441,
    0.8951965065502184,
    0.8908296943231441,
    0.8908296943231441,
    0.8864628820960699
]
