import {fakeStartSendingDeviceState as fakeStartSendingDeviceStateSilo} from "../silo/fakeFunctions";
import {fakeStartSendingDeviceState as fakeStartSendingDeviceStateClimate} from "../climate/fakeFunctions";
import {DevType} from '../../constans/devices';

export function fakeStartSendingDeviceState(devices) {
    if (devices.length) {
        const scales = devices.filter(dev => dev.DevType === DevType.SCALE);
        const climates = devices.filter(dev => dev.DevType === DevType.CLIMATE);
        if (scales.length) {
            fakeStartSendingDeviceStateSilo(scales);
        }
        if (climates.length) {
            fakeStartSendingDeviceStateClimate(climates);
        }
    }
}

