import lokiDB, {buildings} from "./lokiDB"
import {cloneDeep, get, isNil, memoize} from "lodash";
import {getModificationTime, insertInto, setModificationTime} from "../utils/LokiUtils";
import animalsDB from "./animalsDB";
import {getFarm} from "../selectors/farmSelector";
import store from "../store/store"

class Buildings {

    /**********************************
     ***         BUILDINGS          ***
     **********************************/

    constructor() {
        this.getTreeByLocationID = memoize(this.getTreeByLocationID);
    }

    getModificationTime(farmID) {
        return getModificationTime("buildings", "FarmID", farmID);
    }

    clearCache() {
        animalsDB.getAnimalLocationsByPlcmntID.cache.clear();
        animalsDB.getAnmLocationByID.cache.clear();
        animalsDB.getAllAnimalsForLocation.cache.clear();
        this.getTreeByLocationID.cache.clear();
    }

    //insert into animals
    async insertIntoBuildings(values) {
        insertInto(values, "buildings", "BgID");
        setModificationTime("buildings", values[values.length - 1].DtaModTime, "FarmID", values[values.length - 1].FarmID);
        this.clearCache();
        await lokiDB.asyncSaveDB();
    }

    /**
     * get all buildings with farmID
     * @param farmID
     * @returns {*}
     */
    getAllBuildingsForFarm(farmID) {
        try {
            return buildings.find({FarmID: farmID, DtaDelTime: {$type: 'undefined'}});
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * Zwraca budynki dla wszystkich farm
     * @returns {Array|*}
     */
    getAllBuildingsForAllFarms() {
        try {
            return buildings.find({DtaDelTime: {$type: 'undefined'}});
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * Funkcja wyszukujaca budynek po jego ID
     * @param BgID
     * @return building
     */
    getBuilding(BgID) {
        try {
            return buildings.findOne({BgID: BgID});
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Funkcja wyszukuje budynek po locationID
     * @param locationID    id lokalizacji (budynku, sektora, komory lub stanowiska)
     * @return              obiekt budynku
     */
    getBuildingByLocationID(locationID) {
        try {
            let builds = buildings.find();
            for (let building of builds) {
                if (building.BgID === locationID) return building;
                for (let sector of building.Sectors) {
                    if (sector.SID === locationID) return building;
                    for (let chamber of sector.Chambers) {
                        if (chamber.CID === locationID) return building;
                        if (chamber.Boxes) {
                            for (let box of chamber.Boxes) {
                                if (box.BID === locationID) return building;
                            }
                        }
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Funkcja wyszukujaca stanowisko po jego RFID
     * @param RFID - RFID stanowiska
     * @return box - stanowisko
     */
    getBoxBIDByRfid(RFID) {
        let results = buildings.find();
        let box = undefined;
        results.filter(building => {
            building.Sectors.filter(sector => {
                sector.Chambers.filter(chamber => {
                    if ((!box || box.length < 1) && chamber.Boxes !== undefined) {
                        box = chamber.Boxes.filter(box => (box.RFID === RFID))[0];
                    }
                });
            });
        });
        return box;
    }

    /**
     * Metoda pobiera lokalizacje po RFID
     * @param RFID {string}     numer RFID lokalizacji
     * @return {*}              lokalizacja
     */
    getLocationByRFID(RFID, farmID) {
        let results = buildings.find({DtaDelTime: {$type: 'undefined'}, FarmID: farmID});
        for (let building of results) {
            if (building.RFID === RFID) return building;
            for (let sector of building.Sectors) {
                if (sector.RFID === RFID) return sector;
                for (let chamber of sector.Chambers) {
                    if (chamber.RFID === RFID) return chamber;
                    if (chamber.Boxes) {
                        for (let box of chamber.Boxes) {
                            if (box.RFID === RFID) return box;
                        }
                    }
                }
            }
        }
    }

    /**
     * Funkcja do pobierania ostatniej daty modyfikacji budynków na danej fermie
     * @param farmiID FarmID
     * @return {number} 0 jeśli nie było wcześniej informacji o ostatniej dacie modyfikacji dla danego farmdID
     */

    getSizeofFarm(farmiID) {
        let buil = buildings.find({FarmID: farmiID});
        let farmSize = 0;
        buil.map(building => {
            building.Sectors.map(sector => {
                sector.Chambers.map(chamber => {
                    if (!chamber.IndividualFeeding) {
                        farmSize += +chamber.CSize;
                    } else {
                        farmSize += chamber.Boxes ? +chamber.Boxes.length : 0;
                    }
                })
            })
        });
        //console.log("farmSize: ", farmSize);
        return farmSize;
    }

    /**
     * Metoda pobiera wszystkie komory dla danego typu sektora z lokacją w stringu
     * @param SType
     * @param FarmID
     */
    getAllChambersForSTypeWithLocation(SType, FarmID) {
        try {
            let data = buildings.find({FarmID: FarmID, DtaDelTime: {$type: 'undefined'}});
            console.log(data);
            let Sectors = [];
            data.map(b => {
                b.Sectors.map(sector => {
                    sector.location = b.BName;
                    Sectors.push(sector);
                })
            });
            Sectors = Sectors.filter(sec => parseInt(sec.SType) === SType);
            let Chambers = [];
            Sectors.map(s => {
                s.Chambers.map(chamber => {
                    chamber.location = s.location + " - " + s.SName;
                    Chambers.push(chamber);
                })
            });
            return Chambers;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * getAllChambersWithStandsList(FarmID)
     * @param FarmID
     */
    getAllChambersWithStandsList(FarmID) {
        try {
            const chambers = this.getAllChambersWithStands(FarmID);
            let stands = [];
            chambers.map(c => {
                c.Boxes.map(box => {
                    box.CID = c.CID;
                    box.location = c.location + " - " + c.CName;
                    stands.push(box);
                })
            });
            return stands;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllChambersWithStands(FarmID) {
        try {
            let data = buildings.find({FarmID: FarmID, DtaDelTime: {$type: 'undefined'}});
            let Sectors = [];
            data.map(b => {
                b.Sectors.map(sector => {
                    sector.location = b.BName;
                    Sectors.push(sector);
                })
            });
            let chambers = [];
            Sectors.map(s => {
                s.Chambers.map(chamber => {
                    if (chamber.Boxes) {
                        chamber.location = s.location + " - " + s.SName;
                        chambers.push(chamber);
                    }
                })
            });
            return chambers;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllChambers(FarmID) {
        try {
            let data = buildings.find({FarmID: FarmID, DtaDelTime: {$type: 'undefined'}});
            console.log(data);
            let chambers = [];
            for (let building of data) {
                for (let sector of building.Sectors) {
                    chambers.push(...sector.Chambers);
                }
            }
            console.log(chambers);
            return chambers
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    checkIfFarmHasSectorType(farmID, sectorType) {
        let bs = buildings.find({FarmID: farmID, DtaDelTime: {$type: 'undefined'}});
        console.log(bs);
        for (let i = 0; i < bs.length; i++) {
            for (let j = 0; j < bs[i].Sectors.length; j++) {
                if (+bs[i].Sectors[j].SType === +sectorType) return true;
            }
        }
    }

    getTreeByLocationID(id) {
        let bs = buildings.find();
        let state = store.getState();
        let farm = getFarm(state, id);
        let res = state.farms.farms;
        console.log(farm);
        if (farm) {
            return {farm}
        }
        for (let i = 0; i < bs.length; i++) {
            if (bs[i].BgID === id) {
                return {
                    building: cloneDeep(bs[i]),
                    farm: res.filter(f => f.FarmID === bs[i].FarmID)[0]
                }
            }
            for (let j = 0; j < bs[i].Sectors.length; j++) {
                if (bs[i].Sectors[j].SID === id) {
                    return {
                        sector: cloneDeep(bs[i].Sectors[j]),
                        sectorIndex: j,
                        building: cloneDeep(bs[i]),
                        farm: res.filter(f => f.FarmID === bs[i].FarmID)[0],
                    }
                }
                for (let k = 0; k < bs[i].Sectors[j].Chambers.length; k++) {
                    if (bs[i].Sectors[j].Chambers[k].CID === id) {
                        return {
                            chamber: cloneDeep(bs[i].Sectors[j].Chambers[k]),
                            chamberIndex: k,
                            sector: cloneDeep(bs[i].Sectors[j]),
                            sectorIndex: j,
                            building: cloneDeep(bs[i]),
                            farm: res.filter(f => f.FarmID === bs[i].FarmID)[0]
                        }
                    }
                    if (bs[i].Sectors[j].Chambers[k].Boxes) {
                        for (let l = 0; l < bs[i].Sectors[j].Chambers[k].Boxes.length; l++) {
                            if (bs[i].Sectors[j].Chambers[k].Boxes[l].BID === id) {
                                return {
                                    box: cloneDeep(bs[i].Sectors[j].Chambers[k].Boxes[l]),
                                    boxIndex: l,
                                    chamber: cloneDeep(bs[i].Sectors[j].Chambers[k]),
                                    chamberIndex: k,
                                    sector: cloneDeep(bs[i].Sectors[j]),
                                    sectorIndex: j,
                                    building: cloneDeep(bs[i]),
                                    farm: res.filter(f => f.FarmID === bs[i].FarmID)[0]
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    getAllSectorsForFarm(FarmID) {
        let bs = buildings.find({FarmID});
        let sectors = [];
        bs.map(building => {
            sectors = [...sectors, ...building.Sectors];
        });
        return sectors;
    }

    getAllChambersWithoutIndividualFeeding(FarmID) {
        try {
            let chambers = [];
            let bs = buildings.find({FarmID: FarmID});
            for (let i = 0; i < bs.length; i++) {
                for (let j = 0; j < bs[i].Sectors.length; j++) {
                    chambers = [...chambers, ...bs[i].Sectors[j].Chambers.filter(item => !item.IndividualFeeding)];
                }
            }
            return chambers;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getLocationByID(id) {
        let farm = getFarm(store.getState(), id);
        if (farm) return farm;
        let build = buildings.findOne({BgID: id});
        if (build) return build;
        let builds = buildings.find();
        for (let building of builds) {
            let sector = building.Sectors.find(item => item.SID === id);
            if (sector) return sector;
            for (let sec of building.Sectors) {
                let chamber = sec.Chambers.find(item => item.CID === id);
                if (chamber) return chamber;
                for (let ch of sec.Chambers) {
                    if (ch.Boxes) {
                        let box = ch.Boxes.find(item => item.BID === id);
                        if (box) return box;
                    }
                }
            }
        }
    }

    getObjectForTreeSelect(FarmID, shouldShowBoxes = true) {
        let builds = buildings.find({FarmID});
        builds = builds.filter(item => !item.DtaDelTime);
        return builds.map(building => ({
            name: "BName",
            object: building,
            key: "BgID",
            children: building.Sectors.map(sector => ({
                name: "SName",
                object: sector,
                parent: [{name: "BName", object: building, key: "BgID"}],
                key: "SID",
                children: sector.Chambers.map(chamber => ({
                    name: "CName",
                    object: chamber,
                    parent: [{name: "BName", object: building, key: "BgID"}, {
                        name: "SName",
                        object: sector,
                        key: "SID"
                    }],
                    key: "CID",
                    children: chamber.Boxes && shouldShowBoxes ? chamber.Boxes.map(box => ({
                        name: "BoxesName",
                        object: box,
                        parent: [{name: "BName", object: building, key: "BgID"}, {
                            name: "SName",
                            object: sector,
                            key: "SID"
                        }, {name: "CName", object: chamber, key: "CID"}],
                        key: "BID"
                    })) : null
                }))
            }))
        }))
    }


    getSectorTypeByChamberID(chamberID) {
        let builds = buildings.find().filter(item => !item.DtaDelTime);
        let sectorType;
        for (let building of builds) {
            const sectors = get(building, "Sectors", []);
            for (let sector of sectors) {
                sectorType = get(sector, "Chambers", []).find(chamber => chamber.CID === chamberID) ? sector.SType : sectorType;
                if (!isNil(sectorType)) {
                    break;
                }
            }
            if (!isNil(sectorType)) {
                break;
            }
        }
        return sectorType;
    }

}

const buildingsDB = new Buildings();
export default buildingsDB;
