import lokiDB, {cachedTablesParams, settings} from "./lokiDB"
import _ from "lodash";
import {SettingTypes} from "../constans/settingTypes";
import {getModificationTime, insertInto, setModificationTime} from "../utils/LokiUtils";
import store from "../store/store"
import {getSettingClass} from "../utils/SettingsUtils";
import FarmMap from "../beans/settings/FarmMap";

class Settings {
    /**********************************
     ***         Settings           ***
     **********************************/

    getModificationTime(farmID) {
        return getModificationTime("settings", "FarmID", farmID);
    }

    async insertIntoSettings(values) {
        insertInto(values, "settings", "SetID");
        setModificationTime("settings", values[values.length - 1].DtaModTime, "FarmID", values[values.length - 1].FarmID);
        await lokiDB.asyncSaveDB();
    }

    getLookSettings() {
        try {
            return getSettingClass(settings.findOne({look: {$type: "object"}}).look);
        } catch (e) {
            console.error(e);
            return {
                // TODO wstawic domyslne wartosci dla wygladu
            }
        }
    }

    async setLookSettings(values) {
        try {
            let res = this.getLookSettings();
            res.look = values;
            await this.insertIntoSettings([res]);
            return res.look
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Potrzebne do workera, nie używać normalnie
     * @return {*}
     */
    getAllSettingsWithoutFarmID() {
        try {
            return [...settings.find({DtaDelTime: {$type: "undefined"}}), ...settings.find({
                SetType: SettingTypes.FEEDING_FORAGE,
                DtaDelTime: {$type: 'number'}
            })].map(setting => getSettingClass(setting));
        } catch (e) {
            return [];
        }
    }

    getAllSettings(FarmID) {
        try {
            let res = settings.find({FarmID, DtaDelTime: {$type: "undefined"}});
            res = [...res, ...settings.find({
                SetType: SettingTypes.GENERAL,
                DtaDelTime: {$type: "undefined"}
            }), ...settings.find({
                SetType: SettingTypes.NOTIFICATIONS,
                DtaDelTime: {$type: "undefined"}
            }), ...settings.find({FarmID, SetType: SettingTypes.FEEDING_FORAGE, DtaDelTime: {$type: 'number'}})];
            return res.map(setting => getSettingClass(setting));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getSettingByID(SetID, {showDeleted = false} = {}) {
        try {
            const params = showDeleted ? {} : {DtaDelTime: {$type: "undefined"}};
            let res = getSettingClass(settings.findOne({SetID: SetID, ...params}));
            return !res || !SetID ? undefined : res;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async deleteFromSettings(values) {
        values.map(item => {
            let data = settings.find({SetID: item.SetID});
            if (data.length > 0) {
                let loki = _.cloneDeep(data[0].$loki);
                let meta = _.cloneDeep(data[0].meta);
                data[0] = _.cloneDeep(item);
                data[0].$loki = loki;
                data[0].meta = meta;
                settings.remove(data[0]);
            }
        });
        let cached = this.getModificationTime(values[0].FarmID);
        console.log(cached);
        if (!cached.table) {
            cached.DtaModTime = values[values.length - 1].DtaModTime;
            cached.table = 'settings';
            cachedTablesParams.remove(cached)
        } else {
            cached.DtaModTime = values[values.length - 1].DtaModTime;
            cachedTablesParams.update(cached);
        }
        // await lokiDB.saveDB();

    }


    getSettingByIndexAndType(index, type, farmID) {
        let activeFarmID = farmID || store.getState().location.farm;
        let allSettings = settings.find({FarmID: activeFarmID, SetType: type, DtaDelTime: {$type: 'undefined'}});
        return getSettingClass(allSettings.find(item => item.SetData.Index === index));
    }

    getFarmMap(FarmID) {
        let map = settings.findOne({
            SetID: `farm_map_${FarmID}`,
            DtaDelTime: {$type: "undefined"}
        });
        if (!map || !map.SetData.Levels) return null;
        return new FarmMap(map);
    }
}

const settingsDB = new Settings();
export default settingsDB;