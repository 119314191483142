import moment from "moment";
import {flatten, get, memoize} from "lodash";

const settlementList = (timeNow, CID) => [
    {
        DtaStartTime: +moment.utc(timeNow).startOf("day").subtract(14, "days"),
        DtaEndTime: undefined,
        PlcmntID: CID
    }
]

export const fakeGetSettlementList = memoize((state, props) => {
    console.log("fakeGetSettlementList", state, props)
    const chamberIds = [];
    const buildingsList = get(state, "farms.buildings", []);
    buildingsList.forEach(b => {
        get(b, "Sectors", []).forEach(s => {
            get(s, "Chambers", []).forEach(c => {
                chamberIds.push(c.CID);
            })
        })
    })
    return flatten(chamberIds.map(cid => settlementList(+new Date(), cid)))
}, (state => get(state, "farms.buildings.length", 0)))
