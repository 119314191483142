import {myID} from "../../libs/generateID";
import {MessageTypes} from "../../constans/mqttMessages";
import store from "../../store/store";
import {addNotification} from "reapop";
import {changeShadowState} from "../../actions/shadowsActions";
import {getMinutesOfDay} from "../../utils/DateTimeUtils";
import _ from "lodash";
import moment from "moment";

export function refresh(devices) {
    const map = new Map();
    devices.forEach((dev) => {
        const {DevID} = dev;
        const shadow = store.getState().shadows.shadows.get(DevID) || {};
        const newShadow = getShadow(shadow, dev);
        newShadow.waterFlow = !newShadow.waterFlow
            ? Math.floor(Math.random() * 100000)
            : Math.max(newShadow.waterFlow - 1000, 0);
        map.set(DevID, newShadow);
    });
    store.dispatch(changeShadowState(map));
}

function getShadow(newValues = {}, device) {
    const newShadow = {
        _flowTime: 0,
        waterConsumption: 4346000,
        waterFlow: 0,
        ...newValues,
        lastReceiveTime: +new Date() - 50000,
        deviceTime: +new Date() - 50000,
        metadata: {
            data: +new Date() - 50000,
        },
    };
    newShadow.waterConsumption += newShadow.waterFlow / 720;
    newShadow._flowTime = newShadow.waterFlow ? newShadow._flowTime + 50000 : 0;
    if (!!_.get(device, "Settings.FlowValueAlertLimit")) {
    }
    newShadow.flowValueAlert =
        !!_.get(device, "Settings.FlowValueAlertLimit") &&
        newShadow.waterFlow >= _.get(device, "Settings.FlowValueAlertLimit");
    newShadow.flowTimeAlert =
        !!_.get(device, "Settings.FlowTimeAlertLimit") &&
        newShadow._flowTime >= _.get(device, "Settings.FlowTimeAlertLimit");
    return newShadow;
}

export function fakeStartSendingDeviceState(devices) {
    const map = new Map();
    const shadows = store.getState().shadows.shadows;
    const date = moment.utc().startOf("day").subtract(89, "day");
    const last90Days = [];
    const vals = {};
    for (let i = 0; i < 90; i++) {
        const time = +date.clone().add(i, "day");
        last90Days.push({
            AggDt: generateFakeAggDt(
                time,
                i > 0 ? last90Days[i - 1].AggDt[47].MR : undefined
            ),
            AggTi: time,
            DtaModTime: time,
        });
        if (i === 89) {
            vals.waterConsumption =
                last90Days[89].AggDt[last90Days[89].AggDt.length - 1].MR + 1000;
        }
    }

    devices.forEach((dev) => {
        if (!shadows.has(dev.DevID)) {
            map.set(dev.DevID, getShadow(vals, dev));
            store.dispatch({
                type: "FETCH_AGGREGATED_DATA_FULFILLED",
                payload: last90Days,
                meta: {DevID: dev.DevID},
            });
        }
    });
    store.dispatch(changeShadowState(map));
}

export function fakeCreateAndSendMessageObject(
    ClientID,
    GatewayID,
    LocalUserID,
    DeviceID,
    Command,
    data = undefined,
    notification = undefined,
    onSuccess = undefined,
    onError = undefined,
    onSend = undefined
) {
    let id = myID();
    const message = {
        MsgId: id,
        PktType: MessageTypes.REQUEST,
        DeviceId: DeviceID,
        RTime: new Date().getTime(),
        Command: Array.isArray(Command) ? Command : [Command],
        CData: {},
        Priority: 69,
    };
    if (data) {
        message.CData = data;
    }
    if (notification && notification.success) {
        store.dispatch(
            addNotification({
                ...notification.loading,
                ...notification.success,
            })
        );
    }
    const shadows = store.getState().shadows.shadows;
    const cmd = message.Command[0];
    console.log(cmd, shadows, message);
    return id;
}

const generateFakeAggDt = (timeDay, dayBefore = 74346000) => {
    const result = [];
    for (
        let i = 0;
        i <
        (timeDay !== +moment.utc().startOf("day")
            ? 48
            : Math.ceil(getMinutesOfDay(new Date()) / 30));
        i++
    ) {
        result.push({
            T: +moment(timeDay)
                .startOf("day")
                .add(i * 30, "minutes"),
            MR:
                (i ? result[i - 1].MR : dayBefore) +
                Math.floor((3000000  + Math.random() * 900000) / 48 ),
            AF: 0,
        });
    }
    return result;
};
